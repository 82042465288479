import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { Col, Input, Modal, Typography, Row, Button } from "antd";
// Components
import InputWithLabel from "components/InputWithLabel";
// Constants
import { OTHER_INFORMATION, EDIT_OTHER_DETAILS, EDIT_OTHER_DETAILS_LABELS, OPTION, TENOR_EXPIRY } from "lib/constant/Pricer/OtherInfoModal";
import { FIELD_NAMES } from "lib/constant/Pricer/PricerTable";
// Context

import { PricerContext } from "lib/contexts/PricerContext";
// API
import { updateOnEditCell, fetchOtherInfoData } from "api/Pricer/Table";
// Styles
import "./OtherInfoModal.scss";
import { toast } from "lib/helpers/utilities";
import { GlobalContext } from "lib/contexts/GlobalContext";
import PreLoadPagesSpin from "components/PreLoadPagesSpin";

const UPDATE = "Update";
const CANCEL = "Cancel";

const LegsData = ({ data, isSubLeg=false }) => {

  return (
    <Row className="cell-value">
      <Col span={3}>{data.name}</Col>
      <Col
        span={6}
        className={
          (isSubLeg && "add-space--sub-leg") ||
          (data["underlying_tenor"] && "underlying_tenor")
        }
      >
        <Input
          disabled
          value={data.title}
          className={`ant-input-disabled leg-input`}
        />
        {data["underlying_tenor"] && (
          <Input
            disabled
            value={data["underlying_tenor"]}
            className="leg-input"
          />
        )}
      </Col>
    </Row>
  );
};

const OtherInfoModal = (props) => {
  //Context
  const { refetchOptionRow, state} = useContext(PricerContext);
  const { globalState: { dark_Theme } } = useContext(GlobalContext);
  const [legsData,            setLegsData           ] = useState([]);
  const [editDetailsSection,  setEditDetailsSection ] = useState({});
  const [newCarryAndAverage,  setNewCarryAndAverage ] = useState({});
  const [showEditSection,     setShowEditSection    ] = useState(false);
  const [isOkButtonLoading,   setIsOkButtonLoading  ] = useState(false);
  const [isLoading,           setIsLoading          ] = useState(false);

  const prepareAPIData = useCallback((apiData) => {
    const formattedLegsData = [];
    apiData.forEach((item, idx) => {
      formattedLegsData.push({
        ...item,
        name: `Legs ${ idx + 1 }`,
        index: idx,
      });

      item["sublegs"].forEach((nestedItem, nestedIndex) => {
        formattedLegsData.push({ ...nestedItem, name: `SLegs ${ nestedIndex + 1 }`, parentIndex: idx, isSubLeg:true });
      });
    });
    setLegsData(formattedLegsData);
  }, []);

  const handleAddTheNewestValues = useCallback((key, value) => {
    setNewCarryAndAverage(prevState => ({ ...prevState, [key]: value }));
  }, []);

  const showLegsData = useMemo(() => {
    if (legsData.length === 0) return;
    return legsData.map((item, index) => <LegsData data={ item } key={ index } isSubLeg={item.isSubLeg}/>);
  }, [legsData]);

  const triggerOtherModalListAPI = useCallback((refId)=>{
    setIsLoading(true);
    fetchOtherInfoData(refId).then(res => {
      const { average, cost_of_carry,show_edit_section } = res.data;
      prepareAPIData(res.data?.legs);
      setEditDetailsSection({ cost_of_carry, average });
      setShowEditSection(show_edit_section);
      setIsLoading(false);
    }).catch(err => {
      setIsLoading(false);
    });
  },[props.rowSelectedData?.summary_id]);

  const onSubmit = () => {
    if (!props.rowSelectedData?.summary_id) return;

    setIsOkButtonLoading(true);
    const body = {
      summary_id: props.rowSelectedData?.summary_id,
      cost_of_carry: newCarryAndAverage[EDIT_OTHER_DETAILS_LABELS.NEW_COST_OF_CARRY.key],
      average: newCarryAndAverage[EDIT_OTHER_DETAILS_LABELS.NEW_AVERAGE.key],
      [FIELD_NAMES.CALCULATION_DATE]: state?.dateSelected
    };

    updateOnEditCell(body).then(res => {
      toast(res?.data?.message, "success");
      triggerOtherModalListAPI(props.rowSelectedData?.summary_id);
      refetchOptionRow();
      setIsOkButtonLoading(false);
      props.setOtherInfoModalVisible(false);
    }).catch(err => {
      setIsOkButtonLoading(false);
    });
  };

  useEffect(() => {
    if (!props.rowSelectedData?.summary_id) return;
    triggerOtherModalListAPI(props.rowSelectedData?.summary_id);
  }, [props.rowSelectedData?.summary_id]);

  //Reset State
  useEffect(()=>{
    setEditDetailsSection({});
    setNewCarryAndAverage({});
    setLegsData([]);
    setShowEditSection(false);
  },[props.rowSelectedData?.summary_id]);

  return (
    <Modal
      width={"70%"}
      className={`other-info__container ${
        dark_Theme && "ant-modal-dark content_dark"
      }`}
      title={OTHER_INFORMATION}
      open={props.isModalOpen}
      onCancel={() => props.setOtherInfoModalVisible(false)}
      onOk={() => props.setOtherInfoModalVisible(false)}
      footer={[
        <Button
          key="cancel"
          onClick={() => props.setOtherInfoModalVisible(false)}
        >
          {CANCEL}
        </Button>,
        showEditSection && (
          <Button
            key="submit"
            type="primary"
            loading={isOkButtonLoading}
            onClick={() => {
              onSubmit();
             
            }}
          >
            {UPDATE}
          </Button>
        ),
      ]}
    >
      {isLoading ? (
        <PreLoadPagesSpin
          className={"other-info--container--spin"}
          dark_Theme={dark_Theme}
        />
      ) : (
        <>
          <Typography.Title className="legs--title" level={5}>
            {TENOR_EXPIRY}
          </Typography.Title>
          <div className="other-info__container--legs-section">
            {showLegsData}
          </div>
          {/* Edit Other Details Section */}
          {showEditSection && (
            <div className="other-details--section">
              <Typography.Title level={3}>
                {EDIT_OTHER_DETAILS}
              </Typography.Title>
              <div className="avg-and-carry-fields">
                <span className="avg-and-carry-fields--title">{OPTION}</span>
                {Object.values(EDIT_OTHER_DETAILS_LABELS).map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <InputWithLabel
                        label={item.label}
                        itemKey={item.key}
                        onChange={handleAddTheNewestValues}
                        value={
                          item.isDisabled
                            ? editDetailsSection[item.key]?.value
                            : newCarryAndAverage[item.key]
                        }
                        isUnitBeforeText={
                          item.isDisabled &&
                          editDetailsSection[item.key]?.symbol
                        }
                        isDisabled={item.isDisabled}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
      {/* End Of Edit Other Details Section */}
    </Modal>
  );
};

export default OtherInfoModal;
