import Pusher from "pusher-js";

export const PUSHER_CREDENTIALS = {
  APP_KEY: process.env.REACT_APP_PUSHER_APP_KEY,
  CLUSTER: process.env.REACT_APP_PUSHER_APP_CLUSTER,
};


const pusherInstance = new Pusher(PUSHER_CREDENTIALS.APP_KEY, {
  cluster: PUSHER_CREDENTIALS.CLUSTER,
  encrypted: true,
  
});
Pusher.logToConsole = true;
export default pusherInstance;