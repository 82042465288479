import React, { useContext, useEffect, useRef, useState } from "react";
import { Select, Spin } from "antd";
// Context
import { GlobalContext } from "lib/contexts/GlobalContext";

// Style
import "./TenorDropdown.css";
import { fetchTenors, updateOnEditCell } from "api/Pricer/Table";
import { EditOutlined } from "@ant-design/icons";
import { PricerContext } from "lib/contexts/PricerContext";
import { updatedPricerData } from "lib/helpers/Pricer/helperAggFunc";
import { FIELD_NAMES } from "lib/constant/Pricer/PricerTable";
import { isLegLevel, isSummaryLegLevel } from "lib/helpers/Pricer/Table";
import { onPricerCellEditingStarted } from "lib/helpers/Pricer/utils";
 
const { Option } = Select;


const TenorDropdown = (params) => {
  const [tenorsData, setTenorsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
   const ref = useRef(null);
     const node = params.node;

   const rowData = node.group ? node.aggData : node.data;
    const {
      state: {
        pricerTablesData,
        pricerRefAgGrid,
        dateSelected,
        isLoading: isOptionRowLoading,
      },
      refetchOptionRow,
      setIsOptionRowLoading,
      setPricerData,
      setIsCellEditing,
      setRowEditingData,
    } = useContext(PricerContext);
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  function getTenorOptions() {
    setIsLoading(true);
    const nodeData = params?.node.group
      ? params?.node.aggData
      : params?.node.data;
    fetchTenors(nodeData.summary_id, nodeData.leg_id)
      .then((res) => {
        setTenorsData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

    const handleMenuItemClick = (value) => {
      onPricerCellEditingStarted(params, setRowEditingData, dark_Theme);
      const { label, value: style_period_tenor_id } = JSON.parse(value);
      let bodyParams = {
        option_row_id: rowData.summary_id,
        summary_id: rowData.summary_id,
        style_period_tenor_id: style_period_tenor_id,
        calculation_date: dateSelected,
        row_type: rowData.row_type,
        [FIELD_NAMES.LEG_ID]: rowData[FIELD_NAMES.LEG_ID],
      };

      setIsCellEditing(true);
      setIsOptionRowLoading(true);
      setIsOpen(false);
      updateOnEditCell(bodyParams)
        .then((res) => {
             
          const updatedData = updatedPricerData(
            res.data.data,
            pricerTablesData
          );
          setPricerData(updatedData);

          let refreshParams = {
            force: true, // forcefully refresh
            columns: [FIELD_NAMES.TENOR], // specific column to refresh
          };
          pricerRefAgGrid.api.refreshCells(refreshParams);
          
          
          // refetchOptionRow();
          })
          .catch((e) => {
            refetchOptionRow();
            }).finally(() => {
          setIsOptionRowLoading(false);
          setIsCellEditing(false);
          setIsOpen(false);
          setIsLoading(false);
               setRowEditingData(null);
        });
    };


  useEffect(() => {
    if (isOpen) getTenorOptions();
  }, [isOpen]);

   const handleClickOutside = (event) => {
     if (ref.current && !ref.current.contains(event.target)) {
       setIsOpen(false); // Close the dropdown
     }
   };
  // Add event listener for clicks
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  if (!(isLegLevel(params) || isSummaryLegLevel(params))) {
    return <p>{params.value}</p>;
  }
  return (
    <div ref={ref}>
      <Select
        onClick={() => setIsOpen((prev) => !prev)}
        open={isOpen && !isOptionRowLoading && tenorsData.length > 0 }
        loading={isLoading}
        placeholder="Select Tenor"
        className={`${
          dark_Theme
            ? "greek-drop-down__container-dark"
            : "greek-drop-down__container-light"
        } tenor-dropdown`}
       
        suffixIcon={<>{isLoading ? <Spin size="small" /> : <EditOutlined />}</>}
        dropdownStyle={{
          fontSize: "var(--font-size-10) !important",
        }}
        onBlur={() => setIsOpen(false)}
        dropdownMatchSelectWidth={false}
        bordered={false}
        onChange={(val) => handleMenuItemClick(val)}
        value={params.value || "10Y"}
        popupClassName={dark_Theme && "dropdown-dark"}
      >
        {isLoading ? (
          <Option>
           
            <Spin size="small" />
          </Option>
        ) : (
          tenorsData?.map((item) => (
            <Option key={item.value} value={JSON.stringify(item)}>
              {item.label}
            </Option>
          ))
        )}
      </Select>
    </div>
  );
};

export default TenorDropdown;
