/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
//Import Helpers
import { AUDIT_TRAIL_COLUMNS } from "lib/constant/AuditTrail/AuditTrail";
import {
  rowHeight,
  headerHeight,
  getRowStyle,
} from "lib/constant/AgGridCommonStyle";
import { GlobalContext } from "lib/contexts/GlobalContext";
import {
  defaultCellsStyle,
  setUserPreferencesForSSRM,
} from "lib/helpers/AuditTrail/generalHelper";

import { cellClickedListener } from "lib/helpers/GeneralFuncHelpers";
import useDebounce from "lib/hooks/useDebounce";
import {
  createServerSideDatasource,
  onAuditTrailColumnResized,
  onAuditTrailFirstDataRendered,
  onAuditTrailSelectionChanged,
  onAuditTrailSortChange,
} from "lib/helpers/AuditTrail/utils";

const AgGridTable = (props) => {
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [sortModel, setSortModel] = useState();
  const [columnsState, setColumnsState] = useState();
  const [firstRender, setFirstRender] = useState(true);
  const {
    setDetailedAuditTrailData,
    setAuditTrailData,
    volSurfaceButtonClicked,
    start_date,
    end_date,
    setStart_date,
    setEnd_date,
    setInitial_date,
    selectedFilters,
  } = props;
  const {
    setAuditTrailSelectedRowState,
    setAuditTrailState,
    globalState: { auditTrailState, dark_Theme },
  } = useContext(GlobalContext);
  const params = useParams();
  const location = useLocation();
  const pathName = location.pathname;
  const detailPageId = params?.id; // get id for detail_audit_trail page
  const debouncedColumnState = useDebounce(columnsState, 1000);
  const sort_model = auditTrailState.sortModel;

  // format the filter model and keep it updated in it.
  const filterModelData = {
    date_time: {
      filterType: "date",
      type: "inRange", // could also be 'equals', 'lessThan', 'greaterThan', etc.
      dateFrom: start_date,
      dateTo: end_date,
    },
  };

  const filterModelDataForAuditTrail = useMemo(() => {
    return { ...auditTrailState?.selectedFilter };
  }, [auditTrailState?.selectedFilter]);

  const auditTrailColData = useMemo(() => {
    const auditTrailData = [...AUDIT_TRAIL_COLUMNS].map((item) => ({
      ...item,
      menuTabs: [],
    }));
    const columnWithoutUIN = () =>
      [...auditTrailData].filter((item) => item.headerName !== "UIN");
    if (params?.id) {
      return columnWithoutUIN();
    } else {
      return auditTrailData;
    }
  }, [dark_Theme, params?.id]);

  useEffect(() => {
    if (gridRef.current) {
      
      gridRef.current.api?.setServerSideDatasource(
        createServerSideDatasource({
          detailPageId,
          pathName,
          volSurfaceButtonClicked,
          setAuditTrailData,
          setDetailedAuditTrailData,
          filterModelDataForAuditTrail,
          filterModelData,
          sort_model,
          selectedFilters,
          start_date,
          end_date,
        })
      );
    }
  }, [
    filterModelDataForAuditTrail,
    start_date,
    end_date,
    sortModel,
    selectedFilters,
  ]);

  useEffect(() => {
    if (debouncedColumnState && !firstRender) {
      let data = {
        table_name: pathName.includes("vol-surface")
          ? "vol_surface"
          : "audit_trail",
        column_state: JSON.stringify(debouncedColumnState),
      };

      setUserPreferencesForSSRM(data);
    }
  }, [debouncedColumnState]);

  useEffect(() => {
    let data = {
      table_name: "audit_trail",
      filter_model: JSON.stringify(filterModelDataForAuditTrail),
    };
    if (!pathName.includes("vol-surface")) {
      setUserPreferencesForSSRM(data);
    }
  }, [filterModelDataForAuditTrail]);

  const onGridReady = useCallback((params) => {
    let data = {
      table_name: pathName.includes("vol-surface")
        ? "vol_surface"
        : "audit_trail",
    };
    params.api.sizeColumnsToFit();

    setUserPreferencesForSSRM(data)
      .then((res) => {
        let response = res.data.data;
        let columnState_response = response.column_state;
        if (columnState_response) {
          params.columnApi.applyColumnState({
            state: JSON.parse(columnState_response),
          });
        }
        let hasFilter =
          response.filter_model &&
          JSON.parse(response.filter_model) &&
          Object.keys(JSON.parse(response.filter_model)).length;
        let filterModel = hasFilter
          ? Object.values(JSON.parse(response.filter_model))[0]
          : null;
        if (pathName.includes("vol-surface")) {
          filterModel = auditTrailState.selectedFilter;
        }
        let filter = response?.filter_model
          ? JSON.parse(response.filter_model)
          : "";
        if (filter?.date_time) {
          let filteredDate = filter.date_time;
          let obj = {
            start_date: filteredDate.dateFrom,
            end_date: filteredDate.dateTo,
          };
          setInitial_date(obj);
          setStart_date(filteredDate.dateFrom);
          setEnd_date(filteredDate.dateTo);
        }
        if (auditTrailState.selectedFilter) {
          if (auditTrailState.selectedFilter.filter !== filterModel.filter) {
            let tableState = {
              columnState: JSON.parse(response.column_state) || null,
              selectedRows: [],
              selectedFilter: filterModel,
              sortedModel: JSON.parse(response.sort_model) || null,
            };
            setAuditTrailState(tableState);
          }
        }
        setFirstRender(false);
      })
      .catch((error) => {
        console.log("error while setting user prefs >", error);
        setFirstRender(false);
      });
    setGridApi(params.api);
    const datasource = createServerSideDatasource({
      detailPageId,
      pathName,
      volSurfaceButtonClicked,
      setAuditTrailData,
      setDetailedAuditTrailData,
      filterModelDataForAuditTrail,
      filterModelData,
      sort_model,
      selectedFilters,
      start_date,
      end_date,
    });
    params.api.setServerSideDatasource(datasource);
  }, []);

  const onFirstDataRendered = useCallback(
    (params) =>
      onAuditTrailFirstDataRendered(params, setAuditTrailSelectedRowState),
    []
  );
  return (
    <div className="audit-trail">
      <AgGridReact
        getRowId={(data) => {
          return data?.data?.id || data?.id;
        }}
        ref={gridRef}
        rowSelection="multiple"
        checkboxSelection={true}
        onFirstDataRendered={onFirstDataRendered}
        headerCheckboxSelection={true}
        className={`ag-theme-alpine audit-trail__table ${
          dark_Theme ? "ag-theme-balham-dark" : "ag-theme-balham"
        }`}
        columnDefs={auditTrailColData}
        getRowStyle={getRowStyle}
        defaultColDef={defaultCellsStyle}
        getContextMenuItems={() => []}
        getRowHeight={rowHeight}
        headerHeight={headerHeight}
        groupDisplayType="custom"
        onGridReady={onGridReady}
        onColumnResized={(params) =>
          onAuditTrailColumnResized(params, false, setColumnsState)
        }
        suppressMovableColumns={true}
        rowModelType={"serverSide"}
        pagination={true}
        cacheBlockSize={100}
        onSelectionChanged={(params) =>
          onAuditTrailSelectionChanged(params, setAuditTrailSelectedRowState)
        }
        suppressRowClickSelection
        paginationPageSize={100}
        serverSideStoreType="partial"
        suppressAggFuncInHeader="true"
        groupRemoveSingleChildren="false"
        onCellClicked={cellClickedListener}
        onSortChanged={(params) =>
          onAuditTrailSortChange(params, setSortModel, pathName)
        }
      />
    </div>
  );
};

export default AgGridTable;
