import { isSummaryLevel } from 'lib/helpers/GeneralFuncHelpers';
import React from 'react';
const CheckBoxCell = (props) => {
  const nodeData = props.node.group ? props.node.data : props.node.data;
  return (
    isSummaryLevel(props) ?  <div className="checkbox-cell">
      <input
        type="checkbox"
        checked={nodeData?.live}
        onChange={()=> {}}
        disabled
      />
    </div> : <></>
  )
};

export default React.memo(CheckBoxCell);
