import React, { useState, useMemo, useContext, useEffect,useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { Button, DatePicker, Radio } from 'antd';
import moment from 'moment';
// Context
import { PricerContext } from "lib/contexts/PricerContext";
import { GlobalContext } from "lib/contexts/GlobalContext";
// Components
import AddOptionModal from "../AddOptionModal";
// Helpers
import { fetchProductsExists } from "api/Pricer/Table";
import { FIELD_NAMES,DEFAULT_SORT } from "lib/constant/Pricer/PricerTable";
import { storeInLocalStorage } from "lib/helpers/Pricer/Table";
import { PRICER_DATE_SELECTED, PRICER_DATAFunc, PRICER_FILTER_BUTTON_SELECTED } from "lib/helpers/Pricer/utils";
// Style
import 'core/styles/PricerAuditAgGridLayout.scss'
import './FilterButtons.scss';
import classnames from "classnames";
import useGAEvenetsTraker from "lib/hooks/useGAEvenetsTraker";
import { GA_EVENTS_NAMES } from "lib/constant/GoogleAnalytics/GoogleAnalytics";

export const FILTER_BUTTONS_NAMES = {
  RESET_SORT   : "Reset Sort",
  REFRESH      : "Refresh",
  ALL          : "All",
  ADD_OPTION   : "Add Option",
};

export const SUMMARY_IDS = "summaryIds";

const FilterButtons = () => {
  //Context
  const { state :{ pricerTablesData,pricerOptInfo, pricerRefAgGrid, dateSelected }, setDateSelected,setPricerOperation, refetchOptionRow } = useContext(PricerContext);
  const { globalState: {  dark_Theme } } = useContext(GlobalContext);
  const googleEvenTracker= useGAEvenetsTraker("event")
  //States
  const [productsData             , setProductsData             ] = useState([]);
  const [isAddOptionModalVisible  , setIsAddOptionModalVisible  ] = useState(false);
  const [buttonSelected           , setButtonSelected           ] = useState(null);
  const [isDatePickerOpen         , setIsDatePickerOpen         ] = useState(false);

  const filterInstance = pricerRefAgGrid?.api && pricerRefAgGrid?.api.getFilterInstance(FIELD_NAMES.PRODUCT);

  const applySelectedFilter = (buttonClicked) => {
    if(buttonClicked !== FILTER_BUTTONS_NAMES.ALL){
      filterInstance?.setModel({ values: [buttonClicked] });
    }
    if(buttonClicked === FILTER_BUTTONS_NAMES.ALL){
      filterInstance?.setModel({ values: null });
    }
    pricerRefAgGrid?.api && pricerRefAgGrid.api.onFilterChanged();
  };

  const storeValuesSelectedIntoLocalStorage = (key, value) => {
    const data = {
      ...pricerOptInfo,
      [key]:value
    };
    setPricerOperation(data);
  };
  const onFilterButtonClick = (buttonClicked) => {
    setButtonSelected(buttonClicked);
    if(!filterInstance) return;
    storeValuesSelectedIntoLocalStorage(PRICER_FILTER_BUTTON_SELECTED, buttonClicked);
    applySelectedFilter(buttonClicked);
  };

  const showProductsButtons = () => {
    if(productsData.length === 0) return <></>;
    return productsData.map((item, index) => {
      return (
        <Radio.Button key={`${item}-${index}`} value={item}>
          {item}
        </Radio.Button>
      )
    });
  };

  let {data}=useQuery(
    ['product-exists', pricerTablesData?.length],
    async ()=> await fetchProductsExists(),
    {
      refetchOnMount:false,
      refetchOnWindowFocus:false,
      staleTime:50000,
      cacheTime:50000,
      retry: false
    });

    useEffect(()=>{
        if(data){
          const filterButtons = [...new Set(data.data.data.map(item => item['product']))];
          setProductsData([FILTER_BUTTONS_NAMES.ALL, ...filterButtons.filter((product)=>product)]);
        }
    },[data])

  const showAddOptionModal = useMemo(() =>{
    return (
      <AddOptionModal
        setIsAddOptionModalVisible={setIsAddOptionModalVisible}
        isAddOptionModalVisible={isAddOptionModalVisible}
      />
    )
  },[isAddOptionModalVisible]);

  const onResetButtonClick = (params) => {
    pricerRefAgGrid.columnApi.applyColumnState({
      state: DEFAULT_SORT,
      defaultState: { sort: DEFAULT_SORT },
    });
  };


  const storeSummaryIdsInLocalStorage = (pricerTablesData) => {
    const summaryIds = pricerTablesData && pricerTablesData.map((item) => item.summary_id);
    storeInLocalStorage(SUMMARY_IDS, JSON.stringify(summaryIds));
  };

  const previousSelectedFilter = PRICER_DATAFunc()?.[PRICER_FILTER_BUTTON_SELECTED];
  const previousDatePicker     = PRICER_DATAFunc()?.[PRICER_DATE_SELECTED];

  //2 Effect To Store Selected Button And Get Value From Local Storage
  useEffect(()=> {
    if(!previousSelectedFilter || pricerTablesData.length === 0 || !pricerTablesData) return;
    setButtonSelected(previousSelectedFilter);
  },[previousSelectedFilter, pricerTablesData.length]);

  useEffect(()=>{
    if(!buttonSelected || pricerTablesData.length === 0  || !pricerTablesData) return;
    applySelectedFilter(buttonSelected);
  },[buttonSelected, pricerTablesData.length]);

  useEffect(() => {
    if(!previousDatePicker && pricerTablesData.length === 0 || !pricerTablesData) return;
    setDateSelected(previousDatePicker);
    refetchOptionRow();
  },[previousDatePicker, pricerTablesData.length]);


  const datePickerRef = useRef();

  const onDatePickerChange = (date, dateString) => {
    storeValuesSelectedIntoLocalStorage(PRICER_DATE_SELECTED, dateString);
    setDateSelected(dateString);
    googleEvenTracker(GA_EVENTS_NAMES.PRICER_PAGE_CALCULATION_DATE_TRIGGER ,"pricer")
    setIsDatePickerOpen(false);
    datePickerRef.current.blur();  // To Remove Focus From Date Picker
  }

  return (
    <div className={`buttons-container ${dark_Theme || false ? "pricer-filter-buttons__container_dark":"pricer-filter-buttons__container"} filter-buttons_container`}>
      <div className="buttons-container__big">
        <Button type="primary" onClick={(e) => {onResetButtonClick(e); googleEvenTracker(GA_EVENTS_NAMES.PRICER_PAGE_RESET_SORT_CLICK,"pricer", "reset")}}>{FILTER_BUTTONS_NAMES.RESET_SORT}</Button>
        <DatePicker
          ref={datePickerRef}
          onOpenChange={setIsDatePickerOpen}
          value={dateSelected ? moment(dateSelected) : ""}
          onChange={onDatePickerChange}
          placeholder={"Calculation Date"}
          popupStyle={{ background:"black" }}
          popupClassName={dark_Theme || false ? "darkCalender" : ''}
          className={ classnames("calc-data", dateSelected && "filled--data__background" )
          }
        />
      </div>

      <div className="pricer-filter-buttons__container__buttons buttons-container__small">
        {/* Show Products Buttons */}
        {productsData.length > 0  && (
          <Radio.Group className="buttons-container__small" value={buttonSelected} onChange={(e) => onFilterButtonClick(e.target.value)}>
            {showProductsButtons()}
          </Radio.Group>
        )}
        <Button onClick={() => {
          setIsAddOptionModalVisible(true);
          googleEvenTracker(GA_EVENTS_NAMES.PRICER_PAGE_ADD_OPTION_CLICK,"pricer", "Open")
          storeSummaryIdsInLocalStorage(pricerTablesData);
        }}>{FILTER_BUTTONS_NAMES.ADD_OPTION}</Button>
      </div>
      {/*Show Add Option Modal*/}
      {isAddOptionModalVisible && showAddOptionModal}
    </div>
  )
};



export default FilterButtons;
