import { fetchMarketAnalysifilters } from "api/MarketAnalysis/MarketAnalysis";
import { createContext, useCallback, useEffect, useMemo, useReducer } from "react";

// List of initial state for all global data
export const initialState = {
  marketAnalysisData: null,
  selectedRows:[],
  columnState:null,
  sortModel:null,
  marketAnalysisFilters:[]
};

// Actions
const SET_MARKET_ANALYSIS_GRAPH_DATA = "SET_MARKET_ANALYSIS_GRAPH_DATA";
const SET_MARKET_ANALYSIS_FILTERS = "SET_MARKET_ANALYSIS_FILTERS";
const SET_SELECTED_ROWS = "SET_SELECTED_ROWS";
const SET_COLUMN_STATE = "SET_COLUMN_STATE";
const SET_SORT_MODEL = "SET_SORT_MODEL";

// Shared Reducer For Global Context
const Reducer = (MarketAnalysisState, action) => {
  switch (action.type) {
    case SET_SELECTED_ROWS:
      return {
        ...MarketAnalysisState,
        selectedRows: action.payload,
      };
    case SET_COLUMN_STATE:
      return {
        ...MarketAnalysisState,
        columnsState: action.payload,
      };
    case SET_SORT_MODEL:
      return {
        ...MarketAnalysisState,
        sortModel: action.payload,
      };
    case SET_MARKET_ANALYSIS_GRAPH_DATA:
      return {
        ...MarketAnalysisState,
        marketAnalysisGraphData: action.payload,
      };
    case SET_MARKET_ANALYSIS_FILTERS:
      return {
        ...MarketAnalysisState,
        marketAnalysisFilters: action.payload,
      };
    default:
      return MarketAnalysisState;
  }
};

// Global State Which its provide context for children
const MarketAnalysisState = ({ children, defaultInitialState = {} }) => {
  const [MarketAnalysisState, dispatch] = useReducer(Reducer, {
    ...initialState,
    ...defaultInitialState,
  });

  const setSelectedRows = useCallback((value) => {
    dispatch({ type: SET_SELECTED_ROWS, payload: value });
  }, []);

  const setColumnState = useCallback((value) => {
    dispatch({ type: SET_COLUMN_STATE, payload: value });
  }, []);

  const setSortModel = useCallback((value) => {
    dispatch({ type: SET_SORT_MODEL, payload: value });
  }, []);

  const setMarketAnalysisGraphData = useCallback((value) => {
    dispatch({ type: SET_MARKET_ANALYSIS_GRAPH_DATA, payload: value });
  }, []);

  const setMarketAnalysisFilters = useCallback((value) => {
    dispatch({ type: SET_MARKET_ANALYSIS_FILTERS, payload: value });
  }, []);



  useEffect(()=>{
    if( MarketAnalysisState.marketAnalysisFilters?.length===0 || !MarketAnalysisState.marketAnalysisFilters){
      fetchMarketAnalysifilters().then((res)=>{
        setMarketAnalysisFilters(res.data.data);
      }).catch((error)=>{
        console.log("Error While fetching Market Analysis Filters", error)
      })
    }
  },[MarketAnalysisState.marketAnalysisFilters])


  const contextValue = useMemo(() => {
    return {
      MarketAnalysisState,
      setSelectedRows,
      setColumnState,
      setSortModel,
      setMarketAnalysisGraphData,
      setMarketAnalysisFilters
    };
  }, [MarketAnalysisState]);

  return (
    <MarketAnalysisContext.Provider value={contextValue}>
      {children}
    </MarketAnalysisContext.Provider>
  );
};

// Create Global Context
export const MarketAnalysisContext = createContext(initialState);

// Export Global State Context Component
export default MarketAnalysisState;
