import { Button } from "antd";
import "./NotFound.scss";
export default function NotFound({ onClick }) {
  return (
    <div
      className="not-found-container"
      style={{
        backgroundImage: "url(bg.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <h4>404</h4>
      <h5>Page Not Found</h5>
      <p>Sorry, we can't find the page you're looking for.</p>
      <Button className="not-found-button" onClick={onClick}>Back to Home</Button>
    </div>
  );
}
