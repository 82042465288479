/* eslint-disable react-hooks/exhaustive-deps */
import { AgGridReact } from "ag-grid-react";
import {
  commonStyle,
  headerHeight,
  rowHeight,
} from "lib/constant/AgGridCommonStyle";
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_FONT_COLOR, TEAM_RUN_READONLY_COLUMNS } from "lib/constant/TeamRunReadonly/TeamRunReadonly";

import { GlobalContext } from "lib/contexts/GlobalContext";
import {
  addingBordersForCarbon,
  addingBordersForOptionsExceptCarbon,
} from "lib/helpers/GeneralFuncHelpers";
import { determineFontColorForTeamRun, onTeamRunCellClickListener } from "lib/helpers/TeamRunReadonly/utils";

import { useCallback, useContext, useMemo } from "react";

function SingleAgGridTable({
  item,
  teamRunData,
  onGridReady,
  onColumnResized,
  handleRowsSelection,
  gridRef,
  productIds,
  handleNotifyTeamRunRowsViewed,
}) {
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const getRowId = (params) => params.data.id;

  const rowClassRules = useMemo(() => {
    return {
      // row style expression
      "font-bold": (params) => params.data?.is_highlighted,
    };
  }, []);

  const cellClickedListener = useCallback(
    (e, productName) =>
      onTeamRunCellClickListener(e, productName, handleNotifyTeamRunRowsViewed),
    [productIds, teamRunData]
  );

  // Function to get row style
  const getRowStyle = (params) => {
    const { data, node } = params;
    const isSelected = node.selected;
    const isDarkTheme = dark_Theme;

    if (isSelected) {
      return { color: data.font_color || DEFAULT_FONT_COLOR };
    }

    let style = {
      color: determineFontColorForTeamRun(data, isDarkTheme),
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
    };

    // Apply dark theme background color if applicable
    if (isDarkTheme) {
      style.backgroundColor = commonStyle.backgroundColor;
    }

    // Apply special background color for new rows
    if (data.is_new) {
      style.backgroundColor = data.color;
    }

    return style;
  };

  return (
    <AgGridReact
    
      onRowDataUpdated={(event) => {
        if (item === "Carbon") {
          addingBordersForCarbon(event, item);
        } else {
          addingBordersForOptionsExceptCarbon(event);
        }
      }}
      getRowId={getRowId}
      ref={(ref) => (gridRef.current[item] = ref)}
      onGridReady={(params) => onGridReady(params, item)}
      className={`ag-theme-alpine   ${
        dark_Theme ? " ag-theme-balham-dark " : " ag-theme-balham"
      }`}
      
      columnDefs={TEAM_RUN_READONLY_COLUMNS(item)}
      rowData={teamRunData[item]}
      rowClassRules={rowClassRules}
      defaultColDef={{
        editable: false,
        resizable: true,
        filter: false,
      }}
      headerHeight={headerHeight}
      getContextMenuItems={[]}
      getRowStyle={getRowStyle}
      columnHoverHighlight={false}
      getRowHeight={rowHeight}
      onCellClicked={(e) => cellClickedListener(e, item)}
      // row selection
      multiSortKey="ctrl" // for multi sort
      checkboxSelection
      rowSelection={"multiple"}
      suppressRowClickSelection
    
      animateRows
      rowDragManaged
      suppressRowHoverHighlight
      suppressContextMenu
      onSelectionChanged={(params) => handleRowsSelection(params, item)}
      suppressMovableColumns={true}
      onColumnResized={(params) => onColumnResized(params, item)}
    />
  );
}

export default SingleAgGridTable;
