import { Select, Col, Row } from "antd";

export default function SetComparisonSurface({
  dark_Theme,
  disabledCondition,
  setSelectedFilters,
  comparisonGraph ,
  setComparisonGraph,
  setComparisonUpdated,
  userVolSurfaces,
}) {
  
  function onChangeHandle(e) {
    setSelectedFilters((prev) => ({
      ...prev,
      comparison_surface_id: e,
    }));
    setComparisonGraph(e);
    setComparisonUpdated(true);
  }
  return (
    <Col span={24}>
      <Row gutter={[25, 25]}>
        <Col span={3}>Set Comparison Surface:</Col>
        <Col>
          <Select
            placeholder="Select Comparison"
            className={dark_Theme ? "select-dark" : ""}
            popupClassName={dark_Theme ? "dropdown-dark" : ""}
            style={{ width: 180 }}
            allowClear
            value={ comparisonGraph}
            disabled={disabledCondition}
            onChange={(e) => onChangeHandle(e)}
            showSearch
            filterOption={(input, option) => {
              const inputSmallLetter = input.toLowerCase();
              const label = option.label.toLowerCase();
              return label.includes(inputSmallLetter);
            }}
            options={userVolSurfaces?.map((data) => ({
              value: data.id,
              label: data.title,
            }))}
          />
        </Col>
      </Row>
    </Col>
  );
}
