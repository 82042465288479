import axios from "api/axios";

export const getGraphsData = async (param) => {
  return await axios.get("/vol-surface/graphs", {
    params: {
      ...param,
    }
  });
};

export const updateGraphsData = async (body) => {
  return await axios.post("/vol-surface/graphs",{...body});
};

export const updateSecondGraphTable = async (body) => {
  return await axios.post("/vol-surface/calculate-volatility",{...body});
};

export const updateGraphsUnderlyingInput = async (body) => {
  return await axios.post("/user-vol-surfaces/update-underlying ",{...body});
};

export const updateMainGraphsUnderlyingInput = async (body) => {
  return await axios.post("/user-vol-surfaces/update-control-table ",{...body});
};

export const fetchTrailDataForVolSurface = async (queryParams) => {
  return await axios.get('vol-surface', {
    params: {
      ...queryParams,
    },
  })
};

export const batchUpdateGraphs = async (body) => {
  return await axios.post('vol-surface/update-batch', {...body})
};


export const fetchFiltersForVolSurface= async()=>{
  return await axios.get('vol-surface/graphs/filters');
}



export const fetchFilterShortCutsForVolSurface= async()=>{
  return await axios.get('vol-surface/shortcuts');
}


export const sendEmailForVolSurface= async()=>{
  return await axios.get('/vol-surface/export-date');
}

export const fetchUserVolSurface= async(queryParams)=>{
  return await axios.get('vol-surface/surfaces',{
    params:{...queryParams}
  });
}

export const fetchVolSurfaceContractFilter= async(queryParams)=>{
  return await axios.get('/vol-surface/filters/contract-styles',{
    params:{...queryParams}
  });
}

export const updateUserVolSurfaceTitle= async(body)=>{
  return await axios.post('vol-surface/store-surface', {...body});
}

export const setLiveForUserVolSurface= async(body)=>{
  return await axios.post('vol-surface/set-live', {...body});
}

export const updateOnEditCell = async (body) => {
  return await axios.post(`/vol-surface/control-table`, { ...body });
};
export const updateOnEditEstimateCell = async (body) => {
  return await axios.post(`/vol-surface/vol-calc-table`, { ...body });
};

export const getControlTableData = async (param) => {
  return await axios.get(`/vol-surface/control-table`, {
    params: {
      ...param,
    }});
}

export const getVolSurfaceData = async (param) => {
  return await axios.get(`/vol-surface/vol-surface-data`, {
    params: {
      ...param,
    },
  });
}
export const getSurfacesData = async (param) => {
  return await axios.get(`/vol-surface/vol-surface-data/surfaces`, {
    params: {
      ...param,
    },
  });
}
export const downloadVolSurfaceData = async (params) => {
  return await axios.get("/vol-surface/vol-surface-data/export", {
    params: {
      ...params,
    },
  });
};