import { getDataFromLocalStorage } from "lib/helpers/GeneralFuncHelpers";

export const GEEKS_USER = "geeks_user";
export const DARK_THEME = "dark_theme";
export const PRICER_OPT_INFO ="pricerOptInfo";
export const AUDIT_TRAIL_COLUMN_STATE ="auditTrail_columnState";
export const AUDIT_TRAIL_STATE ="auditTrail_state";

export const PROFILE_LABELS = {
  VIEW              : "View",
  CHANGE_PASSWORD   : "Change Password",
  SAVE              : "Save",
  CANCEL            : "Cancel",
  PROFILE           : "Profile",
  TEAM_RUN_MEMBERS  : "Team Run Members",
  EXIT              : "Exit"
};

export const PROFILE_INFO = {
  NAME: { label: "Name", key: "name" },
  EMAIL: { label: "Email", key: "email" },
  PASSWORD: { label: "Password", key: "password" },
  Company: { label: "Company", key: "company" },
  TEAM_RUN_MEMBERS: { label: "Team Run Members", key: "team_run_members" },
  VolSurfaceEmailList: {
    label: "Vol Surface Email List",
    key: "volSurfaceEmailList",
  },
  DARK_THEME: { label: "Dark Theme", key: "dark_theme" },
};


const findNameFromLocalStorage = getDataFromLocalStorage('geeks_user')
const findDarkThemeLocalStorage = getDataFromLocalStorage('dark_theme')

export const LOCAL_STORAGE_INFO = {
  name              : findNameFromLocalStorage?.name,
  username          : findNameFromLocalStorage?.username || "Not Exists",
  email             : findNameFromLocalStorage?.email || "Not Exists",
  password          : "*".repeat(10),
  darktheme         : findDarkThemeLocalStorage,
};

