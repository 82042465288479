export const TENOR_EXPIRY = "Tenor Expiry";
export const OTHER_INFORMATION = "Other Information";
export const EDIT_OTHER_DETAILS = "Edit Other Details";
export const OPTION = "Option";

export const EDIT_OTHER_DETAILS_LABELS = {
  CURRENT_AVERAGE       : {label : "Current Average",       isDisabled: true,  key : "average"},
  NEW_AVERAGE           : {label : "New Average",           isDisabled: false, key : "new_average"},
  CURRENT_COST_OF_CARRY : {label : "Current Cost Of Carry", isDisabled: true,  key : "cost_of_carry"},
  NEW_COST_OF_CARRY     : {label : "New Cost Of Carry",     isDisabled: false, key : "new_cost_of_carry"},
};
