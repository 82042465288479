export const NAVBAR_NAMES = {
  HOME: "Home",
  PRICER: "Pricer",
  TEAM_RUN_READONLY: "Team Run RO",
  VOL_SURFACE_DATA: "Data",
  NEW_PRICER: "Pricer Revamp",
  TEAM_RUN: "Team Run",
  AUDIT_TRAIL: "Audit Trail",
  TRADER: "Trader",
  VOL_SURFACE: "Vol Surface",
  MARKET_ANALYSIS: "Market Analysis",
  ARATUS: "Aratus",
  LOGOUT: "Logout",
  PROFILE: "Profile",
  REPORT_BUG: " Report a Bug",
};
