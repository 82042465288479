import axios from "api/axios";

export const fetchTypes = async (legsNum) => {
  return await axios.get(`option-types?number_of_legs=${legsNum}`)
};

export const fetchCommoditiesAPI = async () => {
  return await axios.get("products")
};

export const fetchContractStylesAPI = async (productId) => {
  return await axios.get(`products/${productId}/contracts-styles`)
};

export const fetchTenorAPI = async (contractStyleSelected) => {
  return await axios.get(`contracts-styles/tenors?contract_style=${contractStyleSelected}`)
};

export const fetchBespokeTenorAPI = async (contractStyleSelected) => {
  return await axios.get(`/bespoke-tenors?contract_style=${contractStyleSelected}`)
};

export const fetchConstraintsAPI = async (typeId, legsNum, contract_style) => {
  return await axios.get(
    `constratints?option_type_id=${typeId}&number_of_legs=${legsNum}&contract_style=${contract_style}`
  );
};

export const submitAddOptionAPI = async (body) => {
  return await axios.post(`/option-rows`,{...body})
};
