import { Checkbox, Col } from "antd";
import { FormItem } from "./AddOptionHelperComponents";
import { FIELDS_LABEL } from "lib/constant/AddOption/AddOption";

function LiveOption({
  fetchedData,
  onLegsChange,
  isLiveChecked,
  setIsLiveChecked,
}) {
  
  return (
    <Col span={24}>
      <FormItem label={FIELDS_LABEL.LIVE.label}>
        <Checkbox
          checked={isLiveChecked}
          disabled={fetchedData[FIELDS_LABEL.CONSTRAINTS.key].live !== null}
          onChange={(event) => {
            const isChecked = event.target.checked;
            setIsLiveChecked(() => isChecked);
            onLegsChange(FIELDS_LABEL.LIVE.key, isChecked ? 1 : 0);
          }}
        />
      </FormItem>
    </Col>
  );
}

export default LiveOption;
