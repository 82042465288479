import  { useContext } from "react";
import _ from 'lodash';
import { useQuery } from "@tanstack/react-query";
import axios from "api/axios";
import { TeamRunReadonlyContext } from "lib/contexts/TeamRunContextReadonly";

export const prepareBody = (response) => {
  if(_.isEmpty(response)) return;
  let sortedBody = {};
  Object.keys(response).forEach(item => {
    const { title, product_id, rows } =response[item];
    sortedBody = {
      ...sortedBody,
      [title] : rows
    }
  });
  return sortedBody;
};
const useTeamRunReadonly = () => {
  const { setTeamRunData, setProductIds } = useContext(TeamRunReadonlyContext);

  // APIS FUNC
  // useQuery(
  //   ["products"],
  //   async () =>
  //     await axios
  //       .get("team-run/products")
  //       .then((res) => res.data.data)
  //       .catch((err) => console.error({ err })),
  //   {
  //     onSuccess: (product) => setProductIds(product),
  //     retryOnMount: false,
  //     refetchOnReconnect: false,
  //     refetchOnWindowFocus: false,
  //     retry: true,
  //   }
  // );

  const { isFetching } = useQuery(
    ["team-run-read-only"],
    async () =>
      await axios
        .get("team-run/read-only")
        .then((res) => res?.data?.data)
        .catch((err) => console.error({ err })),
    {
      retryOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: true,
      onSuccess: (successItem) => {
        const res = prepareBody(successItem);
        setTeamRunData(res);
      },
    }
  );

  return { isFetching };
};

export default useTeamRunReadonly;
