import { Col, Space, DatePicker,Row } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

export default function SelectDate({
    dark_Theme,
    start_date,
    end_date,
    handleRangePickerChange,
    showTradeButtons
}) {
  return (
    <>
      <Col span={24}>
        <Space>{showTradeButtons}</Space>
      </Col>
      <Col span={24}>
        <Row size={"middle"} className={`${dark_Theme && "date-picker-dark"}`}>
          <Col span={3}>
            <span style={{ whiteSpace: "nowrap" }}>Data to include:</span>
          </Col>
          <Col>
            <RangePicker
              onChange={handleRangePickerChange}
              className={start_date && "filled--data_rangePicker__background"}
              popupClassName={dark_Theme && "date-picker-dropdown-dark"}
              value={start_date ? [moment(start_date), moment(end_date)] : ""}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
}
