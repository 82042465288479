import React from "react";
import { Button, Col, InputNumber, Row } from "antd";
import CustomTradeInputForDelta from "components/Common/CustomTradeInputForDelta/CustomTradeInputForDelta";
import {
  API_KEYS,
  BACKEND_KEYS_NAME,
} from "lib/constant/EditTradeDetails/EditTradeDetails";
import HedgeItem from "./HedgeItem";

const ShowLegsDetail = ({
  handleLegsPremiumChange,
  placeholdersTitle,
  editLegValue,
  handleLegsQuantityChange,
  handleHedgeSwapSignBtnChange,
  temp_summary_id,
  isSellActive,
  setIsRequestActive,
  reformatAndSetTradeData,
  handleHedgeQuantityChange,
  ...item
}) => {
  const index =
    editLegValue.length > 0
      ? editLegValue.findIndex((data) => data.id === item.id)
      : -1;
  const isSell =
    item.type === BACKEND_KEYS_NAME.LEGS
      ? item["sell_or_buy"] === "sell"
      : item.hedges[0]["sell_or_buy"] === "sell";
  return (
    <Row gutter={[20, 15]}>
      {item.type === BACKEND_KEYS_NAME.LEGS && (
        <>
          <Col span={16}>
            <Row gutter={20}>
              <Col span={8}>
                <span>{item?.label}</span>
              </Col>
              <Col span={4}>
                <InputNumber
                  onStep={() => setIsRequestActive(true)}
                  onInput={() => setIsRequestActive(true)}
                  onFocus={() => setIsRequestActive(true)}
                  onBlur={() => setIsRequestActive(false)}
                  placeholder={
                    placeholdersTitle?.[API_KEYS.QUANTIZE_PLACEHOLDER]
                  }
                  disabled={
                    item[BACKEND_KEYS_NAME.VOLATILITY]?.[
                      BACKEND_KEYS_NAME.IS_DISABLED
                    ] || true
                  }
                  value={item[BACKEND_KEYS_NAME.VOLATILITY]?.value}
                  onChange={(value) => {
                    handleLegsQuantityChange(item.id, value);
                  }}
                  addonAfter={item[BACKEND_KEYS_NAME.VOLATILITY]?.symbol}
                />
              </Col>
              <Col span={4}>
                {item.type === BACKEND_KEYS_NAME.LEGS ? (
                  <InputNumber
                    onStep={() => setIsRequestActive(true)}
                    onInput={() => setIsRequestActive(true)}
                    onFocus={() => setIsRequestActive(true)}
                    onBlur={() => setIsRequestActive(false)}
                    type="number"
                    placeholder={
                      placeholdersTitle?.[API_KEYS.QUANTIZE_PLACEHOLDER]
                    }
                    disabled={
                      item[BACKEND_KEYS_NAME.PREMIUM]?.is_disabled || false
                    }
                    defaultValue={
                      item[BACKEND_KEYS_NAME.PREMIUM]?.[BACKEND_KEYS_NAME.VALUE]
                    }
                    value={
                      index !== -1
                        ? editLegValue[index].value
                        : item[BACKEND_KEYS_NAME.PREMIUM]?.[
                            BACKEND_KEYS_NAME.VALUE
                          ]
                    }
                    step={item[BACKEND_KEYS_NAME.STEP_SIZE] || 0.005}
                    onChange={(value) => {
                      handleLegsPremiumChange((prev) => {
                        if (prev.length > 0) {
                          let newData = prev.map((data) => {
                            return { ...data, edit: false };
                          });
                          if (index !== -1) {
                            newData[index] = {
                              edit: true,
                              id: item.id,
                              value: value,
                            };
                          } else {
                            newData.push({
                              edit: true,
                              id: item.id,
                              value: value,
                            });
                          }
                          return newData;
                        } else {
                          return [{ edit: true, id: item.id, value: value }];
                        }
                      });
                    }}
                  />
                ) : null}
              </Col>
              <Col span={4}>
                <InputNumber
                  className="edit-company-trader-input"
                  placeholder={
                    placeholdersTitle?.[API_KEYS.COMPANY_TRADE_PLACEHOLDER]
                  }
                  disabled={true}
                  value={item[BACKEND_KEYS_NAME.COMPANY_TRADER]}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  className="edit-company-trader-input"
                  placeholder={placeholdersTitle?.[API_KEYS.TRADE_PLACEHOLDER]}
                  disabled={true}
                  value={item[BACKEND_KEYS_NAME.TRADER]}
                />
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row gutter={20}>
              <Col span={3}>
                {item["sell_or_buy"] && (
                  <span className="sell-or-buy--label">
                    {item["sell_or_buy"]}
                  </span>
                )}
              </Col>
              <Col span={5}>
                <InputNumber
                  placeholder={
                    placeholdersTitle?.[API_KEYS.EXCHANGE_PLACEHOLDER]
                  }
                  disabled={true}
                  value={item[BACKEND_KEYS_NAME.EXCHANGE]}
                  style={{ minWidth: 95, maxWidth: 95 }}
                />
              </Col>
              <Col span={10}>
                {item.type === BACKEND_KEYS_NAME.LEGS ? (
                  <InputNumber
                    placeholder={
                      placeholdersTitle?.[API_KEYS.QUANTIZE_PLACEHOLDER]
                    }
                    disabled={
                      item[BACKEND_KEYS_NAME.QUANTITY_T]?.[
                        BACKEND_KEYS_NAME.IS_DISABLED
                      ] || false
                    }
                    onStep={() => setIsRequestActive(true)}
                    onInput={() => setIsRequestActive(true)}
                    onFocus={() => setIsRequestActive(true)}
                    onBlur={() => setIsRequestActive(false)}
                    value={item[BACKEND_KEYS_NAME.QUANTITY_T]?.value}
                    step={
                      item[BACKEND_KEYS_NAME.QUANTITY_T][
                        BACKEND_KEYS_NAME.STEP_SIZE
                      ] || 1
                    }
                    onChange={(value) => {
                      handleLegsQuantityChange(item.id, value);
                    }}
                    addonAfter={
                      item[BACKEND_KEYS_NAME.QUANTITY_T][
                        BACKEND_KEYS_NAME.SYMBOL
                      ]
                    }
                  />
                ) : null}
              </Col>
            </Row>
          </Col>
        </>
      )}

      {item.type === BACKEND_KEYS_NAME.HEDGE && (
        <>
          {item?.hedges &&
            item?.hedges?.map((hedge, index) => (
              <React.Fragment key={hedge.id}>
                <Col span={16}>
                  <Row gutter={20}>
                    <Col span={8}>
                      <span>{hedge?.label}</span>
                    </Col>
                    <Col span={4}></Col>
                    <Col span={4}>
                      {item.type === BACKEND_KEYS_NAME.HEDGE ? (
                        <p
                          style={{ textAlign: "start" }}
                          className="edit-trade-underlying"
                        >
                          {
                            hedge[BACKEND_KEYS_NAME.UNDERLYING]?.[
                              BACKEND_KEYS_NAME.VALUE
                            ]
                          }
                        </p>
                      ) : null}
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        className="edit-company-trader-input"
                        placeholder={
                          placeholdersTitle?.[
                            API_KEYS.COMPANY_TRADE_PLACEHOLDER
                          ]
                        }
                        disabled={true}
                        value={item[BACKEND_KEYS_NAME.COMPANY_TRADER]}
                      />
                    </Col>
                    <Col span={4}>
                      <InputNumber
                        className="edit-company-trader-input"
                        placeholder={
                          placeholdersTitle?.[API_KEYS.TRADE_PLACEHOLDER]
                        }
                        disabled={true}
                        value={item[BACKEND_KEYS_NAME.TRADER]}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={20}>
                    <Col span={3}>
                      {hedge["sell_or_buy"] && (
                        <span className="sell-or-buy--label">
                          {hedge["sell_or_buy"]}
                        </span>
                      )}
                    </Col>
                    <Col span={5}>
                      <InputNumber
                        placeholder={
                          placeholdersTitle?.[API_KEYS.EXCHANGE_PLACEHOLDER]
                        }
                        disabled={true}
                        value={item[BACKEND_KEYS_NAME.EXCHANGE]}
                        style={{ minWidth: 95, maxWidth: 95 }}
                      />
                    </Col>
                    <Col span={10}>
                      {item.type === BACKEND_KEYS_NAME.HEDGE ? (
                        <>
                          <CustomTradeInputForDelta
                            isDelta={false}
                            hedge={hedge}
                            placeholdersTitle={placeholdersTitle}
                            handleHedgeQuantityChange={
                              handleHedgeQuantityChange
                            }
                            setIsRequestActive={setIsRequestActive}
                            isSellActive={isSellActive}
                          />

                          <div style={{ margin: "10px 0" }}>
                            <HedgeItem
                              hedge={hedge}
                              placeholdersTitle={placeholdersTitle}
                              isSellActive={isSellActive}
                              setIsRequestActive={setIsRequestActive}
                              handleHedgeQuantityChange={
                                handleHedgeQuantityChange
                              }
                            />
                          </div>
                        </>
                      ) : null}
                    </Col>
                    <Col span={4}>
                      {index === 0 && (
                        <Button
                          type="primary"
                          onClick={() =>
                            handleHedgeSwapSignBtnChange(
                              isSell ? "buy" : "sell"
                            )
                          }
                        >
                          Swap sign
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </React.Fragment>
            ))}
        </>
      )}
    </Row>
  );
};

export default ShowLegsDetail;
