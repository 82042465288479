import { AgGridReact } from "ag-grid-react"; // React Grid Logic

import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GlobalContext } from "lib/contexts/GlobalContext";
import "./gridStyles.css";
import { VOL_SURFACE_Month_Estimates_COLS } from "lib/constant/VolSurface";
import { updateOnEditEstimateCell } from "api/VolSurface/VolSurface";
import { useVolSurfaceControlTable } from "lib/contexts/VolSurfaceControlTableContext";
import { getRowClass, getRowStyle } from "lib/constant/AgGridCommonStyle";
import { updateVolSurfaceEstimateData } from "lib/helpers/VulSurface/VulSurface";

export default function AgGridMonthEstimatesTable({ rowData }) {
  const [gridHeight, setGridHeight] = useState("auto");
  const gridRef = useRef();

  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const {
    volSurfaceTablesData,
    setIsCellEditing,
    setRowEditingData,
    isLoading,
    selectedFilters,
    setVolCalcTable,
    volCalcTable,
  } = useVolSurfaceControlTable();

  const columnDefs = useMemo(() => VOL_SURFACE_Month_Estimates_COLS, []);

  const onCellEditingStopped = useCallback(
    (event) => {
      +event.oldValue === +event.newValue && setIsCellEditing(false);
      +event.oldValue === +event.newValue && setIsCellEditing(false);
      if (+event.oldValue === +event.newValue) return;

      const node = event.node;
      const rowData = node.group ? node.aggData : node.data;

      const prepareBody = {
        vol_smile_id: rowData.vol_smile_id,
        new_strike: +event.newValue,
        column_key: event?.colDef?.field === "0" ? 0 : 1,
      };

      setIsCellEditing(true);
      updateOnEditEstimateCell(prepareBody)
        .then((res) => {
          if (res.status !== 200) {
            revertCellValue(event.node, event.oldValue);
          } else {
            const updatedData = updateVolSurfaceEstimateData(
              res.data.data,
              volCalcTable
            );
            setVolCalcTable(updatedData);
            let refreshParams = { force: true };
            gridRef.current.api.refreshCells(refreshParams);
            setIsCellEditing(false);
            setRowEditingData(null);
          }
        })
        .catch((e) => {
          revertCellValue(event.node, event.oldValue);
          console.log("Error while setting data");

          setIsCellEditing(false);
          setRowEditingData(null);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsCellEditing, setRowEditingData, setVolCalcTable, volCalcTable]
  );
  const gridOptions = {
    stopEditingWhenGridLosesFocus: false,
  };
  const getRowId = (params) => {
    return params.data.id;
  };

  gridOptions.onCellEditingStarted = function (event) {
    var editor = event.api.getCellEditorInstances({
      rowIndex: event.rowIndex,
      column: event.column,
    });
    if (editor && editor.length > 0) {
      editor[0].getGui().style.color = dark_Theme ? "white" : "black";
    }
  };

  const revertCellValue = (node, value) => {
    node?.setDataValue(columnDefs[0]?.field, value);
  };

  useEffect(() => {
    if (!volSurfaceTablesData) {
      return;
    }
    const numberOfRowData = volSurfaceTablesData.length;
    const calculatedHeight = numberOfRowData * 30 + 95;
    setGridHeight(`${calculatedHeight}px`);
  }, [volSurfaceTablesData]);

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    gridRef.current.api.showLoadingOverlay();
  };

  if (
    isLoading ||
    !selectedFilters?.product_id 
  )
    return null;

  return (
    <div
      className={`${
        dark_Theme || false
          ? "pricer-ag-grid__container_dark"
          : "pricer-ag-grid__container"
      }`}
      style={{
        height: gridHeight,
        width: "15%",
        padding: "5px 0px 5px 0px",
      }}
    >
      <AgGridReact
        getRowId={getRowId}
        onCellEditingStopped={(event, pricerTablesData) =>
          onCellEditingStopped(event, pricerTablesData)
        }
        headerHeight={30}
        rowHeight={30}
        rowData={volCalcTable}
        getRowStyle={getRowStyle}
        getRowClass={getRowClass}
        ref={gridRef}
        columnDefs={columnDefs}
        suppressContextMenu
        suppressMovableColumns={true}
        onGridReady={onGridReady}
        className={`ag-theme-alpine pricer-ag-grid__table ${
          dark_Theme ? " ag-theme-balham-dark" : "ag-theme-balham"
        }`}
      />
    </div>
  );
}
