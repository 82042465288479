import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from "react";
import _ from "lodash";
import { Dropdown, Menu, Space } from "antd";
// Icons
import { MenuOutlined } from "@ant-design/icons";
// Helpers

// Context
import { GlobalContext } from "lib/contexts/GlobalContext";
import { extractCopyShortHandForSummaryRows } from "lib/helpers/GeneralFuncHelpers";
import { ACTION_BUTTONS_NAME } from "lib/constant/GeneralActionButtons";
import useGAEvenetsTraker from "lib/hooks/useGAEvenetsTraker";
import { GA_EVENTS_NAMES } from "lib/constant/GoogleAnalytics/GoogleAnalytics";
import { TraderContext } from "lib/contexts/TraderContext";
import { ACTION_COLUMN_MENU_ITEMS } from "lib/constant/Trader/Trader";

const TraderActionsColumn = (props) => {
  const {
    state: { selectedRows, teamRunData },
  } = useContext(TraderContext);
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const googleEvenTracker = useGAEvenetsTraker("event");

  const [menuItemsClicked, setMenuItemsClicked] = useState("");
  const [menuItemsData, setMenuItemsData] = useState(ACTION_COLUMN_MENU_ITEMS);
  const [rowData, setRowData] = useState({});

  useEffect(() => {
    if (_.isEmpty(props.data)) return;

    setRowData(props.data);
  }, [props.data]);

  const selectedRowsLength = useMemo(
    () =>
      props.data &&
      selectedRows &&
      selectedRows[props.data.product_title]?.length > 1,
    [props.data, selectedRows, teamRunData]
  );

  useEffect(() => {
    setMenuItemsData(
      selectedRowsLength
        ? [
            ...returnSelectedDropDown().filter(
              (item) =>
                item.key === "copy_shorthand_no_vols" ||
                item.key === "copy_shorthand" ||
                item.key === "export_rows"
            ),
          ]
        : menuItemsData
    );
  }, [selectedRowsLength, props.data, teamRunData]);

  const returnSelectedDropDown = () => {
    if (rowData) {
      return menuItemsData;
    }
    return null;
  };

  const exportSelectedRowsToCSV = (props) => {
    const { api } = props;
    // Specify the column keys for the columns 'title' and 'broker'
    const columnKeys = ["title", "broker"];
    const today = new Date().toISOString().slice(0, 10); // Gets the date portion of the ISO string

    // Define parameters for exporting
    const params = {
      columnKeys: columnKeys, // Include only the 'title' and 'broker' columns
      onlySelected: true, // Only export selected rows
      fileName: `open_trades_${today}.csv`, // Dynamic filename including today's date
    };

    // Use the grid API to export the data to CSV
    api.exportDataAsCsv(params);
  };

  const handleMenuItemClick = useCallback(
    async (itemClicked) => {
      if (itemClicked === ACTION_BUTTONS_NAME.COPY_SHORTHAND.key) {
        extractCopyShortHandForSummaryRows(props);
      }

      if (itemClicked === ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key) {
        googleEvenTracker(
          GA_EVENTS_NAMES.TEAM_RUN_PAGE_COPY_SHORTHAND_NO_VOL_CLICK,
          "Trader"
        );
        extractCopyShortHandForSummaryRows(props, false);
        return;
      }

      if (itemClicked === ACTION_BUTTONS_NAME.EXPORT_ROWS.key) {
        exportSelectedRowsToCSV(props);
      }

      setTimeout(() => setMenuItemsClicked(""), 0);
    },
    [rowData, selectedRows, selectedRowsLength]
  );

  const showDropDown = () => {
    return (
      <Dropdown
        className={`action-buttons__dropdown button-icon ${
          !dark_Theme && "button-light-theme"
        }`}
        trigger={["click"]}
        overlay={
          <Menu
            onClick={({ key }) => handleMenuItemClick(key)}
            key="action-buttons__menu-items_MENU"
            items={
              !menuItemsClicked && !selectedRowsLength
                ? returnSelectedDropDown()?.sort((a, b) => a.index - b.index)
                : menuItemsData?.sort((a, b) => a.index - b.index)
            }
            theme={dark_Theme ? "dark" : "light"}
          />
        }
        overlayClassName={dark_Theme && "menu-dropdown-dark"}
      >
        <Space>
          <MenuOutlined />
        </Space>
      </Dropdown>
    );
  };

  return (
    <div
      className="action-buttons__container action-button_container"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        width: "100%",
        height: "100%",
      }}
    >
      {/* Show Menu Item When Menu Icon Clicked */}
      {showDropDown()}
    </div>
  );
};

export default React.memo(TraderActionsColumn);
