import { BUTTON_NAMES, FIELDS_LABEL, INPUT_KEYS, IS_BUY, IS_CALL } from "lib/constant/AddOption/AddOption";
import { formatNumber } from "../GeneralFuncHelpers";


export const findGCD = (input,d) => {
  if (toString.call(input) !== "[object Array]")  
      return  false;  
  var len, a, b; 
len = input.length;
if ( !len ) {
  return null;
}
a = input[ 0 ];
for ( var i = 1; i < len; i++ ) {
  b = input[ i ];
  a = gcd_two_numbers( a, b );
}
return a;
}

function gcd_two_numbers(x, y) {
  if ((typeof x !== 'number') || (typeof y !== 'number')) 
    return false;
  x = Math.abs(x);
  y = Math.abs(y);
  while(y) {
    var t = y;
    y = x % y;
    x = t;
  }
  return x;
}


export const reformatSubmitData = (data) =>{
  const concatRatiosWithStrikes = Object.keys(data['strikeValues']).map((item, index) => {
    const strikeValue = data['strikeValues'][item];
    const ratioValues = data['ratioValues'][item];
    return {
      strike:strikeValue.value,
      ratio : ratioValues.value,
      buy: ratioValues[INPUT_KEYS.BUTTON] === BUTTON_NAMES.BUY ? ratioValues[IS_BUY] = 1 : ratioValues[IS_BUY] = 0,
      call: strikeValue[INPUT_KEYS.BUTTON] === BUTTON_NAMES.CALL ? strikeValue[IS_CALL] = 1 : strikeValue[IS_CALL] = 0
    }
  });
  let bespokeCheck= JSON.parse(JSON.stringify(data.checkedBespoke)) 

  let tenors_data=[]
   Object.keys(bespokeCheck).length && Object.keys(bespokeCheck).map((bespokeKey)=>{
    let check=bespokeCheck[bespokeKey]?1:0
    if(check){
      let bespokeData={
        is_bespoke:check,
        style_period_tenor_id: 'Bespoke',
        style_period_tenor_months:data[FIELDS_LABEL.TENOR.key][bespokeKey].map((bespoke)=>{
          return {
            style_period_tenor_month_id: bespoke
          }
        })
      }
      tenors_data.push(bespokeData)
    }
    else{

      let nonBespokeData= {
        is_bespoke:check,
        style_period_tenor_id: data[FIELDS_LABEL.TENOR.key][bespokeKey],
        style_period_tenor_months:[],
      }
      tenors_data.push(nonBespokeData)
    }

  })
  
  
  
  

  data = {
    ...data,
    tenors: tenors_data,
    product_id : data[FIELDS_LABEL.COMMODITY.key],
    contract_style : data[FIELDS_LABEL.CONTRACT_STYLE.key],
    option_type_id : data[FIELDS_LABEL.TYPE.key],
    cost_of_carry : data[FIELDS_LABEL.COST_OF_CARRY.key],
    underlyings: Object.values(data['underlyingData']).map(item => ({value: item})),
    [FIELDS_LABEL.AVERAGES.key] :  data[FIELDS_LABEL.AVERAGES.key],
    num_legs : data[FIELDS_LABEL.LEGS.key],
    legs : concatRatiosWithStrikes // ratios and strikes
  };

  delete data.checkedBespoke;
  delete data.contractStyle;
  delete data.costOfCarry;
  delete data.ratioValues;
  delete data.strikeValues;
  delete data.underlyingData;
  
  return data;
};


export const formatUnit = (value,params) => {
  if(value === '' || !value) return value;
  let formattedValue = value;
  const field = params.colDef.field;
  const data = params.node.group ? params.node.aggData : params.node.data;
  let transfomedFieldKey = field.replace('bid','premium').replace('ask','premium').replace('company','premium');
  const unitKey = transfomedFieldKey + '_unit';

  const unit = data[unitKey];
  if(unit !== undefined &&  unit !== '') formattedValue = formatNumber(value) + unit;
  
  return formattedValue;
};

 
export const isSublegRow = (node) => {
  if(node.level === 2) return true;
  const rowData = node.group ? node.aggData : node.data;
  const isSubleg = (node.level === 1 && rowData.level_one_row_type === 'subleg');
  return isSubleg;
}