import { Input, InputNumber } from "antd";
// Styles
import "components/InputWithLabel/InputWithLabel.scss";

const InputWithLabel = (props) => {
  return (
    <div className="input-field-with-label" key={props.key}>
      <span className="label">{props.label}</span>
      {props.additionalData ? (
        <Input
          className={`${props?.isDisabled && "ant-input-number-disabled "} ${
            !props?.width ? "input-field-units" : ""
          }`}
          style={{ width: props?.width }}
          disabled={props.isDisabled}
          value={props.value}
          onChange={(value) => props.onChange(props.itemKey, value)}
          addonBefore={props.isUnitBeforeText ?? ""}
          addonAfter={props.measureUnitValue ?? ""}
        />
      ) : (
        <InputNumber
          className={`${props?.isDisabled && "ant-input-number-disabled "} input-field-units`}
          style={{ width: props?.width }}
          disabled={props.isDisabled}
          value={props.value}
          suffix={props.measureUnitValue}
          onChange={(value) => props.onChange(props.itemKey, value)}
          addonBefore={props.isUnitBeforeText ?? ""}
          addonAfter={props.measureUnitValue ?? ""}
        />
      )}
    </div>
  );
};

export default InputWithLabel;
