import { Select, Col, Row } from "antd";
import { VOL_SURFACE_GRAPGH_FILTERS } from "lib/constant/VolSurface";
import { getDataFromLocalStorage } from "lib/helpers/GeneralFuncHelpers";

export default function SetLiveSurface({
  dark_Theme,
  liveGraphId = getDataFromLocalStorage(VOL_SURFACE_GRAPGH_FILTERS)
    ?.live_surface_id,
  disabledCondition,
  setLiveGraphId,
  setSelectedFilters,
  setComparisonGraph,
  setComparisonUpdated,
  userVolSurfaces,
}) {
  
  function onChangeHandle(e) {
    setLiveGraphId(e);
    setSelectedFilters((prev) => ({
      ...prev,
      live_surface_id: e,
    }));
    // setComparisonGraph(null);
    setComparisonUpdated(true);
  }

  function onClearHandle() {
    setLiveGraphId(null);
    //   setComparisonGraph(null);
    //   setComparisonUpdated(true);
    setSelectedFilters((prev) => ({
      ...prev,
      live_surface_id: null,
      // comparison_surface_id: null,
    }));
  }

  return (
    <Col span={24}>
      <Row gutter={[25, 25]}>
        <Col span={3}>Set Live Surface:</Col>
        <Col>
          <Select
            placeholder="Select Surface"
            className={
              dark_Theme
                ? "content_dark select-dark ant-select-dark-disabled"
                : "content_light select-light ant-input-light-disabled"
            }
            popupClassName={dark_Theme ? "dropdown-dark" : ""}
            value={liveGraphId || undefined}
            style={{ width: 180 }}
            disabled={disabledCondition}
            onChange={(e) => onChangeHandle(e)}
            onClear={() => onClearHandle()}
            allowClear
            showSearch
            filterOption={(input, option) => {
              const inputSmallLetter = input.toLowerCase();
              const label = option.label.toLowerCase();
              return label.includes(inputSmallLetter);
            }}
            options={userVolSurfaces?.map((data) => ({
              value: data.id,
              label: data.title,
            }))}
          />
        </Col>
      </Row>
    </Col>
  );
}
