import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal, Input, InputNumber, Button } from "antd";
// Constants
// API
import { finishUpdateBidOrAsk, removePremiumInBidOrAsk } from "api/TeamRun";
// Styles
import "./UpdateBid.scss";
import { toast } from "lib/helpers/utilities";
import { GlobalContext } from "lib/contexts/GlobalContext";
import { ACTION_BUTTONS_NAME } from "lib/constant/GeneralActionButtons";
import useGAEvenetsTraker from "lib/hooks/useGAEvenetsTraker";
import { GA_EVENTS_NAMES } from "lib/constant/GoogleAnalytics/GoogleAnalytics";
import { UPDATE_BID_AND_ASK_MODAL_INFO } from "lib/constant/TeamRunReadonly/TeamRunReadonly";

const UpdateBid = ({
  isOpen,
  setIsOpen = () => {},
  data = null,
  selectedModal,
  ...rest
}) => {
  const [tradingCompany, setTradingCompany] = useState(
    selectedModal === ACTION_BUTTONS_NAME.UPDATE_ASK.key
      ? {
          key: "ask_trading_company_id",
          value: data?.ask_trading_company_id,
        }
      : {
          key: "bid_trading_company_id",
          value: data?.bid_trading_company_id,
        }
  );
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const googleEvenTracker = useGAEvenetsTraker("event");

  const [newBidValue, setNewBidValue] = useState(null);
  const [oldValue, setOldValue] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);

  const hideModal = () => {
    setIsOpen("");
    setNewBidValue(null);
  };

  const onFinish = async () => {
    if (!tradingCompany.value)
      return toast("Please add a valid trader company name", "warning");
    setIsSubmitLoading(true);

    const traderType =
      selectedModal === ACTION_BUTTONS_NAME.UPDATE_ASK.key
        ? { ask_trading_company_id: tradingCompany.value }
        : { bid_trading_company_id: tradingCompany.value };
    finishUpdateBidOrAsk({
      summary_id: data.summary_id,
      is_ask: selectedModal === ACTION_BUTTONS_NAME.UPDATE_ASK.key ? 1 : 0,
      premium: newBidValue,
      ...traderType,
    })
      .then((res) => {
        if (selectedModal === ACTION_BUTTONS_NAME.UPDATE_ASK.key) {
          googleEvenTracker(
            GA_EVENTS_NAMES.UPDATE_ASK_MODAL_UPDATE_ASK_TRIGGER,
            "Team Run"
          );
        } else {
          googleEvenTracker(
            GA_EVENTS_NAMES.UPDATE_BID_MODAL_UPDATE_BID_TRIGGER,
            "Team Run"
          );
        }
        toast(res?.data?.message, "success");
        rest.triggerSingleAPIFunc(data.product_id, data.product_title);
        setIsSubmitLoading(false);
        hideModal();
      })
      .catch((err) => {
        setIsSubmitLoading(false);
        console.log({ err }, "ERR Remove Premium");
      });
  };

  const onRemoveBid = () => {
    if (!tradingCompany.value)
      return toast("Please add a valid tradingCompany name", "error");
    setIsRemoveLoading(true);

    removePremiumInBidOrAsk({
      summary_id: data.summary_id,
      is_ask: selectedModal === ACTION_BUTTONS_NAME.UPDATE_ASK.key ? 1 : 0,
    })
      .then((res) => {
        toast(res?.data?.message, "success");
        if (selectedModal === ACTION_BUTTONS_NAME.UPDATE_ASK.key) {
          googleEvenTracker(
            GA_EVENTS_NAMES.UPDATE_ASK_MODAL_UPDATE_ASK_TRIGGER,
            "Team Run"
          );
        } else {
          googleEvenTracker(
            GA_EVENTS_NAMES.UPDATE_BID_MODAL_UPDATE_BID_TRIGGER,
            "Team Run"
          );
        }
        rest.triggerSingleAPIFunc(data.product_id, data.product_title);
        setIsRemoveLoading(false);
        hideModal();
      })
      .catch((err) => {
        console.log({ err }, "ERR Remove Premium");
        setIsRemoveLoading(false);
        hideModal();
      });
  };

  const selectMatchedTitle = useMemo(
    () =>
      selectedModal === ACTION_BUTTONS_NAME.UPDATE_ASK.key
        ? ACTION_BUTTONS_NAME.UPDATE_ASK.label
        : ACTION_BUTTONS_NAME.UPDATE_BID.label,
    [selectedModal]
  );

  const selectMatchedLabel = useMemo(() => {
    if (selectedModal === ACTION_BUTTONS_NAME.UPDATE_BID.key) {
      return {
        PREV: UPDATE_BID_AND_ASK_MODAL_INFO.PREVIOUS_BID.label,
        NEW: UPDATE_BID_AND_ASK_MODAL_INFO.NEW_BID.label,
        DELETE_BUTTON: UPDATE_BID_AND_ASK_MODAL_INFO.REMOVE_BID.label,
      };
    }

    return {
      PREV: UPDATE_BID_AND_ASK_MODAL_INFO.PREVIOUS_ASK.label,
      NEW: UPDATE_BID_AND_ASK_MODAL_INFO.NEW_ASK.label,
      DELETE_BUTTON: UPDATE_BID_AND_ASK_MODAL_INFO.REMOVE_ASK.label,
    };
  }, [selectedModal]);

  useEffect(() => {
    setNewBidValue(oldValue);
  }, [oldValue]);

  useEffect(() => {
    if (!data) return;
    selectedModal === ACTION_BUTTONS_NAME.UPDATE_ASK.key
      ? setOldValue(data?.["ask_old"])
      : setOldValue(data?.["bid_old"]);
  }, [data?.summary_id, data, selectedModal]);

  return (
    <div className="update-bid__container">
      <Modal
        open={isOpen}
        className={dark_Theme && "ant-modal-dark content_dark"}
        onCancel={hideModal}
        title={selectMatchedTitle}
        footer={[
          <Button key="back" onClick={hideModal}>
            {UPDATE_BID_AND_ASK_MODAL_INFO.CANCEL.label}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={onFinish}
            loading={isSubmitLoading}
          >
            {UPDATE_BID_AND_ASK_MODAL_INFO.FINISH.label}
          </Button>,
          <Button onClick={onRemoveBid} loading={isRemoveLoading}>
            {selectMatchedLabel.DELETE_BUTTON}
          </Button>,
        ]}
      >
        <div className="update-bid__container--modal">
          <span className="input-label">{selectMatchedLabel.PREV}</span>
          <Input className="input" value={oldValue} disabled />
          <span className="input-label">{selectMatchedLabel.NEW}</span>
          <InputNumber
            className="input"
            value={newBidValue}
            precision={3}
            step={0.005}
            onChange={(value) => setNewBidValue(value)}
          />
          <span className="input-label">Trader Company</span>
          <Input
            style={{
              border: "1px solid var(--color-gray-2)",
            }}
            type="text"
            className="input"
            value={tradingCompany.value}
            onChange={(e) =>
              setTradingCompany({ ...tradingCompany, value: e.target.value })
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default UpdateBid;
