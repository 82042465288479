export const GA_TRACKING_CODE = process.env.REACT_APP_GA_TRACKING_CODE; ; 

//Follow the format for event name <(page-name)-page/(modal-name)-modal>_<actiontype>_<detail>

export const GA_EVENTS_NAMES= {
    LOGOUT: "logout",
    LOGIN: "login",

    // pricer events for google analytics
    PRICER_PAGE_CHANGE_ROW_COLOR_CLICK:'pricer-page_change-row-color_click',
    PRICER_PAGE_REMOVE_ROW_COLOR_CLICK:'pricer-page_remove-row-color_click',
    PRICER_PAGE_COPY_SHORTHAND_CLICK:'pricer-page_copy-shorthand_click',
    PRICER_PAGE_COPY_SHORTHAND_NO_VOL_CLICK:'pricer-page_copy-shorthand-no-vol_click',
    PRICER_PAGE_COPY_SHORTHAND_LEGS_CLICK:'pricer-page_copy-shorthand-legs_click',
    PRICER_PAGE_RESET_SORT_CLICK:'pricer-page_reset-sort_click',
    PRICER_PAGE_DUPLICATE_ROW_TRIGGER:'pricer-page_duplicate-row_trigger',
    PRICER_PAGE_DELETE_ROW_TRIGGER:'pricer-page_delete-row_trigger',
    OTHER_INFO_MODAL_OTHER_INFO_AND_DETAILS_VIEW:'other-info-modal_other-info-and-details_view',
    PRICER_PAGE_PUSH_TO_AUDIT_TRAIL_TRIGGER:'pricer-page_push-to-audit-trail_trigger',
    PRICER_PAGE_PUSH_TO_TEAM_RUN_TRIGGER:'pricer-page_push-to-team-run_trigger',
    ADD_OPTION_MODAL_ADD_OPTION_TRIGGER: 'add-option-modal_add-option_trigger',
    PRICER_PAGE_ADD_OPTION_CLICK: 'pricer-page_add-option_click',
    PRICER_PAGE_CALCULATION_DATE_TRIGGER:'pricer-page_calculation-date_trigger',

    // Team run events for google analytics
    TEAM_RUN_PAGE_UN_HIGHLIGHT_TRIGGER :'team-run-page_un-highlight_trigger',
    TEAM_RUN_PAGE_HIGHLIGHT_TRIGGER :'team-run-page_highlight_trigger',
    TEAM_RUN_PAGE_MAKE_IN_ACTIVE_TRIGGER :'team-run-page_make-in-active_trigger',
    TEAM_RUN_PAGE_MAKE_ACTIVE_TRIGGER :'team-run-page_make-active_trigger',
    TEAM_RUN_PAGE_CHANGE_ROW_COLOR_TRIGGER :'team-run-page_change-row-color_trigger',
    TEAM_RUN_PAGE_REMOVE_ROW_COLOR_TRIGGER :'team-run-page_remove-row-color_trigger',
    TEAM_RUN_PAGE_COPY_SHORTHAND_CLICK :'team-run-page_copy-shorthand_click',
    TEAM_RUN_PAGE_COPY_SHORTHAND_NO_VOL_CLICK :'team-run-page_copy-shorthand-no-vol_click',
    TEAM_RUN_PAGE_ARCHIVE_TRIGGER :'team-run-page_archive_trigger',
    TEAM_RUN_PAGE_DETAIL_AUDIT_TRAIL_VIEW :'team-run-page_detail-audit-trail_view',
    TEAM_RUN_PAGE_PULL_TO_PRICER_TRIGGER :'team-run-page_pull-to-pricer_trigger',
    TRADE_OPTION_MODAL_TRADE_VIEW: 'trade-option-modal_trade_view',
    TRADE_OPTION_MODAL_TRADE_OPTION_TRIGGER: 'trade-option-modal_trade-option_trigger',
    UPDATE_ASK_MODAL_UPDATE_ASK_VIEW: 'update-ask-modal_update-ask_view',
    UPDATE_ASK_MODAL_UPDATE_ASK_TRIGGER: 'update-ask-modal_update-ask_trigger',
    UPDATE_BID_MODAL_UPDATE_BID_TRIGGER: 'update-bid-modal_update-bid_trigger',
    UPDATE_BID_MODAL_UPDATE_BID_VIEW: 'update-bid-modal_update-bid_view',
    
    // audit trail
    AUDIT_TRAIL_PAGE_DETAIL_AUDIT_TRAIL_VIEW :'audit-trail-page_detail-audit-trail_view',
    
}