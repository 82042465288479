import { Select } from "antd";
import {
  getMarkedDepthTradingCompanies,
  getTradingCompanies,
} from "api/TeamRun";
import { BACKEND_KEYS_NAME } from "lib/constant/EditTradeDetails/EditTradeDetails";
import { GlobalContext } from "lib/contexts/GlobalContext";
import { useContext, useEffect, useState } from "react";

function CompanyTraders({
  value,
  onChange,
  setTradingCompanyId,
  defaultValue,
  placeholder,
  isPopup = false,
  isBidAndAsk = false,
  isDisabled = false,
  rowID,
  onClear,
  companyTraders,
  isTradingCompaniesLoading,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [tradingCompanies, setTradingCompanies] = useState([]);

  useEffect(() => {
    if (!companyTraders) {
      fetchTradingCompanies();
    }
  }, []);
  const fetchTradingCompanies = async () => {
    setIsLoading(true);
    try {
      const response = isBidAndAsk
        ? await getMarkedDepthTradingCompanies(rowID)
        : await getTradingCompanies();
      setTradingCompanies(response.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  return (
    <Select
      placeholder={placeholder || "Trading Companies"}
      allowClear
      onClear={() => {
        if (!isPopup) {
          setTradingCompanyId(null);
        } else {
          onClear?.();
        }
      }}
      value={isTradingCompaniesLoading || isLoading ? null : value || null}
      onChange={(v) => {
        const label = [...(companyTraders || tradingCompanies)]?.find(
          (item) => item?.id === v
        )?.label;
        if (!isPopup) {
          onChange(BACKEND_KEYS_NAME.COMPANY_TRADER, label);
          setTradingCompanyId(v);
        } else {
          onChange(v);
        }
      }}
      disabled={isLoading || isDisabled || isTradingCompaniesLoading}
      showSearch
      filterOption={(input, option) => {
        const inputSmallLetter = input.toLowerCase();
        const label = option.children.toLowerCase();
        return label.includes(inputSmallLetter);
      }}
      defaultValue={isLoading ? null : defaultValue}
      loading={isLoading || isTradingCompaniesLoading}
      style={{ width: isPopup ? "100%" : 180 }}
      size={isPopup ? "middle" : "middle"}
      className={
        dark_Theme
          ? "select-dark edit-company-trader-input"
          : "edit-company-trader-input"
      }
      popupClassName={dark_Theme ? "dropdown-dark" : ""}
    >
      {[...(companyTraders || tradingCompanies)].map((item) => (
        <Select.Option key={item.id} value={item.id}>
          {item?.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export default CompanyTraders;
