export const BACKEND_KEYS_NAME = {
  SELL: "sell",
  BUY: "buy",
  HEDGE: "hedge",
  TRADER: "trader",
  COMPANY_TRADER: "trading_company",
  EXCHANGE_ID: "exchange_id",
  LEGS: "legs",
  TITLE: "title",
  ID: "id",
  QUANTITY_T: "quantity_t",
  VOLATILITY: "volatility",
  SYMBOL: "symbol",
  IS_DISABLED: "is_disabled",
  NAME: "name",
  SHOW_HEDGE: "show_hedge",
  TRADED_PREMIUM: "traded_premium",
  PREMIUM: "premium",
  IS_SELL: "is_sell",
  SELLE_OR_BUY: "sell_or_buy",
  STEP_SIZE: "step_size",
  DELTA: "delta",
  DELTA_QUANTITY_T: "delta_quantity_t",
  EXCHANGES: "exchanges",
  EXCHANGE: "exchange",
  VALUE: "value",
  RATIO: "ratio",
  LABEL: "label",
  HEDGE_IS_SELL: "hedge_is_sell",
  UNDERLYING: "underlying",
  TEM_SUMMARY_ID: "temp_summary_id",
  TEAM_RUN_OPTION_ID: "team_run_option_id",
};

export const API_KEYS = {
  TRADE_PLACEHOLDER: "trader_placeholder",
  COMPANY_TRADE_PLACEHOLDER: "trading_company_placeholder",
  EXCHANGE_PLACEHOLDER: "exchange_placeholder",
  QUANTIZE_PLACEHOLDER: "quantity_placeholder",
};
