import React from 'react';
// Components
import VolSurfaceState from 'lib/contexts/VolSurfaceContext';
import VolSurfaceContainer from '../VolSurfaceContainer';
import { VolSurfaceControlTableProvider } from 'lib/contexts/VolSurfaceControlTableContext';

const VolSurfaceGraph = () => {
  return (
    <VolSurfaceState>
      <VolSurfaceControlTableProvider>
        <VolSurfaceContainer />
      </VolSurfaceControlTableProvider>
    </VolSurfaceState>
  );
};

export default VolSurfaceGraph;