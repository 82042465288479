import React from 'react'
import { gtag } from 'ga-gtag';

const useGAEvenetsTraker = (category="Event Category") => {
  const trackEvent = (action="action",name="name", value ="label")=>{
    gtag(category, action, { [name]: value });

  }
    return trackEvent;
}

export default useGAEvenetsTraker
