/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from "react";
import _ from "lodash";
import { Button, Form, InputNumber, Modal, Row, Col } from "antd";
import {
  SECOND_PART_OF_FORM,
  DROP_DOWN_VALIDATION_MESSAGE,
  FIELDS_LABEL,
  ADD_OPTION_INITIAL_STATE,
  INPUT_KEYS,
  MODAL_BUTTON_KEYS,
  RADIO_GROUP_BUTTONS,
  BUTTON_NAMES,
  IS_BUY,
  IS_CALL,
} from "lib/constant/AddOption/AddOption";
import { FormItem, RadioGroupButtons } from "./AddOptionHelperComponents";
// Custom Hooks
import usePricerFetchFunctions from "lib/hooks/usePricerFetchFunctions";
// Helpers
import { findGCD, reformatSubmitData } from "lib/helpers/Pricer/helperFunc";
// API
import { submitAddOptionAPI } from "api/Pricer/AddOption";
// Context
import { PricerContext } from "lib/contexts/PricerContext";
// Style
import "./AddOptionModal.scss";
import { GlobalContext } from "lib/contexts/GlobalContext";
import useGAEvenetsTraker from "lib/hooks/useGAEvenetsTraker";
import { GA_EVENTS_NAMES } from "lib/constant/GoogleAnalytics/GoogleAnalytics";
import Tenors from "./Tenors";
import Strikes from "./Strikes";
import Ratio from "./Ratio";
import LiveOption from "./LiveOption";
import UnderlyingOption from "./UnderlyingOption";
import SelectDropdown from "./SelectDropdown";

const { Item } = Form;

const CreateOptionModal = (props) => {
  const { refetchOptionRow, setIsAddOptionSubmitted } =
    useContext(PricerContext);
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const googleEvenTracker = useGAEvenetsTraker("event");

  //#States
  const [addOptionData, setAddOptionData] = useState({
    ...ADD_OPTION_INITIAL_STATE,
    ...SECOND_PART_OF_FORM,
  });
  const [tenorDataSelectedButtons, setTenorDataSelectedButtons] = useState({});
  const [strikeValues, setStrikeValues] = useState({});
  const [ratioValues, setRatioValues] = useState([]);
  const [isCalcRatioVisible, setIsCalcRatioVisible] = useState({
    [RADIO_GROUP_BUTTONS.LCM_BUTTONS]: RADIO_GROUP_BUTTONS["1"],
  });
  const [calcFieldsValues, setCalcFieldsValues] = useState({});
  const [underlyingData, setUnderlyingData] = useState({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLiveChecked, setIsLiveChecked] = useState(false);
  const [isBespokeChecked, setIsBespokeChecked] = useState({});
  //Hooks
  const fetchedData = usePricerFetchFunctions(addOptionData);

  //Constants
  const CONTRACT_STYLE = addOptionData[FIELDS_LABEL.CONTRACT_STYLE.key];
  const TYPE_OPTION = addOptionData[FIELDS_LABEL.TYPE.key];
  const COST_OF_CARRY = addOptionData[FIELDS_LABEL.COST_OF_CARRY.key];
  const AVERAGES = addOptionData[FIELDS_LABEL.AVERAGES.key];
  const CONSTRAINTS = fetchedData[FIELDS_LABEL.CONSTRAINTS.key];
  const UNDERLYING_COUNT = CONSTRAINTS?.[FIELDS_LABEL.UNDERLYING_COUNT.key];
  const UNDERLYING = addOptionData[FIELDS_LABEL.UNDERLYING.key];
  const FETCHED_CONTRACT_STYLE = fetchedData[FIELDS_LABEL.CONTRACT_STYLE.key];
  const COMMODITY = addOptionData[FIELDS_LABEL.COMMODITY.key];

  //When Legs Change It Should Fetch Types
  const onLegsChange = async (key, value) => {
    if (key === FIELDS_LABEL.LEGS.key) {
      setAddOptionData({
        ...addOptionData,
        [FIELDS_LABEL.LEGS.key]: value,
        [FIELDS_LABEL.TYPE.key]: "",
      });
      return;
    }

    setAddOptionData({ ...addOptionData, [key]: value });
  };

  const onSelectionChange = (key, val) => {
    if (key === FIELDS_LABEL.TYPE.key) {
      setAddOptionData({
        ...addOptionData,
        ...SECOND_PART_OF_FORM,
        [key]: val,
      });
      setStrikeValues({});
      setRatioValues({});
      setUnderlyingData({});
      return;
    }
    if (key === FIELDS_LABEL.LEGS.key) {
      setAddOptionData({ ...ADD_OPTION_INITIAL_STATE, ...SECOND_PART_OF_FORM });
      setStrikeValues({});
      setRatioValues({});
      setUnderlyingData({});
      return;
    }

    if (key === FIELDS_LABEL.COMMODITY.key) {
      setAddOptionData({
        ...addOptionData,
        [FIELDS_LABEL.CONTRACT_STYLE.key]: "",
        [key]: val,
      });
      return;
    }

    setAddOptionData({ ...addOptionData, [key]: val });
  };

  const onCalcChange = useCallback(
    (value, key) => {
      setIsCalcRatioVisible({ ...isCalcRatioVisible, [key]: value });
    },
    [isCalcRatioVisible]
  );

  const showCostOfCarryAndAverage = useMemo(() => {
    if (
      !addOptionData?.[FIELDS_LABEL.CONTRACT_STYLE.key] &&
      !addOptionData?.[FIELDS_LABEL.TYPE.key]
    )
      return;
    const typeSelected = CONTRACT_STYLE.toLowerCase();
    if (!typeSelected.includes("apo") || !TYPE_OPTION) return;

    return (
      <>
        <FormItem label={FIELDS_LABEL.COST_OF_CARRY.label}>
          <InputNumber
            onPressEnter={(event) => event.target.blur()}
            className="input-number cost-of-carry"
            min={0}
            value={COST_OF_CARRY}
            onChange={(value) =>
              onLegsChange(FIELDS_LABEL.COST_OF_CARRY.key, value)
            }
          />
        </FormItem>
        <FormItem label={FIELDS_LABEL.AVERAGES.label}>
          <InputNumber
            onPressEnter={(event) => event.target.blur()}
            className="input-number average"
            min={0}
            value={AVERAGES}
            onChange={(value) => onLegsChange(FIELDS_LABEL.AVERAGES.key, value)}
          />
        </FormItem>
      </>
    );
  }, [addOptionData]);

  const onSubmitModal = async () => {
    setIsButtonLoading(true);
    const prepareBody = {
      strikeValues,
      ratioValues,
      ...addOptionData,
      checkedBespoke: isBespokeChecked,
      underlyingData,
      [FIELDS_LABEL.TENOR.key]: tenorDataSelectedButtons,
    };
    const res = reformatSubmitData(prepareBody);
    googleEvenTracker(
      GA_EVENTS_NAMES.ADD_OPTION_MODAL_ADD_OPTION_TRIGGER,
      "addOption",
      "Submit"
    ); // event tracker
    submitAddOptionAPI(res)
      .then((res) => {
        refetchOptionRow();
        props.setIsAddOptionModalVisible(false);
        setTimeout(() => setIsAddOptionSubmitted(true), 1000);
        setIsButtonLoading(false);
      })
      .catch(() => setIsButtonLoading(false));
  };

  const onCancel = useCallback(() => {
    props.setIsAddOptionModalVisible(false);
  }, []);

  const isSubmitDisabled = () => {
    let bespokeCheckForDisable = false;
    Object.keys(isBespokeChecked).length &&
      Object.keys(isBespokeChecked).forEach((bespokeKey) => {
        if (
          isBespokeChecked[bespokeKey] &&
          !tenorDataSelectedButtons[bespokeKey]?.length
        ) {
          bespokeCheckForDisable =
            !tenorDataSelectedButtons[bespokeKey]?.length;
        } else if (!tenorDataSelectedButtons[bespokeKey]) {
          bespokeCheckForDisable = !tenorDataSelectedButtons[bespokeKey];
        }
      });
    if (
      _.isEmpty(strikeValues) ||
      !CONSTRAINTS ||
      _.isEmpty(tenorDataSelectedButtons) ||
      bespokeCheckForDisable
    )
      return true;

    //Strikes And Ratios Validations
    const strikesAPINumber = CONSTRAINTS[FIELDS_LABEL.STRIKE.key]?.length;
    const ratioAPINumber = CONSTRAINTS[FIELDS_LABEL.RATIO.key]?.length;
    const filledStrikes =
      (strikeValues &&
        Object.values(strikeValues).filter(
          (item) =>
            item[INPUT_KEYS.VALUE] !== null && item[INPUT_KEYS.BUTTON] !== null
        )?.length) ||
      0;
    const filledRatio =
      (ratioValues &&
        Object.values(ratioValues).filter(
          (item) =>
            item[INPUT_KEYS.VALUE] !== null && item[INPUT_KEYS.BUTTON] !== null
        )?.length) ||
      0;
    const isUnderlyingFilled =
      UNDERLYING_COUNT === Object.values(underlyingData).filter(Boolean).length;
    let isAvgAndCostOfCarryFilled;

    if (CONTRACT_STYLE.includes("APO")) {
      isAvgAndCostOfCarryFilled = !(
        COST_OF_CARRY !== null && AVERAGES !== null
      );
    } else {
      isAvgAndCostOfCarryFilled = false;
    }
    //End Of Strikes And Ratios Validations
    return (
      filledStrikes !== strikesAPINumber ||
      filledRatio !== ratioAPINumber ||
      !isUnderlyingFilled ||
      isAvgAndCostOfCarryFilled
    );
  };

  const showCalc = () => {
    const showInputNumbers =
      isCalcRatioVisible[RADIO_GROUP_BUTTONS.Q_BUTTON] ===
      RADIO_GROUP_BUTTONS.Q;

    return (
      <Item>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col sm={12} md={8}>
                <RadioGroupButtons
                  defaultValue={RADIO_GROUP_BUTTONS.R}
                  firstButton={RADIO_GROUP_BUTTONS.R}
                  lastButton={RADIO_GROUP_BUTTONS.Q}
                  onChange={(value) =>
                    onCalcChange(value, RADIO_GROUP_BUTTONS.Q_BUTTON)
                  }
                />
              </Col>
              <Col sm={12} md={8}>
                {showInputNumbers && (
                  <RadioGroupButtons
                    firstButton={RADIO_GROUP_BUTTONS["1"]}
                    defaultValue={RADIO_GROUP_BUTTONS["1"]}
                    lastButton={RADIO_GROUP_BUTTONS.LCM}
                    onChange={(value) =>
                      onCalcChange(value, RADIO_GROUP_BUTTONS.LCM_BUTTONS)
                    }
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <div className="horizontal-scroll__container">
              {showInputNumbers &&
                CONSTRAINTS?.[FIELDS_LABEL.STRIKE.key].map((item, index) => (
                  <InputNumber
                    onPressEnter={(event) => event.target.blur()}
                    className="ratio-and-strike__input-number"
                    min={1}
                    value={props.value}
                    disabled={props.isDisabled}
                    onChange={(value) => {
                      setCalcFieldsValues({
                        ...calcFieldsValues,
                        [index]: value,
                      });
                    }}
                  />
                ))}
            </div>
          </Col>
        </Row>
      </Item>
    );
  };

  useEffect(() => {
    setIsBespokeChecked({});
    setTenorDataSelectedButtons({});
  }, [
    addOptionData[FIELDS_LABEL.LEGS.key],
    COMMODITY,
    CONTRACT_STYLE,
    TYPE_OPTION,
  ]);

  //! Calculate Values For Ratio Fields
  useEffect(() => {
    if (
      CONSTRAINTS?.[FIELDS_LABEL.STRIKE.key]?.length !==
      Object.keys(calcFieldsValues).length
    )
      return;
    const isCalcFieldContainNull = Object.values(calcFieldsValues).find(
      (item) => item === null
    );
    if (isCalcFieldContainNull) return;

    const minNumFilled = _.isEmpty(calcFieldsValues)
      ? 1
      : Math.min(...Object.values(calcFieldsValues));

    if (
      isCalcRatioVisible[RADIO_GROUP_BUTTONS.LCM_BUTTONS] ===
      RADIO_GROUP_BUTTONS["1"]
    ) {
      const obj = {};
      Object.values(ratioValues).forEach(
        (item, index) =>
          (obj[index] = {
            value: calcFieldsValues[index] / minNumFilled,
            [INPUT_KEYS.BUTTON]: ratioValues[index][INPUT_KEYS.BUTTON],
          })
      );
      setRatioValues({ ...ratioValues, ...obj });
    }

    if (
      isCalcRatioVisible[RADIO_GROUP_BUTTONS.LCM_BUTTONS] ===
      RADIO_GROUP_BUTTONS.LCM
    ) {
      const calcValues = Object.values(calcFieldsValues);
      const greatestCommonDivisor = findGCD(calcValues);

      const obj = {};
      Object.values(ratioValues).forEach(
        (item, index) =>
          (obj[index] = {
            value: calcFieldsValues[index] / greatestCommonDivisor,
            [INPUT_KEYS.BUTTON]: ratioValues[index][INPUT_KEYS.BUTTON],
          })
      );
      setRatioValues({ ...ratioValues, ...obj });
    }
  }, [
    calcFieldsValues,
    isCalcRatioVisible[RADIO_GROUP_BUTTONS.LCM_BUTTONS],
    isCalcRatioVisible,
  ]);

  //! Effect To Fill Ratio State If It's Already Filled From API
  useEffect(() => {
    const obj = {};
    CONSTRAINTS?.[FIELDS_LABEL.RATIO.key].forEach((item, index) => {
      if (item.value !== null) {
        obj[index] = {
          [INPUT_KEYS.VALUE]: item[INPUT_KEYS.VALUE],
          [INPUT_KEYS.BUTTON]: item[IS_BUY]
            ? BUTTON_NAMES.BUY
            : BUTTON_NAMES.SELL,
          [IS_BUY]: item[IS_BUY],
        };
      }
    });

    if (!Object.values(obj)?.length) return;

    setRatioValues({ ...ratioValues, ...obj });
  }, [CONSTRAINTS?.[FIELDS_LABEL.RATIO.key]]);

  //! Effect To Fill Live State If It's Already Filled From API
  useEffect(() => {
    const isChecked = CONSTRAINTS?.live;
    setIsLiveChecked(() => isChecked);
    onLegsChange(FIELDS_LABEL.LIVE.key, isChecked ? 1 : 0);
  }, [CONSTRAINTS?.live]);

  //! Effect To Fill Strike State If It's Already Filled From API
  useEffect(() => {
    const obj = {};
    CONSTRAINTS?.[FIELDS_LABEL.STRIKE.key].forEach((item, index) => {
      if (item.value !== null) {
        obj[index] = {
          [INPUT_KEYS.VALUE]: item[INPUT_KEYS.VALUE],
          [INPUT_KEYS.BUTTON]: item[IS_CALL]
            ? BUTTON_NAMES.CALL
            : BUTTON_NAMES.PUT,
          [IS_CALL]: item[IS_CALL],
        };
      }
    });

    if (!Object.values(obj)?.length) return;

    setStrikeValues({ ...strikeValues, ...obj });
  }, [CONSTRAINTS?.[FIELDS_LABEL.STRIKE.key]]);

  //! Effect To Add Default Value For Contract Style If It Return Only One Element In Array
  useEffect(() => {
    if (fetchedData?.[FIELDS_LABEL.CONTRACT_STYLE.key]?.length !== 1) return;

    onSelectionChange(
      FIELDS_LABEL.CONTRACT_STYLE.key,
      FETCHED_CONTRACT_STYLE[0].value
    );
  }, [FETCHED_CONTRACT_STYLE?.length]);

  useEffect(() => {
    const isRatioDataExists = CONSTRAINTS?.[FIELDS_LABEL.RATIO.key];
    if (!isRatioDataExists) return;

    const formattedData = isRatioDataExists.map((item) => ({
      button: item["is_buy"] ? BUTTON_NAMES.BUY : BUTTON_NAMES.SELL,
      is_buy: item["is_buy"],
      value: item["value"],
    }));

    setRatioValues(formattedData);
  }, [CONSTRAINTS?.[FIELDS_LABEL.RATIO.key]]);

  //? Reset Add Option Button State
  useEffect(() => {
    setIsAddOptionSubmitted(false);
  }, []);

  return (
    <Modal
      className={`add-option-form__container ${dark_Theme && "ant-modal-dark"}`}
      open={props.isAddOptionModalVisible}
      onOk={onSubmitModal}
      onCancel={() => props.setIsAddOptionModalVisible(false)}
      footer={[
        <Button key="back" onClick={onCancel}>
          {MODAL_BUTTON_KEYS.CANCEL}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isButtonLoading}
          onClick={onSubmitModal}
          disabled={isSubmitDisabled()}
        >
          {MODAL_BUTTON_KEYS.SUBMIT}
        </Button>,
      ]}
      width={"90vw"}
      getContainer={"#content-root"}
    >
      <Form layout="horizontal">
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <FormItem label={FIELDS_LABEL.LEGS.label}>
              <InputNumber
                onPressEnter={(event) => event.target.blur()}
                min={1}
                onChange={(value) => onLegsChange(FIELDS_LABEL.LEGS.key, value)}
              />
            </FormItem>
          </Col>
          {/*Commodity DropDown*/}
          <SelectDropdown
            options={fetchedData[FIELDS_LABEL.COMMODITY.key]}
            placeholder={FIELDS_LABEL.COMMODITY.PLACEHOLDER}
            onChange={(val) => {
              onSelectionChange(FIELDS_LABEL.COMMODITY.key, val);
            }}
            value={!COMMODITY ? undefined : COMMODITY}
            label={FIELDS_LABEL.COMMODITY.label}
            isLoading={fetchedData.commodityLoading}
          />
          {/* End Of Commodity DropDown */}

          {/* Contract Style DropDown */}
          <SelectDropdown
            isLoading={fetchedData.contractStyleLoading}
            options={FETCHED_CONTRACT_STYLE}
            placeholder={FIELDS_LABEL.CONTRACT_STYLE.PLACEHOLDER}
            onChange={(val) => {
              onSelectionChange(FIELDS_LABEL.CONTRACT_STYLE.key, val);
            }}
            value={!CONTRACT_STYLE ? undefined : CONTRACT_STYLE}
            label={FIELDS_LABEL.CONTRACT_STYLE.label}
          />
          {/* End Of Contract Style DropDown */}

          {/* Type DropDown */}
          <SelectDropdown
            isLoading={fetchedData.typeLoading}
            options={fetchedData[FIELDS_LABEL.TYPE.key]}
            placeholder={FIELDS_LABEL.TYPE.PLACEHOLDER}
            onChange={(val) => {
              onSelectionChange(FIELDS_LABEL.TYPE.key, val);
            }}
            value={!TYPE_OPTION ? undefined : TYPE_OPTION}
            notFoundContent={
              !fetchedData[FIELDS_LABEL.LEGS.key] && (
                <span className="required-validation">
                  {DROP_DOWN_VALIDATION_MESSAGE}
                </span>
              )
            }
            label={FIELDS_LABEL.TYPE.label}
          />

          {/* End Of Type DropDown */}

          {/*Show Cost Of Carry And Average*/}
          <Col span={24}>{showCostOfCarryAndAverage}</Col>
          {/*End Of Show Cost Of Carry And Average*/}
          {CONTRACT_STYLE && TYPE_OPTION && CONSTRAINTS && (
            <UnderlyingOption
              UNDERLYING={UNDERLYING}
              UNDERLYING_COUNT={UNDERLYING_COUNT}
              setUnderlyingData={setUnderlyingData}
              underlyingData={underlyingData}
            />
          )}

          {/*Tenor And Live*/}
          {CONTRACT_STYLE && TYPE_OPTION && CONSTRAINTS && (
            <>
              <Tenors
                fetchedData={fetchedData}
                isBespokeChecked={isBespokeChecked}
                setIsBespokeChecked={setIsBespokeChecked}
                setTenorDataSelectedButtons={setTenorDataSelectedButtons}
                tenorDataSelectedButtons={tenorDataSelectedButtons}
              />

              <LiveOption
                fetchedData={fetchedData}
                onLegsChange={onLegsChange}
                isLiveChecked={isLiveChecked}
                setIsLiveChecked={setIsLiveChecked}
              />
            </>
          )}

          {/*Show Ratio And Strikes*/}
          {TYPE_OPTION && CONSTRAINTS && (
            <>
              <Strikes
                fetchedData={fetchedData}
                setStrikeValues={setStrikeValues}
                strikeValues={strikeValues}
              />

              <Ratio
                fetchedData={fetchedData}
                isCalcRatioVisible={isCalcRatioVisible}
                ratioValues={ratioValues}
                setRatioValues={setRatioValues}
              />

              {/* Show Calc Section  */}
              <Col span={24}>{CONSTRAINTS["show_calc"] && showCalc()}</Col>
              {/* End Of Show Calc Section  */}
            </>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateOptionModal;
