import { Spin, Space } from "antd";
// Component Style
import "./PreLoadPagesSpin.scss";

const PreLoadPagesSpin = ({ dark_Theme, className }) => {
  // const { globalState:{dark_Theme}} = useContext(GlobalContext);

  return (
    <div
      data-testid="pre-loading-page"
      className={`${
        dark_Theme || false ? "PreLoadPagesSpin_dark" : ""
      } PreLoadPagesSpin ${className}`}
    >
      <Space size="middle" align="center">
        <Spin size="large" />
      </Space>
    </div>
  );
};

export default PreLoadPagesSpin;
