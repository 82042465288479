import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";

// List of initial state for all global data
export const initialState = {
  teamRunData: {},
  isTeamRunLoading: false,
  productIds: null,
  selectedRows: null,
  teamrunRefAgGrid: null,
};

// Actions
const SET_TEAM_RUN_DATA = "SET_TEAM_RUN_DATA";
const IS_TEAM_RUN_LOADING = "IS_TEAM_RUN_LOADING";
const SET_PRODUCT_IDS = "SET_PRODUCT_IDS";
const SET_SELECTED_ROWS = "SET_SELECTED_ROWS";
const SET_TEAMRUN_REF_AGGRID = "SET_TEAMRUN_REF_AGGRID";

// Shared Reducer For Global Context
const Reducer = (state, action) => {
  switch (action.type) {
    case IS_TEAM_RUN_LOADING:
      return {
        ...state,
        isTeamRunLoading: true,
      };
    case SET_TEAM_RUN_DATA:
      return {
        ...state,
        teamRunData: action.payload,
      };
    case SET_PRODUCT_IDS:
      return {
        ...state,
        productIds: action.payload,
      };
    case SET_TEAMRUN_REF_AGGRID:
      return {
        ...state,
        teamrunRefAgGrid: action.payload,
      };
    case SET_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: action.payload,
      };

    default:
      return state;
  }
};

// Global State Which its provide context for children
const TraderState = ({ children, defaultInitialState = {} }) => {
  const [state, dispatch] = useReducer(Reducer, {
    ...initialState,
    ...defaultInitialState,
  });

  const setTeamRunData = useCallback((value) => {
    dispatch({ type: SET_TEAM_RUN_DATA, payload: value });
  }, []);

  const setIsTeamRunLoading = useCallback((value) => {
    dispatch({ type: IS_TEAM_RUN_LOADING, payload: value });
  }, []);

  const setProductIds = useCallback((value) => {
    dispatch({ type: SET_PRODUCT_IDS, payload: value });
  }, []);

  const setTeamrunRefAgGrid = useCallback((value) => {
    dispatch({ type: SET_TEAMRUN_REF_AGGRID, payload: value });
  }, []);

  const setSelectedRows = useCallback((value) => {
    dispatch({ type: SET_SELECTED_ROWS, payload: value });
  }, []);

  const contextValue = useMemo(() => {
    return {
      state,
      setTeamRunData,
      setProductIds,
      setIsTeamRunLoading,
      setSelectedRows,
      setTeamrunRefAgGrid,
    };
  }, [state]);

  return (
    <TraderContext.Provider value={contextValue}>
      {children}
    </TraderContext.Provider>
  );
};

// Create Global Context
export const TraderContext = createContext(initialState);

// Export Global State Context Component
export default TraderState;
export function useWebSocketContext() {
  const context = useContext(TraderContext);
  if (context === undefined) {
    throw new Error("useTestContext must be used within TestProvider");
  }
  return context;
}
