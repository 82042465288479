import { Button, Col, Row } from "antd";
import { sendEmailForVolSurface } from "api/VolSurface/VolSurface";
import { toast } from "lib/helpers/utilities";
import { useState } from "react";

export default function SendEmail({ dark_Theme }) {
  const [isExportLoading, setIsExportLoading] = useState(false);

  const handleSendEmail = () => {
    setIsExportLoading(true);
    sendEmailForVolSurface()
      .then((res) => {
        if (res.status === 200) {
          toast(res?.data?.message, "success");
          handleDownloadVolSurfaceData(res.data.data.url);
        } else {
          toast(res?.data?.message, "info");
        }
        setIsExportLoading(false);
      })
      .catch((err) => {
        setIsExportLoading(false);
        console.log("Error >>", err);
      });
  };

  const handleDownloadVolSurfaceData = (url) => {
    const a = document.createElement("a");
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setIsExportLoading(false);
  };
  
  return (
    <Col span={24}>
      <Row className="bias-btn">
        <Col span={3}>Send Email:</Col>
        <Col>
          <Button
            className={dark_Theme ? "graph-btn" : ""}
            onClick={handleSendEmail}
            loading={isExportLoading}
          >
            Send
          </Button>
        </Col>
      </Row>
    </Col>
  );
}
