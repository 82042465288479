import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react";
import { Input, Modal, Row, Col, Divider, Tooltip } from "antd";
// Components
import InputWithLabel from "components/InputWithLabel";
// Style
import "./AdditionalData.scss";
import { fetchAdditionalData } from "api/AuditTrail/AuditTrail";
import { GlobalContext } from "lib/contexts/GlobalContext";
import { ACTION_BUTTONS_NAME } from "lib/constant/GeneralActionButtons";
import PreLoadPagesSpin from "components/PreLoadPagesSpin";

const VALUE = "Value";
const ADDITIONAL_DATA = "Additional Data";
const EXIT = "Exit";

const LegsData = ({ data }) => {
  return (
    <Row
      className="cell-value"
      justify="space-between"
      wrap={false}
      gutter={[10, 5]}
    >
      <Col style={{ minWidth: 60, whiteSpace: "nowrap" }} span={1}>
        {data.name}
      </Col>
      {data.fields.map((item) => (
        <Tooltip placement="top" title={item.value} color="gray">
          <Col span={1} style={{ minWidth: 140 }}>
            <Input disabled value={item.value} suffix={item.symbol} />
          </Col>
        </Tooltip>
      ))}
    </Row>
  );
};

const AdditionalDataModal = ({
  isOpen = false,
  handleMenuItemClicked = () => {},
  rowSelectedInfo = "",
}) => {
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  console.log("rowSelectedInfo");
  const preventTriggeringTwice = useRef(true);
  const [measurementData, setMeasurementData] = useState("");
  const [legData, setLegsData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [columnTitle, setColumnTitle] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetchAdditionalData(rowSelectedInfo)
      .then((res) => {
        const { legs, columns, bottom_values } = res.data.data;
        prepareLegsData(legs);
        setMeasurementData(bottom_values);
        setColumnTitle(columns.map((item) => item.title));
        setIsLoading(false);
      })
      .catch((err) => console.log({ err }))
      .finally(() => setIsLoading(false));
  }, [rowSelectedInfo?.summary_id]);

  const prepareLegsData = useCallback((legData) => {
    const formattedLegsData = [];
    legData.forEach((item, idx) => {
      formattedLegsData.push({
        ...item,
        name: `Legs ${idx + 1}`,
        index: idx,
      });

      item["sublegs"].forEach((nestedItem, nestedIndex) => {
        formattedLegsData.push({
          ...nestedItem,
          name: `SLegs ${nestedIndex + 1}`,
          parentIndex: idx,
        });
      });
    });

    setLegsData(formattedLegsData);
  }, []);
  //End Of Prepare Data Func

  //Show Data As JSX For Measurement Section And Legs Data
  const showMeasurementSection = useMemo(() => {
    if (!measurementData) return;

    return measurementData.map((item, index) => {
      return (
        <React.Fragment key={index}>
          <InputWithLabel
            width={
              item.label === "Shorthand" ? item.value.length * 8 + 70 : null
            }
            label={item.label}
            value={item?.value}
            isDisabled={true}
            measureUnitValue={item.symbol}
            additionalData={true}
          />
        </React.Fragment>
      );
    });
  }, [measurementData]);

  const showLegsData = useMemo(() => {
    if (legData.length === 0) return;
    return legData.map((item, index) => <LegsData data={item} key={index} />);
  }, [legData]);

  const showHeaderColumnForLegs = useMemo(() => {
    if (!columnTitle) return;
    return columnTitle.map((item, index) => {
      return (
        <Col
          style={{ minWidth: 140, textAlign: "start" }}
          span={1}
          key={`${index}-${item}`}
        >
          {item}
        </Col>
      );
    });
  }, [columnTitle]);

  return (
    <Modal
      key="additional-data"
      className={`additional-data__container ${
        dark_Theme && "ant-modal-dark content_dark"
      }`}
      title={ADDITIONAL_DATA}
      open={isOpen}
      cancelText={EXIT}
      onCancel={() =>
        handleMenuItemClicked(ACTION_BUTTONS_NAME.ADDITIONAL_DATA.key, false)
      }
      getContainer={".audit-trail__container"}
    >
      {isLoading ? (
        <PreLoadPagesSpin
          className={"additional-data__container__loading"}
          dark_Theme={dark_Theme}
        />
      ) : (
        <>
          <div className="additional-data__container__legs">
            <Row justify="space-between" className="header-name" wrap={false}>
              <Col style={{ minWidth: 60 }} span={1}></Col>
              {columnTitle && showHeaderColumnForLegs}
            </Row>
            {showLegsData}
          </div>
          <Divider className="divider"></Divider>
          <div className="additional-data__container__measurements">
            <span className="value-label">{VALUE}</span>
            {showMeasurementSection}
          </div>
        </>
      )}
    </Modal>
  );
};

export default AdditionalDataModal;
