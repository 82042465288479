import React from "react";
import { Col, Row } from "antd";
import VolSurfaceOptionsGraph from "./VolSurfaceOptionsGraph";

export default function GraphsContainer({
  volSurfaceData,
  rowsData,
  Highcharts,
  tableHeight,
  scrollRef,
}) {
  return (
    <Col
      className="graphs-container"
      ref={scrollRef}
      style={{
        margin: "0px",
        maxHeight: tableHeight,
        overflowY: "auto",
        padding: "0px",
      }}
    >
      {volSurfaceData &&
        rowsData &&
        volSurfaceData.map((volSurf, index) => {
          return (
            <Col span={24} key={volSurf.block_id}>
              <Row gutter={[24, 4]}>
                <Col span={24} >
                  <Row gutter={[5,0]}>
                    {volSurf && volSurf.block_graphs?.length > 0
                      ? volSurf.block_graphs?.map((volSurfaceOptions) => {
                          return (
                            <VolSurfaceOptionsGraph
                              key={JSON.stringify(volSurfaceOptions)}
                              volSurfaceOptions={volSurfaceOptions}
                              Highcharts={Highcharts}
                            />
                          );
                        })
                      : null}
                  </Row>
                </Col>
              </Row>
            </Col>
          );
        })}
    </Col>
  );
}
