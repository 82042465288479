import  { useContext, useEffect, useState } from "react";
import _ from 'lodash';
import { useQuery } from "@tanstack/react-query";
import axios from "api/axios";
import { TeamRunContext } from "lib/contexts/TeamRunContext";

export const prepareBody = (response) => {
  if(_.isEmpty(response)) return;
  let sortedBody = {};
  Object.keys(response).forEach(item => {
    const { title, product_id, rows } =response[item];
    sortedBody = {
      ...sortedBody,
      [title] : rows
    }
  });
  return sortedBody;
};
const useTeamRun = () => {
  const { setTeamRunData,  setProductIds } = useContext(TeamRunContext);
    const [widths, setWidths] = useState([]);


  // APIS FUNC
  useQuery(
    ['products'],
    async () => await axios.get('team-run/products').then(res => res?.data?.data).catch(err => console.error({ err })),
    {
      onSuccess: (product) => setProductIds(product),
      retryOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: true
    });


  const {isFetching} = useQuery(
    ['index-test'],
    async () => await axios.get('team-run').then(res => res?.data?.data).catch(err => console.error({ err })),
    {
      retryOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: true,
      onSuccess: (successItem) => {
        const res = prepareBody(successItem);
        setTeamRunData(res);
          const storedWidths = localStorage.getItem("team-run-table-widths");
          const initialWidths = storedWidths
            ? JSON.parse(storedWidths)
            : successItem !== null && successItem?.map((component) => ({
                title: component.title,
                width: "35vw", // Default width
              }));
          setWidths(initialWidths);
      }
    });

  useEffect(() => {
    // Only save widths to local storage if they are not empty
    if (widths.length > 0) {
      localStorage.setItem("team-run-table-widths", JSON.stringify(widths));
    }
  }, [widths]);
  return {isFetching, widths, setWidths};
};

export default useTeamRun;
