export const FIELDS_LABEL = {
  LEGS               : {label : "# of Legs",          key : "legs",              PLACEHOLDER : ""                           },
  COMMODITY          : {label : "Commodity",          key : "commodity",         PLACEHOLDER : "Select a Commodity"         },
  CONTRACT_STYLE     : {label : "Contract - Style",   key : "contractStyle",     PLACEHOLDER : "Select a Contract - Style"  },
  TYPE               : {label : "Type",               key : "type",              PLACEHOLDER : "Select a Type"              },
  UNDERLYING         : {label : "Underlying",         key : "underlying",        PLACEHOLDER : ""                           },
  LIVE               : {label : "Live",               key : "live",              PLACEHOLDER : ""                           },
  AVERAGES           : {label : "Average",            key : "average",           PLACEHOLDER : ""                           },
  COST_OF_CARRY      : {label : "Cost Of Carry",      key : "costOfCarry",       PLACEHOLDER : ""                           },
  STRIKE             : {label : "Strike",             key : "strikes",           PLACEHOLDER : ""                           },
  RATIO              : {label : "Ratio",              key : "ratios",            PLACEHOLDER : ""                           },
  TENOR              : {label : "Tenor",              key : "tenors",            PLACEHOLDER : ""                           },
  BESPOKE            : {label : "Bespoke",            key : "bespoke",           PLACEHOLDER : ""                           },
  BESPOKE_TENOR      : {label : "Bespoke Tenor",      key : "bespoke_tenors",    PLACEHOLDER : ""                           },
  CONSTRAINTS        : {label : "Constraints",        key : "constraints",       PLACEHOLDER : ""                           },
  UNDERLYING_COUNT   : {label : "Underlying",         key : "underlying_count",  PLACEHOLDER : ""                           }
};

export const DROP_DOWN_VALIDATION_MESSAGE = 'Please fill # of legs';

export const BUTTON_NAMES = {
  BUY  : "Buy",
  SELL : "Sell",
  PUT  : "Put",
  CALL : "Call"
};

export const ADD_OPTION_INITIAL_STATE = {
  [FIELDS_LABEL.LEGS.key]            : "",
  [FIELDS_LABEL.COMMODITY.key]       : "",
  [FIELDS_LABEL.CONTRACT_STYLE.key]  : "",
  [FIELDS_LABEL.TYPE.key]            : "",
};

export const SECOND_PART_OF_FORM = {
  [FIELDS_LABEL.UNDERLYING.key]     : "",
  [FIELDS_LABEL.LIVE.key]           : "",
  [FIELDS_LABEL.AVERAGES.key]       : 0,
  [FIELDS_LABEL.COST_OF_CARRY.key]  : 0
};

export const INPUT_KEYS = {
  VALUE  : "value",
  BUTTON : "button"
};

export const MODAL_BUTTON_KEYS = {
  CANCEL : "Cancel",
  SUBMIT : "Submit"
};


export const RADIO_GROUP_BUTTONS = {
  R   : "R",
  Q   : "Q",
  1   : "1",
  LCM : "LCM",
  LCM_BUTTONS : "LCM_BUTTONS",
  Q_BUTTON : "Q_BUTTONS",
};

export const IS_BUY = "is_buy";
export const IS_CALL = "is_call";
