import _ from "lodash";
import { getDataFromLocalStorage } from "../GeneralFuncHelpers";
import { TRADER_SELECTED_ROWS, TrADER_STATE } from "lib/constant/Trader/Trader";

export const TRADER_DATAfun = () => getDataFromLocalStorage(TrADER_STATE)



export const setSelectedRowsForTrader=(gridRef)=>{
    const teamRunDataFromLocalStorage = TRADER_DATAfun();
    if(_.isEmpty(teamRunDataFromLocalStorage)|| !teamRunDataFromLocalStorage[TRADER_SELECTED_ROWS]) return;
    if( Object.keys(teamRunDataFromLocalStorage[TRADER_SELECTED_ROWS]).length>0){
      Object.keys(teamRunDataFromLocalStorage[TRADER_SELECTED_ROWS]).forEach((product)=>{
        if(teamRunDataFromLocalStorage[TRADER_SELECTED_ROWS][product].length>0){
          teamRunDataFromLocalStorage[TRADER_SELECTED_ROWS][product].forEach((summaryId)=>{
            gridRef.current[product].api && gridRef.current[product].api.forEachNode(node=> {
              if(node.data.summary_id === summaryId) {
                  node.setSelected(true);
                }
            });
          })
        }
      })
    }
  }


  