/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from "react";
import _ from "lodash";
import { useNavigate, generatePath } from "react-router-dom";
import { Dropdown, Menu, Space } from "antd";
import { ROUTES_PATH } from "routes";
// Icons
import {
  MenuOutlined,
  CopyOutlined,
  ImportOutlined,
  VerticalAlignBottomOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
// Helpers
import { handleCopyShortHand } from "lib/helpers/AuditTrail/generalHelper";

import { prepareBody } from "lib/hooks/useTeamRunReadonly";
// API
import {
  getArchive,
  getMultipleArchives,
  handlePullToPricerClickTeamRun,
  highLightAPI,
  makeActiveInActiveAPI,
  setColorForRow,
  triggerSingleAPI,
} from "api/TeamRun";
// Context
import { TeamRunReadonlyContext } from "lib/contexts/TeamRunContextReadonly";
// Style
import { GlobalContext } from "lib/contexts/GlobalContext";
import classnames from "classnames";
import {
  extractCopyShortHandForSummaryRows,
  status200,
  updateTeamRunData,
} from "lib/helpers/GeneralFuncHelpers";
import { toast } from "lib/helpers/utilities";
import { ACTION_BUTTONS_NAME } from "lib/constant/GeneralActionButtons";
import useGAEvenetsTraker from "lib/hooks/useGAEvenetsTraker";
import { GA_EVENTS_NAMES } from "lib/constant/GoogleAnalytics/GoogleAnalytics";
import UpdateBid from "./UpdateBid";
import {
  ACTION_COLUMN_MENU_ITEMS,
  getAllMenuItemsPossibility,
  TEAM_RUN_READONLY_COLORS_STATE,
} from "lib/constant/TeamRunReadonly/TeamRunReadonly";
import { TEAMRUN_DATAfun } from "lib/helpers/TeamRunReadonly/utils";

const ActionColumn = (props) => {
  const authorizedActions = [
    ACTION_BUTTONS_NAME.COPY_SHORTHAND.key,
    ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key,
    ACTION_BUTTONS_NAME.ADDITIONAL_DATA.key,
    
  ];

  const navigate = useNavigate();
  const {
    state: {
      selectedRows,
      teamRunData,

      teamrunRefAgGrid,
    },
    setTeamRunData,
    setTradePopupData,
    setBidAskPopupData,
    setAdditionalPopupData,
  } = useContext(TeamRunReadonlyContext);
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const googleEvenTracker = useGAEvenetsTraker("event");

  const [menuItemsClicked, setMenuItemsClicked] = useState("");
  const [isUpdateOptionClicked, setIsUpdateOptionClicked] = useState(false);
  const [isPushOptionClicked, setIsPushOptionClicked] = useState(false);
  const [menuItemsData, setMenuItemsData] = useState(ACTION_COLUMN_MENU_ITEMS);
  const [rowData, setRowData] = useState({});
  const [isUpdateBidOrAskActive, setIsUpdateBidOrAskActive] = useState("");

  const colorArray = TEAMRUN_DATAfun()?.[TEAM_RUN_READONLY_COLORS_STATE];

  useEffect(() => {
    if (_.isEmpty(props.data)) return;

    setRowData(props.data);
  }, [props.data]);

  const selectedRowsLength = useMemo(
    () =>
      props.data &&
      selectedRows &&
      selectedRows[props.data.product_title]?.length > 1,
    [props.data, selectedRows, teamRunData]
  );

  useEffect(() => {
    let activeInactive = [
      {
        key: ACTION_BUTTONS_NAME.MAKE_IN_ACTIVE.key,
        label: ACTION_BUTTONS_NAME.MAKE_IN_ACTIVE.label,
        icon: <MinusCircleOutlined />,
        index: 6,
      },
      {
        key: ACTION_BUTTONS_NAME.MAKE_ACTIVE.key,
        label: ACTION_BUTTONS_NAME.MAKE_ACTIVE.label,
        icon: <PlusCircleOutlined />,
        index: 6,
      },
    ];

    setMenuItemsData(
      selectedRowsLength
        ? [
            ...returnSelectedDropDown().filter(
              (item) =>
                item.key === "archive" ||
                item.key === "copy_no_vol" ||
                item.key === "copy_shorthand" ||
                item.key === ACTION_BUTTONS_NAME.COLOR
            ),
            ...activeInactive,
          ]
        : menuItemsData
    );
  }, [selectedRowsLength, props.data, teamRunData]);

  useEffect(() => {
    if (teamRunData) {
      let updatedRowData = teamRunData[props.data.product_title]?.find(
        (row) => row.id === props.data.id
      );
      setRowData(updatedRowData);
    }
  }, [teamRunData, props.data]);

  const dynamicList = useMemo(() => {
    const {
      HIGH_LIGHT_ACTIVE,
      HIGH_LIGHT_IN_ACTIVE,
      UN_HIGH_LIGHT_IN_ACTIVE,
      UN_HIGH_LIGHT_ACTIVE,
    } = getAllMenuItemsPossibility();

    return {
      HIGH_LIGHT_ACTIVE,
      HIGH_LIGHT_IN_ACTIVE,
      UN_HIGH_LIGHT_IN_ACTIVE,
      UN_HIGH_LIGHT_ACTIVE,
    };
  }, [props.data]);

  const triggerSingleAPIFunc = (productId) => {
    triggerSingleAPI(productId)
      .then(({ data }) => {
        const formattedData = prepareBody(data);
        setTeamRunData(formattedData);
      })
      .catch((err) => console.log({ err }));
  };

  const setDataForBidAndAskPopup = (itemClicked) => {
    let updatedTeamRunData =
      teamRunData[props.data.product_title] &&
      teamRunData[props.data.product_title].find(
        (item) => item.id === props.data.id
      );
    let data = {
      isOpen: itemClicked,
      isUpdateBidModalVisible: true,
      keyItem: itemClicked,
      selectedModal: itemClicked,
      triggerSingleAPIFunc: triggerSingleAPIFunc,
      data: updatedTeamRunData,
    };
    setBidAskPopupData(data);
    setIsUpdateBidOrAskActive(itemClicked);
  };

  const returnSelectedDropDown = () => {
    if (rowData) {
      if (rowData["is_active"] && rowData["is_highlighted"]) {
        return dynamicList.UN_HIGH_LIGHT_IN_ACTIVE;
      }

      if (!rowData["is_active"] && !rowData["is_highlighted"]) {
        return dynamicList.HIGH_LIGHT_ACTIVE;
      }

      if (!rowData["is_active"] && rowData["is_highlighted"]) {
        return dynamicList.UN_HIGH_LIGHT_ACTIVE;
      }

      if (rowData["is_active"] && !rowData["is_highlighted"]) {
        return dynamicList.HIGH_LIGHT_IN_ACTIVE;
      }
      return menuItemsData;
    }
    return [];
  };

  const handleResponse = (res, itemClicked) => {
    if (status200(res)) {
      if (teamrunRefAgGrid[rowData.product_title]) {
        setRowData(res.data.data[0]);
        let updatedData = updateTeamRunData(
          rowData.product_title,
          res.data.data,
          teamrunRefAgGrid
        );
        setTeamRunData({
          ...teamRunData,
          [rowData.product_title]: updatedData,
        });
        if (itemClicked === ACTION_BUTTONS_NAME.UN_HIGHLIGHT.key) {
          !!rowData["is_active"]
            ? setMenuItemsData(dynamicList.HIGH_LIGHT_IN_ACTIVE)
            : setMenuItemsData(dynamicList.HIGH_LIGHT_ACTIVE);
        } else if (itemClicked === ACTION_BUTTONS_NAME.HIGHLIGHT.key) {
          !!rowData["is_active"]
            ? setMenuItemsData(dynamicList.UN_HIGH_LIGHT_IN_ACTIVE)
            : setMenuItemsData(dynamicList.UN_HIGH_LIGHT_ACTIVE);
        } else if (itemClicked === ACTION_BUTTONS_NAME.MAKE_IN_ACTIVE.key) {
          !!rowData["is_highlighted"]
            ? setMenuItemsData(dynamicList.UN_HIGH_LIGHT_ACTIVE)
            : setMenuItemsData(dynamicList.HIGH_LIGHT_ACTIVE);
        } else if (itemClicked === ACTION_BUTTONS_NAME.MAKE_ACTIVE.key) {
          !!rowData["is_highlighted"]
            ? setMenuItemsData(dynamicList.UN_HIGH_LIGHT_IN_ACTIVE)
            : setMenuItemsData(dynamicList.HIGH_LIGHT_IN_ACTIVE);
        }
      }
    } else {
      triggerSingleAPIFunc(rowData.product_id);
    }
  };

  const handleMenuItemClickPartTwo = async (itemClicked) => {
    if (itemClicked === ACTION_BUTTONS_NAME.UN_HIGHLIGHT.key) {
      highLightAPI(rowData.summary_id)
        .then((res) => {
          googleEvenTracker(
            GA_EVENTS_NAMES.TEAM_RUN_PAGE_UN_HIGHLIGHT_TRIGGER,
            "Team Run"
          );
          handleResponse(res, itemClicked);
        })
        .catch((err) => {
          console.log("Error ", err);
          triggerSingleAPIFunc(rowData.product_id);
        });
    }

    if (itemClicked === ACTION_BUTTONS_NAME.HIGHLIGHT.key) {
      highLightAPI(rowData.summary_id)
        .then((res) => {
          googleEvenTracker(
            GA_EVENTS_NAMES.TEAM_RUN_PAGE_HIGHLIGHT_TRIGGER,
            "Team Run"
          );
          handleResponse(res, itemClicked);
        })
        .catch((err) => {
          console.log("Error ", err);
          triggerSingleAPIFunc(rowData.product_id);
        });
    }

    if (itemClicked === ACTION_BUTTONS_NAME.MAKE_IN_ACTIVE.key) {
      makeActiveInActiveAPI(selectedRows[rowData.product_title], 0)
        .then((res) => {
          googleEvenTracker(
            GA_EVENTS_NAMES.TEAM_RUN_PAGE_MAKE_IN_ACTIVE_TRIGGER,
            "Team Run"
          );
          handleResponse(res, itemClicked);
        })
        .catch((err) => {
          console.log("Error ", err);
          triggerSingleAPIFunc(rowData.product_id);
        });
    }

    if (itemClicked === ACTION_BUTTONS_NAME.MAKE_ACTIVE.key) {
      // console.log({rowData})
      // console.log(selectedRows[rowData.product_title]);
      makeActiveInActiveAPI(selectedRows[rowData.product_title], 1)
        .then((res) => {
          googleEvenTracker(
            GA_EVENTS_NAMES.TEAM_RUN_PAGE_MAKE_ACTIVE_TRIGGER,
            "Team Run"
          );
          handleResponse(res, itemClicked);
        })
        .catch((err) => {
          console.log("Error ", err);
          triggerSingleAPIFunc(rowData.product_id);
        });
    }

    if (itemClicked.includes("#")) {
      const filterColor = colorArray.find(
        (color) => color.hex_code === itemClicked
      );
      setColorForRow({
        summary_ids: selectedRows[rowData.product_title],
        color_id: filterColor.id,
      })
        .then((res) => {
          googleEvenTracker(
            GA_EVENTS_NAMES.TEAM_RUN_PAGE_CHANGE_ROW_COLOR_TRIGGER,
            "Team Run"
          );
          handleResponse(res, itemClicked);
        })
        .catch((err) => {
          console.log("Error ", err);
          triggerSingleAPIFunc(rowData.product_id);
        });
    }
    if (itemClicked === ACTION_BUTTONS_NAME.REMOVE_COLOR.key) {
      setColorForRow({
        summary_ids: selectedRows[rowData.product_title],
        is_remove: 1,
      })
        .then((res) => {
          googleEvenTracker(
            GA_EVENTS_NAMES.TEAM_RUN_PAGE_REMOVE_ROW_COLOR_TRIGGER,
            "Team Run"
          );
          handleResponse(res, itemClicked);
        })
        .catch((err) => {
          console.log("Error ", err);
          triggerSingleAPIFunc(rowData.product_id);
        });
    }
  };

  const handleMenuItemClick = useCallback(
    async (itemClicked) => {
      if (itemClicked === ACTION_BUTTONS_NAME.COPY_SHORTHAND.key) {
        extractCopyShortHandForSummaryRows(props);
      }

      if (itemClicked === ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key) {
        googleEvenTracker(
          GA_EVENTS_NAMES.TEAM_RUN_PAGE_COPY_SHORTHAND_NO_VOL_CLICK,
          "Team Run"
        );
        extractCopyShortHandForSummaryRows(props, false);
        return;
      }

      if (itemClicked === ACTION_BUTTONS_NAME.ARCHIVE.key) {
        const extractSummaryIds = selectedRows[rowData.product_title];
        if (
          selectedRows[rowData.product_title] &&
          selectedRows[rowData.product_title].length > 1
        ) {
          getMultipleArchives(extractSummaryIds).then((res) => {
            googleEvenTracker(
              GA_EVENTS_NAMES.TEAM_RUN_PAGE_ARCHIVE_TRIGGER,
              "Team Run"
            );
            toast(res?.data?.message, "success");
            triggerSingleAPIFunc(rowData.product_id);
          });
          return;
        } else {
          getArchive(rowData.summary_id).then((res) => {
            toast(res?.data?.message, "success");
            triggerSingleAPIFunc(rowData.product_id);
          });
          return;
        }
      }

      if (itemClicked === ACTION_BUTTONS_NAME.DETAILED_AUDIT_TRIAL.key) {
        googleEvenTracker(
          GA_EVENTS_NAMES.TEAM_RUN_PAGE_DETAIL_AUDIT_TRAIL_VIEW,
          "Team Run"
        );
        const preparePath = generatePath(ROUTES_PATH.AUDIT_TRAIL.auditDetails, {
          id: rowData.id,
        });
        navigate(preparePath);
        return;
      }

      if (itemClicked === ACTION_BUTTONS_NAME.PULL_TO_PRICER.key) {
        googleEvenTracker(
          GA_EVENTS_NAMES.TEAM_RUN_PAGE_PULL_TO_PRICER_TRIGGER,
          "Team Run"
        );

        handlePullToPricerClickTeamRun(rowData.summary_id);
        return;
      }
      if (itemClicked === ACTION_BUTTONS_NAME.ADDITIONAL_DATA.key) {
        let teamRun_data = {
          isAdditionalDataItemClicked: true,
          isOpen: true,
          data: props?.data ? props.data : props.node.aggData,
        };

        setAdditionalPopupData(teamRun_data);
        return;
      }

      if (itemClicked === ACTION_BUTTONS_NAME.TRADE.key) {
        googleEvenTracker(
          GA_EVENTS_NAMES.TRADE_OPTION_MODAL_TRADE_VIEW,
          "Team Run"
        );
        let teamRun_data = {
          isTradeItemClicked: true,
          data: props.data,
        };
        setTradePopupData(teamRun_data);
        return;
      }

      if (itemClicked === ACTION_BUTTONS_NAME.UPDATE_BID.key) {
        setDataForBidAndAskPopup(itemClicked);
        googleEvenTracker(
          GA_EVENTS_NAMES.UPDATE_BID_MODAL_UPDATE_BID_VIEW,
          "Team Run"
        );

        return;
      }
      if (itemClicked === ACTION_BUTTONS_NAME.UPDATE_ASK.key) {
        googleEvenTracker(
          GA_EVENTS_NAMES.UPDATE_ASK_MODAL_UPDATE_ASK_VIEW,
          "Team Run"
        );
        setDataForBidAndAskPopup(itemClicked);
        return;
      }

      if (itemClicked === ACTION_BUTTONS_NAME.UPDATE_OPTION.key) {
        setIsUpdateOptionClicked(true);
        return;
      }

      if (itemClicked === ACTION_BUTTONS_NAME.PUSH_OPTION.key) {
        setIsPushOptionClicked(true);
        return;
      }

      await handleMenuItemClickPartTwo(itemClicked);
      setTimeout(() => setMenuItemsClicked(""), 0);
    },
    [rowData, selectedRows, colorArray, selectedRowsLength]
  );

  const showDropDown = () => {
    return (
      <Dropdown
        className={`action-buttons__dropdown button-icon ${
          !dark_Theme && "button-light-theme"
        }`}
        trigger={["click"]}
        overlay={
          <Menu
            onClick={({ key }) => handleMenuItemClick(key)}
            key="action-buttons__menu-items_MENU"
            items={
              !menuItemsClicked && !selectedRowsLength
                ? returnSelectedDropDown()
                    ?.sort((a, b) => a.index - b.index)
                    .map((item) =>
                      authorizedActions.includes(item.key) ? item : null
                    )
                : menuItemsData
                    ?.sort((a, b) => a.index - b.index)
                    .map((item) =>
                      authorizedActions.includes(item.key) ? item : null
                    )
            }
            theme={dark_Theme ? "dark" : "light"}
          />
        }
        overlayClassName={dark_Theme && "menu-dropdown-dark"}
      >
        <Space>
          <MenuOutlined />
        </Space>
      </Dropdown>
    );
  };

  return (
    <div className="action-buttons__container action-button_container justify-content-start">
      {/* Show Menu Item When Menu Icon Clicked */}
      {showDropDown()}
    </div>
  );
};

export default ActionColumn;
