import {useEffect} from "react";

const GenericFallback = (props) => {
  useEffect(() => {
    if (!props.error)
      return;
    console.error(props.error);
  }, [props.error]);

  return null;
};


export default GenericFallback;
