import React from 'react';
// Components
import AgGridTables from '../TeamRunReadonlyAgGridTables/TeamRunReadonlyAgGridTables';
import TeamRunReadonlyState from 'lib/contexts/TeamRunContextReadonly';

const TeamRunReadonlyTable = () => {
  return (
    <TeamRunReadonlyState>
      <AgGridTables />
    </TeamRunReadonlyState>
  );
};

export default TeamRunReadonlyTable;