import { Col, InputNumber } from "antd";
import { FormItem } from "./AddOptionHelperComponents";
import { FIELDS_LABEL } from "lib/constant/AddOption/AddOption";

function UnderlyingOption({
  UNDERLYING_COUNT,
  underlyingData,
  setUnderlyingData,
  UNDERLYING,
}) {
  return (
    <Col span={24}>
      <FormItem label={FIELDS_LABEL.UNDERLYING.label}>
        <div className="horizontal-scroll__container">
          {Array.from(Array(UNDERLYING_COUNT), (element, parentIndex) => {
            return (
              <InputNumber
                key={parentIndex}
                onPressEnter={(event) => event.target.blur()}
                className="input-number"
                min={0}
                value={UNDERLYING ? UNDERLYING : underlyingData[parentIndex]}
                onChange={(value) =>
                  setUnderlyingData({
                    ...underlyingData,
                    [parentIndex]: value,
                  })
                }
              />
            );
          })}
        </div>
      </FormItem>
    </Col>
  );
}

export default UnderlyingOption;
