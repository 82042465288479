function PushToAuditTrailItem({ children, label, className }) {
  return (
    <div
      className={`d-flex justify-content-between align-items-center ${className}`}
    >
      <span className="date-label fw-bold">{label}</span>
      {children}
    </div>
  );
}

export default PushToAuditTrailItem;
