import { AgGridReact } from "ag-grid-react";
import { Row } from "antd";

import { useContext, useEffect, useMemo, useRef, useState } from "react";
import "ag-grid-enterprise";
import { GlobalContext } from "lib/contexts/GlobalContext";
import { VOL_SURFACE_Data_COLS } from "lib/constant/VolSurface";
import { getSurfacesData, getVolSurfaceData } from "api/VolSurface/VolSurface";
import {} from "lib/helpers/VulSurface/VulSurface";
import { useVolSurfaceControlTable } from "lib/contexts/VolSurfaceControlTableContext";
import { getRowClass, getRowStyle } from "lib/constant/AgGridCommonStyle";
import VolSurfaceDataFilters from "./VolSurfaceDataFilters";

export default function VolSurfaceDataTable({ scrollableRef }) {
  const [gridHeight, setGridHeight] = useState("auto");
  const [volSurfaceData, setVolSurfaceData] = useState({});
  const [surfaces, setSurfaces] = useState([]);
  const [surface, setSurface] = useState(null);
  const gridRef = useRef();
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const {
    setVolSurfaceData: setRowsData,
    isLoading,
    selectedFilters,
    setSurfaceId,
    setTableHeight,
  } = useVolSurfaceControlTable();

  const columnDefs = useMemo(() => VOL_SURFACE_Data_COLS, []);

  const getRowId = (params) => {
    return params.data.id;
  };

  //get surfaces
  useEffect(() => {
    if (!selectedFilters?.product_id )
      return;
    getSurfacesData({
      product_contract_style_id: selectedFilters?.product_id,
    }).then((res) => {
      setSurfaces(res.data.data);
      setSurface(res.data.data.at(0).value);
    });
  }, [ selectedFilters?.product_id]);

  //Get Control Table Data
  useEffect(() => {
    if (
      !surface ||
      !selectedFilters?.product_id 
    )
      return;
    getVolSurfaceData({
      product_contract_style_id: selectedFilters?.product_id,
      surface_id: surface,
    }).then((res) => {
      setVolSurfaceData(res.data.data);
      setRowsData(res.data.data.main_control_table);
    });
  }, [
    selectedFilters?.product_id,
    selectedFilters?.shortcutFilter,
    setRowsData,
    surface
  ]);

  useEffect(() => {
    setSurfaceId(surface);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surface]);

  //handle grid height
  useEffect(() => {
    if (volSurfaceData.main_control_table?.length === 0) return;
    const numberOfRowData = volSurfaceData.main_control_table?.length;
    const calculatedHeight = numberOfRowData * 30 + 95;
    setGridHeight(`${calculatedHeight}px`);
    setTableHeight(`${calculatedHeight}px`);
  }, [volSurfaceData.main_control_table?.length, setTableHeight]);

  const gridOptions = {
    stopEditingWhenGridLosesFocus: false,
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    gridRef.current.api.showLoadingOverlay();
  };

  function onSurfaceChange(value) {
    setSurface(value);
    setSurfaceId(value.value);
  }

  if (
    isLoading ||
    !selectedFilters?.product_id 
  )
    return null;

  return (
    <div style={{ width: "100%" }}>
      <VolSurfaceDataFilters
        riskFree={volSurfaceData?.risk_free}
        surfaces={surfaces}
        onChange={onSurfaceChange}
        value={surface}
      />
      <Row justify={"start"} style={{ width: "100%" }}>
        <div
          className={`${
            dark_Theme || false
              ? "pricer-ag-grid__container_dark"
              : "pricer-ag-grid__container"
          }`}
          style={{
            height: gridHeight,
            width: "100%",
            padding: "5px 0px 5px 0px",
          }}
        >
          <AgGridReact
            getRowId={getRowId}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            headerHeight={30}
            rowHeight={30}
            rowData={volSurfaceData.main_control_table}
            getRowStyle={(params) => {
              if (params.data.field !== "spread") {
                return {
                  borderTop: "none",
                  borderBottom: "none",
                };
              }
              getRowStyle(params);
            }}
            getRowClass={getRowClass}
            ref={gridRef}
            stopEditingWhenCellsLoseFocus={true}
            columnDefs={columnDefs}
            groupDefaultExpanded={1}
            suppressMovableColumns={true}
            suppressContextMenu
            className={`ag-theme-alpine pricer-ag-grid__table ${
              dark_Theme ? " ag-theme-balham-dark" : "ag-theme-balham"
            }`}
          />
        </div>
      </Row>
    </div>
  );
}
