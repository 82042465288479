import { Button, Col, Row } from "antd";

export default function SaveVolSurface({
  dark_Theme,
  selectedFilters,
  disabledGraphControl,
  onUpdateGraphTitle,
}) {
  return (
    <Col span={24}>
      <Row gutter={[25, 25]}>
        <Col span={3}>Vol Surface: </Col>
        <Col>
          <Button
            className={dark_Theme ? "graph-btn" : ""}
            disabled={!selectedFilters["product_id"] || disabledGraphControl}
            onClick={() => onUpdateGraphTitle()}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Col>
  );
}
