import React, { useState, useMemo, useContext, useEffect } from "react";
import { Select } from "antd";
// Context
import { GlobalContext } from "lib/contexts/GlobalContext";
// Helpers
import {
  FIELD_NAMES,
  GREEK_COLUMNS_WITH_LABELS,
} from "lib/constant/Pricer/PricerTable";
// Style
import "./GreeksDropDown.scss";
import { getDataFromLocalStorage, setDataToLocalStorage } from "lib/helpers/GeneralFuncHelpers";

const { Option } = Select;

const GreekDropDown = ({ field, ...props }) => {
  const initialData = getDataFromLocalStorage("greeksDropDown");
  const greeksDropDownData = GREEK_COLUMNS_WITH_LABELS;
  const [dropdownData, setDropDownData] = useState({
    [FIELD_NAMES.COMPANY]: greeksDropDownData["company"],
    [FIELD_NAMES.BID]: greeksDropDownData["bid"],
    [FIELD_NAMES.ASK]: greeksDropDownData["ask"],
  });
  const [selectedItem, setSelectedItem] = useState("");
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const onSelectionChanged = (value) => {
    const pricerCols = props.api.getColumnDefs();
    const colId = props.column.colId;
    setSelectedItem(value);
    pricerCols.forEach((def) => {
      if (def.colId === colId) {
        if (def.colId.toString().includes("company")) {
          selectedCompany = value;
        }
        if (def.colId.toString().includes("bid")) {
          selectBid = value;
        }
        if (def.colId.toString().includes("ask")) {
          selectAsk = value;
        }

        def.colId = value;
        def.field = value;
      }
      return def;
    });
    
    setDataToLocalStorage("greeksDropDown", {
      company: selectedCompany || "company_value",
      bid: selectBid || "bid_delta",
      ask: selectAsk || "ask_delta",
    });

    props.api.setColumnDefs(pricerCols);
  };

  const showHeaderDropDown = useMemo(() => {
    const AskOrBidOrCompanydropdownData = props.column.colDef.colId?.includes(
      "company"
    )
      ? dropdownData[FIELD_NAMES.COMPANY]
      : props.column.colDef.colId?.includes("ask")
      ? dropdownData[FIELD_NAMES.ASK]
      : dropdownData[FIELD_NAMES.BID];

    return AskOrBidOrCompanydropdownData?.map((item) => {
      return (
        <Option key={item.key} value={item.key}>
          {item.label}
        </Option>
      );
    });
  }, [props.column.colDef.colId, dropdownData]);

 useEffect(() => {
   selectAsk = initialData?.ask || "";
   selectBid = initialData?.bid || "";
   selectedCompany = initialData?.company || "";
 }, []);

  const returnValue = useMemo(() => {
    const containsCompany = props.column.colDef.colId
      .toString()
      .includes("company");

    if (containsCompany) {
      return initialData?.company || dropdownData[FIELD_NAMES.COMPANY][0];
    }
    const containsAsk = props.column.colDef.colId
      .toString()
      .includes(FIELD_NAMES.ASK);

    if (containsAsk) {
      return initialData?.ask || dropdownData[FIELD_NAMES.ASK][0];
    }
    return initialData?.bid || dropdownData[FIELD_NAMES.BID][0];
  }, [dropdownData, initialData?.ask, initialData?.bid, initialData?.company, props.column.colDef.colId]);

 

  return (
    <Select
      className={` greek-drop-down__container ${
        dark_Theme
          ? "greek-drop-down__container-dark"
          : "greek-drop-down__container-light"
      }`}
      onChange={(val) => onSelectionChanged(val)}
      value={returnValue}
      popupClassName={dark_Theme && "dropdown-dark"}
    >
      {showHeaderDropDown}
    </Select>
  );
};

var selectedCompany = "";
var selectBid = "";
var selectAsk = "";

export default GreekDropDown;
