import axios from "api/axios";
import { returnSelectedIdForEditTradeDetails } from "lib/helpers/AuditTrail/generalHelper";

export const updateTradeLegs = async (data) => {
  return await axios.post(`/team-run/trade/update-legs`, data);
};

export const getEditEditTRadeDetails = async (bodyParams) => {
  return await axios.get(`team-run/trade`, {
    params: {
      ...bodyParams,
    },
  });
};

export const refetchTradeDetails = async (bodyParams) => {
  return await axios.get(`team-run/trade/show`, {
    params: {
      ...bodyParams,
    },
  });
};

export const updatePremiumTrade = async (data) => {
  return await axios.post(`/team-run/trade/update-premium`, data);
};

export const updateQuantityTrade = async (data) => {
  return await axios.post(`/team-run/trade/update-quantity`, data);
};

export const storeOrUpdateTradeDetails = async (props, data) => {
  // const endPointName =
  //   props.isTeamRun || props.isPricer
  //     ? "store-trade-details"
  //     : "update-trade-details";

  const endPointName = "trade/conf-email";
  return await axios.post(
    `${returnSelectedIdForEditTradeDetails(props, true)}/${endPointName}`,
    { ...data }
  );
};
export const saveAndSendTradeConfirmation = async (props, data) => {
  const endPointName = "trade/conf-email";
  return await axios.post(
    `${returnSelectedIdForEditTradeDetails(props, true)}/${endPointName}`,
    { ...data }
  );
};
