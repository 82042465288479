import { AUDIT_TRAIL_STATE } from "lib/constant/Profile/Profile";
import {
  PRICER_EXPANDED_ROWS,
  createQueryParamsForSSRM,
  expandedMatchedRows,
  getDataFromLocalStorage,
} from "../GeneralFuncHelpers";
import _ from "lodash";
import { fetchTrailDataForVolSurface } from "api/VolSurface/VolSurface";
import {
  fetchAuditTrailPages,
  fetchTrailData,
} from "api/AuditTrail/AuditTrail";
import { setUserPreferencesForSSRM } from "./generalHelper";
import { FIELD_NAMES } from "lib/constant/AuditTrail/AuditTrail";

export const AUDIT_TRAIL_DATAFunc = () =>
  getDataFromLocalStorage(AUDIT_TRAIL_STATE);

export const expandRowAuditTrailBasedOnSummaryId = (gridAPI) => {
  const auditTrailData = AUDIT_TRAIL_DATAFunc();

  if (_.isEmpty(auditTrailData?.[PRICER_EXPANDED_ROWS])) return;

  Object.values(auditTrailData[PRICER_EXPANDED_ROWS]).forEach((keyItem) => {
    expandedMatchedRows(gridAPI, keyItem, true);
  });
};

/**
 * Creates a server-side data source for fetching rows based on the provided parameters.
 * @param {Object} params - The parameters for fetching rows.
 * @return {void} This function does not return a value directly, but handles success and failure cases through callbacks.
 */
export const createServerSideDatasource = ({
  detailPageId,
  pathName,
  volSurfaceButtonClicked,
  setAuditTrailData,
  setDetailedAuditTrailData,
  filterModelDataForAuditTrail,
  filterModelData,
  sort_model,
  selectedFilters,
  start_date,
  end_date,
}) => {
  return {
    getRows: (params) => {
      const startRow = params.request.startRow;
      const endRow = params.request.endRow;
      const groupKeys = JSON.stringify(params.request.groupKeys || []);
      // If it's a detail_audit_trail page then set filterModel to "[]" else go with the existing filterModel
      const filterModel = detailPageId
        ? JSON.stringify([])
        : JSON.stringify(
            filterModelData
              ? filterModelData
              : filterModelDataForAuditTrail !== null
              ? filterModelDataForAuditTrail
              : params.request.filterModel || []
          );

      const sortModel = sort_model
        ? JSON.stringify(sort_model)
        : JSON.stringify(params.request.sortModel || []);

      const queryParams = createQueryParamsForSSRM(
        startRow,
        endRow,
        sortModel,
        groupKeys,
        filterModel
      );

      let dateFilter = {
        start_date,
        end_date,
      };

      // Check if it is a vol-surface request
      if (pathName.includes("vol-surface")) {
        if (volSurfaceButtonClicked && volSurfaceButtonClicked === "Table") {
          fetchTrailDataForVolSurface({
            ...queryParams,
            ...selectedFilters,
            ...dateFilter,
          })
            .then((response) => {
              const data = response.data.data;
              params.success({
                rowData: data.rows,
                rowCount: data.lastRow,
              });
            })
            .catch((error) => {
              console.error("Error fetching Vol-surface data:", error);
              params.fail();
            });
        }
      } else {
        // for detail audit trail
        if (detailPageId) {
          fetchAuditTrailPages(detailPageId, queryParams)
            .then((response) => {
              const data = response.data.data;
              if (setDetailedAuditTrailData) {
                setDetailedAuditTrailData(data);
              }
              params.success({
                rowData: data.rows,
                rowCount: data.lastRow,
              });
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              params.fail();
            });
        }
        // For normal Audit trail
        else {
          fetchTrailData({ ...queryParams, ...filterModelDataForAuditTrail })
            .then((response) => {
              const data = response.data.data;
              if (setAuditTrailData) {
                setAuditTrailData(data);
              }
              params.success({
                rowData: data.rows,
                rowCount: data.lastRow,
              });
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              params.fail();
            });
        }
      }
    },
  };
};

export const onAuditTrailGridReady = (gridApi) => {
  expandRowAuditTrailBasedOnSummaryId(gridApi);
};

/**
 * Sets the sort model for the audit trail based on the given parameters.
 * @param {Object} params - The parameters for the sort change.
 * @param {Function} setSortModel - The function to set the sort model.
 * @param {string} pathName - The path name of the audit trail.
 */
export const onAuditTrailSortChange = (params, setSortModel, pathName) => {
  setSortModel(params);
  let columnState = params.columnApi?.getColumnState();
  let sortedCol = [];
  columnState.forEach((col) => {
    if (col.sort) {
      sortedCol.push({
        colId: col.colId,
        sort: col.sort,
      });
    }
  });

  let data = {
    table_name: pathName.includes("vol-surface")
      ? "vol_surface"
      : "audit_trail",
    sort_model: JSON.stringify(sortedCol),
  };
  setUserPreferencesForSSRM(data);
};

/**
 * Handles the selection change event in the Audit Trail component.
 * @param {Object} params - The parameters object containing the API reference.
 * @param {Function} setAuditTrailSelectedRowState - The function to set the selected row state.
 */
export const onAuditTrailSelectionChanged = (
  params,
  setAuditTrailSelectedRowState
) => {
  const selectedRows = params.api.getSelectedRows();
  const removeDuplicates = [
    ...new Map(
      selectedRows.map((item) => [item[FIELD_NAMES.SUMMARY_ID], item])
    ).values(),
  ];
  const summaryIds = removeDuplicates.map((item) => item.summary_id);
  setAuditTrailSelectedRowState(summaryIds);
};

/**
 * Updates the column state when the audit trail columns are resized.
 * @param {Object} params - The parameters for the function.
 * @param {boolean} firstRender - Flag indicating if it's the first render.
 * @param {Function} setColumnsState - The function to set the columns state.
 */
export const onAuditTrailColumnResized = (
  params,
  firstRender,
  setColumnsState
) => {
  if (!firstRender) {
    let columnState = params.columnApi?.getColumnState();
    const columnsWidth = columnState.map((item) => ({
      width: item.width,
      colId: item.colId,
    }));
    setColumnsState(columnsWidth);
  }
};

/**
 * Handles the first data rendered event in the Audit Trail component.
 * @param {Object} params - The parameters object containing the API reference.
 * @param {Function} setAuditTrailSelectedRowState - The function to set the selected row state.
 */
export const onAuditTrailFirstDataRendered = (params, setAuditTrailSelectedRowState) => {
    const rows = params.api.getSelectedRows();
    const removeDuplicates = [
      ...new Map(
        rows.map((item) => [item[FIELD_NAMES.SUMMARY_ID], item])
      ).values(),
    ];
    const summaryIds = removeDuplicates.map((item) => item.summary_id);
    setAuditTrailSelectedRowState(summaryIds);
  }