import React, { useContext, useState } from "react";
import { Modal, Button, Input } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
// Constants
// API
import { onPushOptionClicked } from "api/Pricer/Table";
// Helpers
import { toast } from "lib/helpers/utilities";
// Styles
import "./PushOption.scss";
import { GlobalContext } from "lib/contexts/GlobalContext";
import { ACTION_BUTTONS_NAME } from "lib/constant/GeneralActionButtons";
import CompanyTraders from "components/EditTradeDetails/CompanysTrader";

const LABEL_NAME = {
  SIMILAR_OPTION: { label: "Similar options" },
  PUSH_OPTION_LABEL: { label: "Push Option" },
};

const PushOption = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isArrowIconClicked, setIsArrowIconClicked] = useState(true);
  const [tradingCompanies, setTradingCompanies] = useState(
    props.tradingCompanies
  );
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const handleTraderCompanyChange = (index, e) => {
    const updatedTraderCompanies = [...tradingCompanies];
    updatedTraderCompanies[index].value = e;
    setTradingCompanies(updatedTraderCompanies);
  };

  function onClickHandle() {
    if (tradingCompanies.map((item) => item.value).includes(""))
      return toast("Please add a valid trader company name", "warning");

    setIsLoading(true);
    const extractTraders = {
      ask_trading_company_id: tradingCompanies.find(
        (item) => item.key === "ask_trading_company_id"
      )?.value,
      bid_trading_company_id: tradingCompanies.find(
        (item) => item.key === "bid_trading_company_id"
      )?.value,
    };
   
    onPushOptionClicked(
      props.summaryId,
      extractTraders,
      props?.data?.same_team_run_option_id
    )
      .then(() => {
        toast("Pushed Successfully", "success");
        setIsLoading(false);
        props.onClose(false);
      })
      .then(() => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Modal
      getContainer={"#content-root"}
      title={ACTION_BUTTONS_NAME.PUSH_OPTION.label}
      width={"40vw"}
      onCancel={() => props.onClose(false)}
      open={props.isOpen}
      footer={[
        <Button loading={isLoading} onClick={onClickHandle} type="primary">
          {LABEL_NAME.PUSH_OPTION_LABEL.label}
        </Button>,
      ]}
      className={`push-option__container ${
        dark_Theme ? "ant-modal-dark" : "ant-modal-light"
      }`}
    >
      {props.data != null && (
        <>
          <div
            className={dark_Theme ? "option-label-light" : "option-label-light"}
          >
            <span>{props.data.label}</span>
            <span>{props.data.value}</span>
          </div>

          {props.data["similar_options"] && (
            <div>
              <span>{LABEL_NAME.SIMILAR_OPTION.label}</span>
              <span className="arrows--icon">
                {" "}
                {isArrowIconClicked ? (
                  <DownOutlined
                    onClick={() => setIsArrowIconClicked((prev) => !prev)}
                  />
                ) : (
                  <UpOutlined
                    onClick={() => setIsArrowIconClicked((prev) => !prev)}
                  />
                )}
              </span>
              {isArrowIconClicked &&
                props.data["similar_options"]?.map((item) => (
                  <div>
                    <span dangerouslySetInnerHTML={{ __html: item }} />
                  </div>
                ))}
            </div>
          )}
        </>
      )}
      {tradingCompanies.map((item, index) => (
        <div key={index} className="trader-input__container">
          <h6 className={dark_Theme ? "label-light" : "label-dark"}>
            {item.label}
          </h6>
          <div  style={{ width: "50%"}} >
            <CompanyTraders
              defaultValue={item.key}
              onChange={(value) => handleTraderCompanyChange(index, value)}
              placeholder="Select Trader Company"
              isPopup={true}
              value={item?.value || ""}
            />
          </div>
          {/* <Input
            type="text"
            className="trader-input"
            value={item.value}
            onChange={(e) => handleTraderCompanyChange(index, e)}
          /> */}
        </div>
      ))}
    </Modal>
  );
};

export default PushOption;
