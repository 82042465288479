import React from "react";
import { Carousel, Checkbox, Col, Radio, Row, Tooltip } from "antd";
import { FormItem } from "./AddOptionHelperComponents";
import { FIELDS_LABEL } from "lib/constant/AddOption/AddOption";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

function Tenors({
  fetchedData,
  isBespokeChecked,
  setIsBespokeChecked,
  tenorDataSelectedButtons,
  setTenorDataSelectedButtons,
}) {
  const showBespokeTenorPicker = () => {
    const constraintsTenorNumber =
      fetchedData?.[FIELDS_LABEL.BESPOKE_TENOR.key]?.length;
    if (
      !fetchedData?.[FIELDS_LABEL.BESPOKE_TENOR.key] ||
      !constraintsTenorNumber
    ) {
      return null;
    }
    return (
      <Carousel
        className="tenors-date-carousel"
        arrows
        dots={false}
        swipeToSlide
        rows={1}
        prevArrow={<LeftOutlined />}
        nextArrow={<RightOutlined />}
      >
        {fetchedData[FIELDS_LABEL.BESPOKE_TENOR.key].map((item) => {
          return (
            <React.Fragment key={item.title}>
              {" "}
              {/* Added key attribute */}
              <div className="bespoke-tenor-title">{item?.title}</div>
              {item?.tenors.map((nestedElement) => {
                return (
                  <div key={nestedElement.title} id="bespoke-checkbox-list">
                    {" "}
                    {/* Added key attribute */}
                    {nestedElement.map((nestedElement2) => {
                      const checkboxButtonJSX = (
                        <Checkbox
                          className="tenor-date"
                          key={`${nestedElement2.title}${nestedElement2.id}`}
                          value={nestedElement2.id}
                        >
                          {nestedElement2.title}
                        </Checkbox>
                      );

                      return nestedElement2.title.length > 7 ? (
                        <Tooltip
                          title={nestedElement2.title}
                          placement="top"
                          key={`${nestedElement2.title}${nestedElement2.id}-tooltip`}
                        >
                          {" "}
                          {/* Added key attribute */}
                          {checkboxButtonJSX}
                        </Tooltip>
                      ) : (
                        checkboxButtonJSX
                      );
                    })}
                  </div>
                );
              })}
            </React.Fragment>
          );
        })}
      </Carousel>
    );
  };

  const showTenorDatePicker = (parentIndex) => {
    const constraintsTenorNumber =
      fetchedData?.[FIELDS_LABEL.CONSTRAINTS.key]?.tenors;
    if (
      !fetchedData?.[FIELDS_LABEL.CONTRACT_STYLE.key] ||
      !fetchedData?.[FIELDS_LABEL.TENOR.key] ||
      !constraintsTenorNumber
    )
      return;

    return Object.values(fetchedData[FIELDS_LABEL.TENOR.key]).map(
      (item, index) => {
        return (
          <Carousel
            key={JSON.stringify(item[index])}
            className="tenors-date-carousel"
            arrows
            dots={false}
            swipeToSlide
            rows={1}
            prevArrow={<LeftOutlined />}
            nextArrow={<RightOutlined />}
          >
            {item.map((nestedElement) => {
              return (
                <div key={nestedElement.id}>
                  {nestedElement.map((nestedElement2) => {
                    const radioButtonJSX = (
                      <Radio.Button
                        onClick={() =>
                          setTenorDataSelectedButtons((prev) => ({
                            ...prev,
                            [parentIndex]: nestedElement2.id,
                          }))
                        }
                        className="tenor-date"
                        key={`${nestedElement2.title}${nestedElement2.id}`}
                        value={nestedElement2.id}
                      >
                        {nestedElement2.title}
                      </Radio.Button>
                    );

                    return nestedElement2.title.length > 7 ? (
                      <Tooltip title={nestedElement2.title} placement="top">
                        {radioButtonJSX}
                      </Tooltip>
                    ) : (
                      radioButtonJSX
                    );
                  })}
                </div>
              );
            })}
          </Carousel>
        );
      }
    );
  };
  const showBespoke =
    fetchedData[FIELDS_LABEL.CONSTRAINTS.key]["show_bespoke_checkbox"];
  return (
    <Col span={24}>
      <FormItem label={FIELDS_LABEL.TENOR.label}>
        <Row gutter={[60, 0]} className="horizontal-scroll__container">
          {fetchedData[FIELDS_LABEL.CONSTRAINTS.key] &&
            Array.from(
              Array(
                fetchedData[FIELDS_LABEL.CONSTRAINTS.key][
                  FIELDS_LABEL.TENOR.key
                ]
              ),
              (element, parentIndex) => {
                if (!(parentIndex in isBespokeChecked)) {
                  setIsBespokeChecked((prev) => ({
                    ...prev,
                    [parentIndex]: false,
                  }));
                }
                if (isBespokeChecked[parentIndex]) {
                  return (
                    <Col sm={12} md={8}>
                      <Row gutter={[0, 0]}>
                        {showBespoke && (
                          <Col span={24}>
                            <FormItem label={FIELDS_LABEL.BESPOKE.label}>
                              <Checkbox
                                checked={isBespokeChecked[parentIndex]}
                                onChange={(e) =>
                                  setIsBespokeChecked((prev) => ({
                                    ...prev,
                                    [parentIndex]: e.target.checked,
                                  }))
                                } //setIsBespokeChecked
                              />
                            </FormItem>
                          </Col>
                        )}
                        <Col span={24}>
                          <Checkbox.Group
                            className="tenor__container"
                            buttonStyle="solid"
                            key={parentIndex}
                            value={
                              Array.isArray(
                                tenorDataSelectedButtons[parentIndex]
                              )
                                ? tenorDataSelectedButtons[parentIndex]
                                : []
                            }
                            onChange={(e) =>
                              setTenorDataSelectedButtons((prev) => ({
                                ...prev,
                                [parentIndex]: e,
                              }))
                            }
                          >
                            {showBespokeTenorPicker()}
                          </Checkbox.Group>
                        </Col>
                      </Row>
                    </Col>
                  );
                } else {
                  return (
                    <Col sm={12} md={8}>
                      <Row gutter={[0, 0]}>
                        {showBespoke && (
                          <Col span={24}>
                            <FormItem label={FIELDS_LABEL.BESPOKE.label}>
                              <Checkbox
                                checked={isBespokeChecked[parentIndex]}
                                onChange={(e) =>
                                  setIsBespokeChecked((prev) => ({
                                    ...prev,
                                    [parentIndex]: e.target.checked,
                                  }))
                                } //setIsBespokeChecked
                              />
                            </FormItem>
                          </Col>
                        )}
                        <Col span={24}>
                          <Radio.Group
                            className="tenor__container"
                            buttonStyle="solid"
                            style={{ marginTop: 30 }}
                            value={
                              Array.isArray(
                                tenorDataSelectedButtons[parentIndex]
                              )
                                ? undefined
                                : tenorDataSelectedButtons[parentIndex]
                            }
                          >
                            {showTenorDatePicker(parentIndex)}
                          </Radio.Group>
                        </Col>
                      </Row>
                    </Col>
                  );
                }
              }
            )}
        </Row>
      </FormItem>
    </Col>
  );
}

export default Tenors;
