import React, { useContext, useEffect } from 'react';
import {Layout as AntdLayout} from "antd";
import * as Sentry from "@sentry/browser";

import PropTypes from "prop-types";
import NavBar from 'components/NavBar';
import './Layout.scss'
import { GlobalContext } from 'lib/contexts/GlobalContext';

const { Content, Footer } = AntdLayout;

const Layout = (props) => {
  const {globalState: {dark_Theme} } = useContext(GlobalContext);

   useEffect(() => {
    Sentry.addIntegration(
      Sentry.feedbackIntegration({
        autoInject: false,
        themeLight: {
          background: "#ffffff",
          color: "#000000",
          submitBackground: "var(--color-blue-2)",
          submitBorder: "var(--color-blue-2)",
          submitBackgroundHover: "var(--color-blue-3)",
        },
        showBranding: false,
        colorScheme: dark_Theme? "dark" : "light",
        themeDark: {
          background: "#000000",
          color: "#ffffff",
          submitBackground: "var(--color-blue-2)",
          submitBorder: "var(--color-blue-2)",
          submitBackgroundHover: "var(--color-blue-3)",
        },
      })
    );
    
   }, [dark_Theme, ]);

  return (
    <React.Suspense fallback={<div />}>
      <AntdLayout>
        {/* Header */}
        <NavBar navTabs= {props.navTabs} />

        {/* Content */}
        <Content
          className={`${
            dark_Theme || false ? "content_dark" : "content_light"
          } `}
        >
          {props.children}
        </Content>

        {/* Footer */}
        {props.footer && <Footer>{/* Add Footer When Needed */}</Footer>}
      </AntdLayout>
     
    </React.Suspense>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  noFooter: PropTypes.bool
};

Layout.defaultProps = {
  noFooter: false
};

export default Layout;
