import EditTradeDetails from "components/EditTradeDetails/EditTradeDetails";
import React from "react";
import UpdateBid from "../UpdateBid/UpdateBid";
import AdditionalDataModal from "components/AuditTrail/AdditionalData/AdditionalData";

function TeamRunPopups({
  tradePopup_data,
  bidAskPopup_data,
  isAdditionalDataClicked,
  handleBidAskPopupClose,
  handlePopupClose,
  additionalData,
  isAdditionalDataOpen,
  handleAdditionalDataModalClose,
}) {
  return (
    <>
      {tradePopup_data?.isTradeItemClicked && (
        <EditTradeDetails
          isTeamRun
          isOpen={tradePopup_data?.isTradeItemClicked}
          handleMenuItemClicked={handlePopupClose}
          rowSelectedInfo={tradePopup_data?.data}
        />
      )}

      {isAdditionalDataClicked && (
        <AdditionalDataModal
          isOpen={isAdditionalDataOpen}
          rowSelectedInfo={additionalData}
          handleMenuItemClicked={handleAdditionalDataModalClose}
          
        />
      )}

      {bidAskPopup_data.isUpdateBidModalVisible && (
        <UpdateBid
          data={bidAskPopup_data.data}
          keyItem={bidAskPopup_data.keyItem}
          setIsOpen={handleBidAskPopupClose}
          isOpen={bidAskPopup_data.isOpen}
          selectedModal={bidAskPopup_data.selectedModal}
          triggerSingleAPIFunc={bidAskPopup_data.triggerSingleAPIFunc}
        />
      )}
    </>
  );
}

export default TeamRunPopups;
