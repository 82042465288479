import { Col, Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

export default function WholeSurface({
    dark_Theme,
    onBiasChange,
    disabledCondition,
    batchUpdate
}) {
  return (
    <>
      <Col span={3}> Whole Surface: </Col>
      <Col>
        <Button
          icon={<UpOutlined />}
          className={dark_Theme ? "graph-btn" : ""}
          onClick={() => onBiasChange(true)}
          disabled={disabledCondition || batchUpdate}
        />
        <Button
          icon={<DownOutlined />}
          className={dark_Theme ? "graph-btn" : ""}
          onClick={() => onBiasChange(false)}
          disabled={disabledCondition || batchUpdate}
        />
      </Col>
    </>
  );
}
