import TraderAgGridTables from "components/Trader/TraderAgGridTables/TraderAgGridTables";
import TraderState from "lib/contexts/TraderContext";

import TeamRunPageLayout from "components/TeamRun/TeamRunLayout/TeamRunLayout";

function Trader() {
    return (
      <TeamRunPageLayout>
        <TraderState>
          <TraderAgGridTables />
        </TraderState>
      </TeamRunPageLayout>
    );
}

export default Trader
