import axios from "api/axios";

export const fetchAdditionalData = async (rowSelectedInfo) => {
  return await axios.get(
    `audit-trails/${rowSelectedInfo?.summary_id}/additional-info`
  );
};

export const fetchTrailData = async (queryParams) => {
  return await axios.get("/audit-trails?", {
    params: {
      ...queryParams,
    },
  });
};

export const fetchAuditTrailPages = async (id, queryParams) => {
  return await axios.get(`audit-trails/${id}`, {
    params: {
      ...queryParams,
    },
  });
};

export const fetchProducts = async () => {
  return await axios.get("audit-trails/products");
};

export const getFilterButtons = async () => {
  return await axios.get("audit-trails/filters");
};

export const deleteAuditTrailRow = async (audit_trail_id) => {
  return await axios.post(`/audit-trails/delete`, {
    audit_trail_id: audit_trail_id,
  });
};
