import TeamRunReadonlyPageLayout from "components/TeamRunReadonly/TeamRunReadonlyLayout/TeamRunLayout";
import TeamRunReadonlyTable from "components/TeamRunReadonly/TeamRunReadonlyTable/TeamRunReadonlyTable";

function TeamRunReadonly() {
    return (
      <TeamRunReadonlyPageLayout>
        <TeamRunReadonlyTable />
      </TeamRunReadonlyPageLayout>
    );
}

export default TeamRunReadonly;
