import { FIELD_NAMES, PRICER_NODES_COLORS, RowColor } from "lib/constant/Pricer/PricerTable";
import { extractCopyShortHandForSummaryRows, getDataFromLocalStorage, setDataToLocalStorage } from "../GeneralFuncHelpers";
import { COLORS } from "lib/constant/AgGridCommonStyle";
import { GA_EVENTS_NAMES } from "lib/constant/GoogleAnalytics/GoogleAnalytics";
import { ACTION_BUTTONS_NAME } from "lib/constant/GeneralActionButtons";
import { handleCopyShortHand } from "../AuditTrail/generalHelper";
import { deleteRow, duplicateRow } from "api/Pricer/Table";
import { toast } from "../utilities";

export const handleColorMenu = (key, selectedRows, latestPropsRef) => {
  let nodesColorsArr = [];
  const nodesColorsArrSavedValue = getDataFromLocalStorage(PRICER_NODES_COLORS);
  if (nodesColorsArrSavedValue) {
    nodesColorsArr = nodesColorsArrSavedValue;
  }
  selectedRows.forEach((item) => {
    nodesColorsArr.push({ row_color: key, summary_id: item.summary_id });
  });
  const ids = nodesColorsArr?.map((i) => i.summary_id);
  const filterdColorsArray = nodesColorsArr?.filter(
    ({ summary_id }, index) => !ids.includes(summary_id, index + 1)
  );
  setDataToLocalStorage(PRICER_NODES_COLORS, filterdColorsArray);
  latestPropsRef.current.api.forEachNode(function (node) {
    const nodeData = node.group ? node.aggData : node.data;
    selectedRows.forEach((item) => {
      if (nodeData.summary_id === item.summary_id) {
        node.setDataValue(RowColor, key);
      }
    });
  });
};

export const handleMenuItemClick = (
   itemClicked,
   selectedRows,
   latestPropsRef,
   googleEvenTracker,
   refetchOptionRow,
   setIsOtherInfoModalVisible,
   setIsTradeClicked,
   onPushToRunClick,
   onPushToAuditTrailClick,
 ) => {
   if (itemClicked === COLORS[3].name) {
     handleColorMenu(itemClicked, selectedRows, latestPropsRef);
     googleEvenTracker(
       GA_EVENTS_NAMES.PRICER_PAGE_REMOVE_ROW_COLOR_CLICK,
       "pricer"
     );
     return;
   }
   if (
     itemClicked === COLORS[0].name ||
     itemClicked === COLORS[1].name ||
     itemClicked === COLORS[2].name
   ) {
     handleColorMenu(itemClicked, selectedRows, latestPropsRef);
     googleEvenTracker(
       GA_EVENTS_NAMES.PRICER_PAGE_CHANGE_ROW_COLOR_CLICK,
       "pricer"
     );
     return;
   }
   const extractSummaryIds = selectedRows.map(
     (item) => item[FIELD_NAMES.SUMMARY_ID]
   );
   if (itemClicked === ACTION_BUTTONS_NAME.COPY_SHORTHAND.key) {
     extractCopyShortHandForSummaryRows(latestPropsRef.current);
     googleEvenTracker(
       GA_EVENTS_NAMES.PRICER_PAGE_COPY_SHORTHAND_CLICK,
       "pricer"
     );
     return;
   }

   if (itemClicked === ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key) {
     googleEvenTracker(
       GA_EVENTS_NAMES.PRICER_PAGE_COPY_SHORTHAND_NO_VOL_CLICK,
       "pricer"
     );
     extractCopyShortHandForSummaryRows(latestPropsRef.current, false);
     return;
   }

   if (itemClicked === ACTION_BUTTONS_NAME.COPY_SHORTHAND_LEGS.key) {
     const rowData = latestPropsRef.current.node.group
       ? latestPropsRef.current.node.aggData
       : latestPropsRef.current.node.data;
     handleCopyShortHand(rowData.copy_shorthand_legs);
     return;
   }

   if (itemClicked === ACTION_BUTTONS_NAME.DUPLICATE.key) {
     duplicateRow(extractSummaryIds).then((res) => {
       googleEvenTracker(
         GA_EVENTS_NAMES.PRICER_PAGE_DUPLICATE_ROW_TRIGGER,
         "pricer"
       );
       refetchOptionRow();
       toast(res?.data?.message, "success");
     });
     return;
   }

   if (itemClicked === ACTION_BUTTONS_NAME.DELETE.key) {
     deleteRow(extractSummaryIds).then((res) => {
       googleEvenTracker(
         GA_EVENTS_NAMES.PRICER_PAGE_DELETE_ROW_TRIGGER,
         "pricer"
       );
       refetchOptionRow();
       toast(res?.data?.message, "success");
     });
     return;
   }

   if (itemClicked === ACTION_BUTTONS_NAME.OTHER_INFO_AND_DETAILS.key) {
     googleEvenTracker(
       GA_EVENTS_NAMES.OTHER_INFO_MODAL_OTHER_INFO_AND_DETAILS_VIEW,
       "pricer"
     );
     setIsOtherInfoModalVisible(true);
     return;
   }

   if (itemClicked === ACTION_BUTTONS_NAME.TRADE.key) {
     setIsTradeClicked(true);
     return;
   }

   if (itemClicked === ACTION_BUTTONS_NAME.PUSH_TO_TEAM_RUN.key) {
     onPushToRunClick();
   }

   if (itemClicked === ACTION_BUTTONS_NAME.PUSH_TO_AUDIT_TRAIL.key) {
     onPushToAuditTrailClick();
   }
 };