import { useEffect, useMemo } from "react";
// Context
import pusherInstance from "lib/constant/Pusher/Pusher";
import { GEEKS_USER } from "lib/constant/Profile/Profile";
import { COMPANY_ID, TRIGGER_ID, USER_ID } from "lib/constant/TeamRun/TeamRun";
import { getDataFromLocalStorage } from "lib/helpers/GeneralFuncHelpers";
const geeksUser = getDataFromLocalStorage(GEEKS_USER);

const useVolSurfaceWebSocket = (selectedFilters, handleRefreshTable) => {
  const SOCKET_EVENT_DATA = useMemo(
    () => ({
      CHANNEL_NAME: `vol-surface.${geeksUser?.[COMPANY_ID]}`,
      EVENT_NAME: "vol-surface-notification",
    }),
    [geeksUser]
  );


  useEffect(() => {
    pusherInstance.logToConsole = true;
   
    const channel = pusherInstance.subscribe(SOCKET_EVENT_DATA.CHANNEL_NAME);
    channel.bind(
      SOCKET_EVENT_DATA.EVENT_NAME,
      (data) => {
        if (data[TRIGGER_ID] !== geeksUser[USER_ID]) {
          if (
            data.product_contract_style_id.toString() ===
            selectedFilters?.product_id.toString()
          ) {
            handleRefreshTable();
          }
        }
      },
    );

    return () => {
      channel.unbind();
      pusherInstance.unsubscribe(SOCKET_EVENT_DATA.CHANNEL_NAME);
    };
  }, [selectedFilters]);
};

export default useVolSurfaceWebSocket;
