import  { useEffect, useMemo, useState } from "react";
// Context
import { getTeamRunProductById } from "api/TeamRun";
import pusherInstance from "lib/constant/Pusher/Pusher";
import { GEEKS_USER } from "lib/constant/Profile/Profile";
import { COMPANY_ID, TRIGGER_ID, UPDATE, USER_ID } from "lib/constant/TeamRun/TeamRun";
import { getDataFromLocalStorage } from "lib/helpers/GeneralFuncHelpers";
const geeksUser = getDataFromLocalStorage(GEEKS_USER)



const useWebSocket = () => {

  const [data,setData]= useState(null)
  const [title,setTitle]= useState(null)
  const [product_id,setProduct_id]= useState(null)
  
  const SOCKET_EVENT_DATA = useMemo(()=>( {
    CHANNEL_NAME : `team-run.${geeksUser?.[COMPANY_ID]}`,
    EVENT_NAME   : "team-run-notification",
  }),[geeksUser]);

  const handleRefreshTable =  (productId) => {
     getTeamRunProductById(productId)
    .then(res => {
      const data = res.data.data; 
      console.log('getTeamRunProductById')
      console.log({data})
      if(!data) return
      setData(data);
      setProduct_id(productId)
    })
  }

  useEffect(()=>{ 
    pusherInstance.logToConsole = true;
  
    const channel = pusherInstance.subscribe(SOCKET_EVENT_DATA.CHANNEL_NAME);
    channel.bind(
      SOCKET_EVENT_DATA.EVENT_NAME,
      (data) => {
        console.log(data[TRIGGER_ID],geeksUser[USER_ID],data.product_id)
        if (data[TRIGGER_ID] !== geeksUser[USER_ID]) {
          if (data.action === UPDATE) {
            let title = data?.product_title;
            let dataToUpdate = data?.data; 
            setData(dataToUpdate);
            setTitle(title);
          } else {
            setTitle(null);
            handleRefreshTable(data.product_id);
          }
        }
      },
      ); 
      
      
      return (() => {
      channel.unbind()
      pusherInstance.unsubscribe(SOCKET_EVENT_DATA.CHANNEL_NAME)
		})
  },[]); 

  return {
    data,
    title,
    product_id
  }
};

export default useWebSocket;
