import VolSurfaceGraph from 'components/VolSurface/VolSurfaceGraph/VolSurfaceGraph';
import VolSurfacePageLayout from 'components/VolSurface/VolSurfaceLayout/VolSurfaceLayout';
import React from 'react'

export default function VolSurfaceData() {
  return (
    <VolSurfacePageLayout>
      <VolSurfaceGraph />
    </VolSurfacePageLayout>
  );
}
