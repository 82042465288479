import React, { useContext, useState } from "react";
import _ from 'lodash';
import { Checkbox } from 'antd';
// Helpers
import { FIELD_NAMES } from "lib/constant/Pricer/PricerTable";
import { toast } from "lib/helpers/utilities";
// API
import { updateLive } from "api/Pricer/Table";
// Context
import { PricerContext } from "lib/contexts/PricerContext";

const CheckBoxPricer = (props) => {
  const isAggData = props.node.group ? props.node.aggData : props.node.data;
  const [isChecked, setIsChecked] = useState(Boolean(isAggData?.[FIELD_NAMES.LIVE]));
  const { state :{ pricerTablesData }, setPricerData, setIsCellEditing, setRowEditingData } = useContext(PricerContext);

  const onLiveClicked = () => {
    const isAggData = props.node.group ? props.node.aggData : props.node.data;
    setIsCellEditing(true);
    setRowEditingData(isAggData);

    setIsChecked(prev => !prev);
    const prepareBody = {
      [FIELD_NAMES.SUMMARY_ID]    : isAggData?.[FIELD_NAMES.SUMMARY_ID],
      [FIELD_NAMES.LIVE]          : +(!isChecked)
    };

    updateLive(prepareBody).then((res)=> {
      const updatedData = _.unionBy([...res.data.data], pricerTablesData, 'id').sort((a,b) => a.id - b.id);
      setPricerData(updatedData);
      toast("Live Updated", "success");
      setIsCellEditing(false);
      setRowEditingData(null);
    }).catch(err=>{
      setIsCellEditing(false);
      setRowEditingData(null);

    })
  };

  if(props.node?.level !== 0) return <></>;

  return (
    <div className="checkbox-cell">
      <Checkbox
        checked={isChecked}
        onChange={onLiveClicked}
      />
    </div>
  )
};

export default CheckBoxPricer;
