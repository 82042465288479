import React, { useState, useEffect, useMemo, useContext } from "react";
import { Col, Row, Modal } from "antd";
//Helpers
import {
  EDIT_TRADE_DETAILS_LABELS,
  STATE_KEYS_NAME,
} from "lib/constant/AuditTrail/AuditTrail";
import {
  handleCopyTradeDetails,
  returnSelectedIdForEditTradeDetails,
} from "lib/helpers/AuditTrail/generalHelper";
import { toast } from "lib/helpers/utilities";
//Components
import ShowLegsDetail from "components/EditTradeDetails/ShowLegsDetail";
import ShowFirstRowDetail from "components/EditTradeDetails/ShowFirstRowDetail";
// Context
import { AuditTrailContext } from "lib/contexts/AuditTrailContext";
// Style
import "./EditTradeDetails.scss";
import { GlobalContext } from "lib/contexts/GlobalContext";
import {
  getEditEditTRadeDetails,
  refetchTradeDetails,
  storeOrUpdateTradeDetails,
  updatePremiumTrade,
  updateQuantityTrade,
  updateTradeLegs,
} from "api/EditTradeDetails/EditTradeDetails";
import useDebounce from "lib/hooks/useDebounce";
import useGAEvenetsTraker from "lib/hooks/useGAEvenetsTraker";
import { GA_EVENTS_NAMES } from "lib/constant/GoogleAnalytics/GoogleAnalytics";
import EditTradePremium from "./EditTradePremium";
import BuyOrSellButtons from "./BuyOrSellButtons";
import { EditTradeFooter } from "./EditTradeFooter";
import { BACKEND_KEYS_NAME } from "lib/constant/EditTradeDetails/EditTradeDetails";
import PreLoadPagesSpin from "components/PreLoadPagesSpin";
import {
  prepareApiData,
  prepareBodyForCopyingOrSaveAndSendButton,
  prepareDataForGettingTradeDetails,
} from "lib/helpers/EditTradeDetails/utils";

const EditTradeDetails = ({ isEditMode = false, ...props }) => {
  const { setOnSaveAndCreateClicked } = useContext(AuditTrailContext);
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const googleEvenTracker = useGAEvenetsTraker("event");

  //#States
  const [isSellActive, setIsSellActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exChangeData, setExChangeData] = useState([]);
  const [tradingCompanyId, setTradingCompanyId] = useState();
  const [traderId, setTraderId] = useState();
  const [apiData, setApiData] = useState("");
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [isRequestActive, setIsRequestActive] = useState(false);
  const [editTradeDetailTitle, setEditTradeDetailTitle] = useState({
    [STATE_KEYS_NAME.EDIT_TRADE_TITLE]: "",
  });
  const [editTradeValue, setEditTradeValue] = useState(undefined);
  const [editQuantityValue, setEditQuantityValue] = useState(undefined);
  const [editLegValue, setEditLegValue] = useState([]);
  const [traderDetailsData, setTraderDetailsData] = useState([]);
  const debouncedTradePremiumValue = useDebounce(editTradeValue, 1000);
  const debouncedQuantity = useDebounce(editQuantityValue, 1000);
  const legValue =
    editLegValue.length > 0
      ? editLegValue.find((leg) => leg.edit === true)
      : null;
  const debouncedLegVaalue = useDebounce(legValue?.value, 1000);

  // use effect on isSellActive change
  useEffect(() => {
    // add param is_sell = isSellActive to url
    getTradeDetailsData([]);
    setEditLegValue([]);
    setEditQuantityValue(undefined);
  }, [isSellActive]);

  useEffect(() => {
    if (legValue?.value) {
      handleLegsPremiumChange(legValue.value, legValue.id);
    }
  }, [debouncedLegVaalue]);

  useEffect(() => {
    if (debouncedTradePremiumValue != null) {
      handleTradePremium();
    }
  }, [debouncedTradePremiumValue]);

  useEffect(() => {
    if (debouncedQuantity != null) {
      handleQuanityChange(debouncedQuantity);
    }
  }, [debouncedQuantity]);

  //Reset State
  useEffect(() => {
    setTraderDetailsData([]);
    setIsSellActive(false);
  }, []);

  function getTradeDetailsData() {
    let bodyParams = {
      summary_id: apiData?.["temp_summary_id"]
        ? apiData?.["temp_summary_id"]
        : props.rowSelectedInfo?.["summary_id"], // for the first opening popup we use summary_id and for the next request we are using temp_summary_id
      is_sell: isSellActive ? 1 : 0,
    };
    setIsRequestActive(true);
    setIsLoading(true);
    getEditEditTRadeDetails(bodyParams)
      .then((res) => {
        prepareAPIData(res.data.data);
        setExChangeData(
          res.data.data?.["exchanges"].map((item) => ({
            ...item,
            label: item[BACKEND_KEYS_NAME.NAME],
            value: item[BACKEND_KEYS_NAME.ID],
          }))
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error > ", error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsRequestActive(false);
      });
  }

  function refetchTradeDetailsData() {
    setEditTradeValue(undefined);
    setEditQuantityValue(undefined);
    setEditLegValue([]);
    let bodyParams = {
      temp_summary_id: apiData?.["temp_summary_id"],
      is_sell: isSellActive ? 1 : 0,
    };
    refetchTradeDetails(bodyParams).then((res) => {
      onUpdatePrepareAPIData(res.data.data);
    });
  }

  //Re-format and set the trade data
  const reformatAndSetTradeData = (backendKey, value, stateKey, stateValue) => {
    let formattedData = [];
    traderDetailsData.forEach((item) => {
      let deltaQuantityflag = false;
      let legsQuantityFlag = false;
      let hedgeQuanityFlag = false;
      deltaQuantityflag =
        item.type === backendKey &&
        stateKey !== BACKEND_KEYS_NAME.DELTA_QUANTITY_T;
      legsQuantityFlag =
        item.type === BACKEND_KEYS_NAME.LEGS &&
        backendKey === BACKEND_KEYS_NAME.QUANTITY_T &&
        stateValue === BACKEND_KEYS_NAME.LEGS;
      hedgeQuanityFlag =
        item.type === BACKEND_KEYS_NAME.HEDGE &&
        stateKey === BACKEND_KEYS_NAME.DELTA_QUANTITY_T;
      if (deltaQuantityflag) {
        if (stateKey !== BACKEND_KEYS_NAME.QUANTITY_T) {
          item[stateKey] = value;
        }
        if (stateKey === BACKEND_KEYS_NAME.QUANTITY_T) {
          item[stateKey][BACKEND_KEYS_NAME.VALUE] = value;
        }
        if (stateKey === STATE_KEYS_NAME.EXCHANGE_ID) {
          const findActiveExChange = apiData[BACKEND_KEYS_NAME.EXCHANGES].find(
            (item) => item[BACKEND_KEYS_NAME.ID] === value
          );
          item[STATE_KEYS_NAME.EXCHANGE] = findActiveExChange?.name;
        }
      }
      if (stateKey === STATE_KEYS_NAME.EXCHANGE_ID) {
        const findActiveExChange = apiData[BACKEND_KEYS_NAME.EXCHANGES].find(
          (item) => item[BACKEND_KEYS_NAME.ID] === value
        );
        item[BACKEND_KEYS_NAME.EXCHANGE] = findActiveExChange?.name;
      }
      if (
        item.type === BACKEND_KEYS_NAME.HEDGE &&
        backendKey === BACKEND_KEYS_NAME.HEDGE_IS_SELL
      ) {
        item.hedges.forEach((hedge) => {
          hedge[BACKEND_KEYS_NAME.SELLE_OR_BUY] = value;
        });
      }
      if (stateKey === BACKEND_KEYS_NAME.TRADER) {
        item[stateKey] = value;
      }
      if (stateKey === BACKEND_KEYS_NAME.COMPANY_TRADER) {
        item[stateKey] = value;
      }
      if (legsQuantityFlag) {
        item[BACKEND_KEYS_NAME.QUANTITY_T][BACKEND_KEYS_NAME.VALUE] =
          parseFloat(value) * parseInt(item[BACKEND_KEYS_NAME.RATIO]);
      }
      if (hedgeQuanityFlag) {
        // item.hedges.forEach((hedge) => {
        //   if (hedge.id === stateValue) {
        //     hedge[BACKEND_KEYS_NAME.DELTA_QUANTITY_T][BACKEND_KEYS_NAME.VALUE] =
        //       value.delta_quantity;
        //     hedge[BACKEND_KEYS_NAME.DELTA][BACKEND_KEYS_NAME.VALUE] =
        //       value.delta;
        //   }
        // });
        setTraderDetailsData((prev) => {
          prev.forEach((element, index) => {
            if (element?.type === BACKEND_KEYS_NAME.HEDGE) {
              element?.hedges?.forEach((hedge, index) => {
                if (hedge.id === stateValue) {
                  hedge[BACKEND_KEYS_NAME.DELTA][BACKEND_KEYS_NAME.VALUE] =
                    value.delta;
                  hedge[BACKEND_KEYS_NAME.DELTA_QUANTITY_T][
                    BACKEND_KEYS_NAME.VALUE
                  ] = value.delta_quantity;
                }
              });
            }
          });
          return [...prev];
        });
      }

      if (!hedgeQuanityFlag) {
        formattedData.push(item);
      }
    });

    if (
      stateKey !== BACKEND_KEYS_NAME.DELTA_QUANTITY_T &&
      stateKey !== BACKEND_KEYS_NAME.DELTA
    ) {
      setTraderDetailsData(formattedData);
    }
  };

  const handleLegsPremiumChange = (value, id) => {
    const prepareBody = {
      [BACKEND_KEYS_NAME.IS_SELL]: isSellActive ? 1 : 0,
      [BACKEND_KEYS_NAME.LEGS]: [
        {
          [BACKEND_KEYS_NAME.PREMIUM]: value,
          [BACKEND_KEYS_NAME.ID]: id,
        },
      ],
      ...((props.isTeamRun || props.isPricer) && {
        [BACKEND_KEYS_NAME.TEM_SUMMARY_ID]:
          apiData?.[BACKEND_KEYS_NAME.TEM_SUMMARY_ID],
      }),
    };
    setIsRequestActive(true);
    updateTradeLegs(prepareBody)
      .then((res) => {
        onUpdatePrepareAPIData(res.data?.data);
      })
      .catch((error) => {
        console.log("error> ", error);
        refetchTradeDetailsData();
      })
      .finally(() => {
        setIsRequestActive(false);
      });
  };

  function handleTradePremium() {
    const prepareBody = {
      is_sell: isSellActive ? 1 : 0,
      [BACKEND_KEYS_NAME.TEM_SUMMARY_ID]:
        apiData?.[BACKEND_KEYS_NAME.TEM_SUMMARY_ID],
      premium: debouncedTradePremiumValue,
      ...((props.isTeamRun || props.isPricer) && {
        summary_id: +returnSelectedIdForEditTradeDetails(props).split("/")[1],
      }),
    };
    setIsRequestActive(true);
    updatePremiumTrade(prepareBody)
      .then((res) => {
        onUpdatePrepareAPIData(res.data?.data);
      })
      .catch((error) => {
        refetchTradeDetailsData();
      })
      .finally(() => {
        setIsRequestActive(false);
      });
  }

  function onUpdatePrepareAPIData(data) {
    const { data: newData, tradeLegsData } = prepareApiData(
      data,
      traderDetailsData
    );
    if (newData) {
      if (newData.hasOwnProperty(BACKEND_KEYS_NAME.TRADED_PREMIUM)) {
        setEditTradeValue(undefined);
      }

      //Pushing Hedge data to Legs Data
      setTraderDetailsData(tradeLegsData);
      setApiData((prev) => ({ ...prev, ...newData }));
    }
  }

  const prepareAPIData = (data) => {
    const { data: newData, tradeLegsData } =
      prepareDataForGettingTradeDetails(data);
    setTraderDetailsData(tradeLegsData);
    setApiData(newData);
  };

  const triggerPostEditApi = () => {
    if (!apiData) return;
    setIsCreateLoading(true);
    const prepareBody = prepareBodyForCopyingOrSaveAndSendButton(
      props,
      traderDetailsData,
      apiData,
      isSellActive
    );
    prepareBody["trading_company_id"] = tradingCompanyId;
    prepareBody["trader_id"] = traderId;
    setIsRequestActive(true);
    storeOrUpdateTradeDetails(props, prepareBody)
      .then((res) => {
        googleEvenTracker(
          GA_EVENTS_NAMES.TRADE_OPTION_MODAL_TRADE_OPTION_TRIGGER,
          "Team Run"
        );
        handleCopyTradeDetails(res.data.data["copy_trade_details"]);
        !props.isTeamRun && !props.isPricer && setOnSaveAndCreateClicked(true);
        toast(res.data?.message, "success");
        setIsCreateLoading(false);
      })
      .catch((err) => {
        setIsCreateLoading(false);
      })
      .finally(() => {
        setIsRequestActive(false);
      });
  };

  //Checking for validation
  const isSaveAndCreateButtonDisabled = useMemo(() => {
    let isQuantityValue = true;
    let isDeltaQuantityValue = true;
    traderDetailsData.forEach((item) => {
      if (item.type === BACKEND_KEYS_NAME.QUANTITY_T) {
        let validationFlag =
          item[STATE_KEYS_NAME.TRADER] &&
          item[STATE_KEYS_NAME.COMPANY_TRADER] &&
          item[STATE_KEYS_NAME.EXCHANGE_ID] &&
          item[STATE_KEYS_NAME.QTY_VALUES][BACKEND_KEYS_NAME.VALUE];
        if (validationFlag) {
          isQuantityValue = false;
        }
      }
      if (item.type === BACKEND_KEYS_NAME.HEDGE) {
        let validationFlag;
        item?.hedges?.forEach((hedge) => {
          validationFlag =
            hedge[BACKEND_KEYS_NAME.DELTA_QUANTITY_T]?.[
              BACKEND_KEYS_NAME.VALUE
            ] && hedge[BACKEND_KEYS_NAME.DELTA_QUANTITY_T]?.disabled === 0;
          if (validationFlag) {
            isDeltaQuantityValue = false;
          }

          if (item[BACKEND_KEYS_NAME.DELTA_QUANTITY_T]?.disabled === 1) {
            isDeltaQuantityValue = false;
          }
        });
      }
    });
    const isValid =
      isQuantityValue === false && isDeltaQuantityValue === false
        ? false
        : true;
    return isValid;
  }, [traderDetailsData]);
  // End Of Component Func

  //Handle first row quantity change
  function handleQuanityChange(value) {
    reformatAndSetTradeData(
      BACKEND_KEYS_NAME.QUANTITY_T,
      value,
      BACKEND_KEYS_NAME.QUANTITY_T
    );
    const prepareBody = {
      [BACKEND_KEYS_NAME.IS_SELL]: isSellActive ? 1 : 0,
      quantity: value,
      ...((props.isTeamRun || props.isPricer) && {
        temp_summary_id: apiData?.["temp_summary_id"],
      }),
    };

    setIsRequestActive(true);
    updateQuantityTrade(prepareBody)
      .then((res) => {
        onUpdatePrepareAPIData(res.data?.data);
      })
      .catch((error) => {
        console.log("error > ", error);
        refetchTradeDetailsData();
      })
      .finally(() => {
        setIsRequestActive(false);
      });
  }

  //Handle the exchange selection
  const handleExchangeChange = (key, value) => {
    reformatAndSetTradeData(
      BACKEND_KEYS_NAME.QUANTITY_T,
      value,
      STATE_KEYS_NAME.EXCHANGE_ID
    );
  };

  //Handle the Trader change input
  const handleTraderChange = (key, value) => {
    reformatAndSetTradeData(
      BACKEND_KEYS_NAME.TRADER,
      value,
      STATE_KEYS_NAME.TRADER
    );
  };

  //Handle the Company Trader change input
  const handleCompanyTraderChange = (key, value) => {
    reformatAndSetTradeData(
      BACKEND_KEYS_NAME.COMPANY_TRADER,
      value,
      STATE_KEYS_NAME.COMPANY_TRADER
    );
  };

  //Handle the Legs quanitity change
  const handleLegsQuantityChange = (id, value) => {
    reformatAndSetTradeData(
      BACKEND_KEYS_NAME.LEGS,
      value,
      BACKEND_KEYS_NAME.ID
    );
  };

  //Handle the hedge quanitity change
  const handleHedgeQuantityChange = (id, value) => {
    reformatAndSetTradeData(
      BACKEND_KEYS_NAME.HEDGE,
      value,
      BACKEND_KEYS_NAME.DELTA_QUANTITY_T,
      id
    );
  };

  //Handle the hedge quanitity change
  const handleHedgeSwapSignBtnChange = (value) => {
    reformatAndSetTradeData(
      BACKEND_KEYS_NAME.HEDGE_IS_SELL,
      value,
      BACKEND_KEYS_NAME.HEDGE_IS_SELL
    );
  };

  return (
    <Modal
      title={EDIT_TRADE_DETAILS_LABELS.TRADE_OPTION}
      className={dark_Theme && "content_dark ant-modal-dark"}
      open={props.isOpen}
      bodyStyle={{ overflowX: "auto", margin: 16 }}
      footer={EditTradeFooter(
        props,
        triggerPostEditApi,
        isRequestActive,
        isCreateLoading,
        isSaveAndCreateButtonDisabled
      )}
      width={"80vw"}
      onCancel={() => props.handleMenuItemClicked(false)}
    >
      <div className="edit-trade-details">
        {/* Show Sell And Buy Button */}
        <Row gutter={[0, 15]} className="min-max-w-980">
          <BuyOrSellButtons
            isSellActive={isSellActive}
            setIsRequestActive={setIsRequestActive}
            setIsSellActive={setIsSellActive}
          />
          {isLoading ? (
            <PreLoadPagesSpin
              className={" edit-trade-loading"}
              dark_Theme={dark_Theme}
            />
          ) : (
            <>
              <EditTradePremium
                apiData={apiData}
                editTradeValue={editTradeValue}
                setEditTradeValue={setEditTradeValue}
                setIsRequestActive={setIsRequestActive}
              />
              {/* Show Traded premium */}
              <Col span={24}>
                <strong className="title">
                  {(editTradeDetailTitle.title
                    ? editTradeDetailTitle.title
                    : apiData.title) || "API2 Feb-23:Put 0.000"}
                </strong>
              </Col>
              {/* End Of Show Sell And Buy */}

              <Col span={24}>
                <Row gutter={[0, 15]}>
                  {traderDetailsData?.map((item, index) => {
                    const isLegsFirstRow =
                      item.type === BACKEND_KEYS_NAME.QUANTITY_T;
                    /// show hedge row only if show hedge is 1
                    const isHedgeRowAndHideHedge =
                      item.type === BACKEND_KEYS_NAME.HEDGE &&
                      apiData[BACKEND_KEYS_NAME.SHOW_HEDGE] === 0;
                    return (
                      <Col span={24} key={index}>
                        {isLegsFirstRow ? (
                          <ShowFirstRowDetail
                            apiData={apiData}
                            setIsRequestActive={setIsRequestActive}
                            exChangeData={exChangeData}
                            setEditQuantityValue={setEditQuantityValue}
                            editQuantityValue={editQuantityValue}
                            handleExchangeChange={handleExchangeChange}
                            handleTraderChange={handleTraderChange}
                            handleCompanyTraderChange={
                              handleCompanyTraderChange
                            }
                            setTradingCompanyId={setTradingCompanyId}
                            tradingCompanyId={tradingCompanyId}
                            setTraderId={setTraderId}
                            {...item}
                          />
                        ) : isHedgeRowAndHideHedge ? (
                          <></>
                        ) : (
                          <ShowLegsDetail
                            reformatAndSetTradeData={reformatAndSetTradeData}
                            placeholdersTitle={apiData}
                            setIsRequestActive={setIsRequestActive}
                            handleLegsPremiumChange={setEditLegValue}
                            handleLegsQuantityChange={handleLegsQuantityChange}
                            editLegValue={editLegValue}
                            handleHedgeQuantityChange={
                              handleHedgeQuantityChange
                            }
                            handleHedgeSwapSignBtnChange={
                              handleHedgeSwapSignBtnChange
                            }
                            isSellActive={isSellActive}
                            temp_summary_id={apiData?.["temp_summary_id"]}
                            {...item}
                          />
                        )}
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </>
          )}
        </Row>
      </div>
    </Modal>
  );
};
export default EditTradeDetails;
