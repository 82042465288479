import axios from "api/axios";

export const exportMarketAnalysis = async (params) => {
  return await axios.get("/market-analysis/export",{
    params: {
      ...params,
    },
  });
};

export const  fetchMarketAnalysisGraph = async (param) => {
  return await axios.get("/market-analysis/graphs", {
    params: {
      ...param,
    },
  });
};

export const fetchMarketAnalaysisTableData = async (queryParams) => {
  return await axios.get('market-analysis', {
    params: {
      ...queryParams,
    },
  })
};



export const fetchMarketAnalysifilters = async () => {
  return await axios.get('market-analysis/graphs/filters')
};

export const fetchMarketAnalysisTableFilters = async () => {
  return await axios.get("/market-analysis/get-table-filters");
};

