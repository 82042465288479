import { VerticalAlignTopOutlined } from "@ant-design/icons";
import { GA_EVENTS_NAMES } from "lib/constant/GoogleAnalytics/GoogleAnalytics";
import classnames from "classnames";
import { GlobalContext } from "lib/contexts/GlobalContext";
import { useContext } from "react";
import { ACTION_BUTTONS_NAME } from "lib/constant/GeneralActionButtons";
import useGAEvenetsTraker from "lib/hooks/useGAEvenetsTraker";
import { TeamRunReadonlyContext } from "lib/contexts/TeamRunContextReadonly";

function UpdateBid({
  setIsUpdateBidOrAskActive,
  triggerSingleAPIFunc,
  props
}) {
  const {
    state: { teamRunData },

    setBidAskPopupData,
  } = useContext(TeamRunReadonlyContext);
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const googleEvenTracker = useGAEvenetsTraker("event");

  const setDataForBidAndAskPopup = (itemClicked) => {
    let updatedTeamRunData =
      teamRunData[props.data.product_title] &&
      teamRunData[props.data.product_title].find(
        (item) => item.id === props.data.id
      );
    let data = {
      isOpen: itemClicked,
      isUpdateBidModalVisible: true,
      keyItem: itemClicked,
      selectedModal: itemClicked,
      triggerSingleAPIFunc: triggerSingleAPIFunc,
      data: updatedTeamRunData,
    };
    setBidAskPopupData(data);
    setIsUpdateBidOrAskActive(itemClicked);
  };

  return (
    <VerticalAlignTopOutlined
      onClick={() => {
        setDataForBidAndAskPopup(ACTION_BUTTONS_NAME.UPDATE_BID.key);
        googleEvenTracker(
          GA_EVENTS_NAMES.UPDATE_BID_MODAL_UPDATE_BID_VIEW,
          "Team Run"
        );
      }}
      className={classnames("button-icon", {
        "button-light-theme": !dark_Theme,
      })}
    />
  );
}

export default UpdateBid;
